var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"person-select",attrs:{"label":_vm.label,"items":!!_vm.myValue ? (_vm.myItems.findIndex(function (item) { return item.email === _vm.myValue.email && item.name === _vm.myValue.name && (item.icon === _vm.myValue.icon || (!item.icon && !_vm.myValue.icon)); }) > -1 ? _vm.myItems : [Object.assign({}, _vm.myValue, {disabled:true})].concat(_vm.myItems)) : _vm.myItems,"loading":false,"disabled":_vm.disabled,"item-text":"email","item-value":"email","return-object":"","height":46,"rules":_vm.rules},on:{"focus":_vm.focus,"change":function($event){return _vm.$emit('change', Object.assign({}, _vm.myValue))}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.loading)?_c('div',{style:({
        margin: '12px 15px',
        display: 'flex',
        'align-items': 'center'
      })},[_c('v-progress-circular',{attrs:{"width":4,"color":"grey","indeterminate":"","size":24}}),_c('div',{staticClass:"grey--text text--lighten-1",style:({
          'margin-left': '16px'
        })},[_vm._v(" Loading ")])],1):_c('div',{style:({
        margin: '12px 15px',
        display: 'flex',
        'align-items': 'center'
      })},[_vm._v(" No Matched Data ")])]},proxy:true},{key:"selection",fn:function(data){return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-avatar',{staticStyle:{"margin-right":"12px"},attrs:{"size":"30"}},[_c('img',{attrs:{"src":data.item.icon || _vm.default_icon}})]),_c('div',[_vm._v(_vm._s(data.item.name))])],1)]}},{key:"item",fn:function(data){return [(!_vm.items.length)?_c('div',{style:({
        margin: '12px 0',
        display: 'flex',
        'align-items': 'center'
      })},[_c('v-progress-circular',{attrs:{"width":4,"color":"grey","indeterminate":"","size":24}}),_c('div',{staticClass:"grey--text text--lighten-1",style:({
          'margin-left': '16px'
        })},[_vm._v(" Loading ")])],1):(typeof data.item !== 'object')?_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}}):_c('div',{class:data.item.disabled?['person-select-disabled']:[],staticStyle:{"display":"flex","align-items":"center","margin-right":"8px"}},[_c('v-avatar',{staticStyle:{"margin-right":"16px"},attrs:{"size":"30"}},[_c('img',{attrs:{"src":data.item.icon || _vm.default_icon}})]),_c('div',{staticStyle:{"text-align":"left"}},[_c('div',{staticStyle:{"height":"14px","line-height":"16px"}},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('div',{staticStyle:{"height":"14px","line-height":"16px","color":"grey"}},[_vm._v(" "+_vm._s(data.item.email)+" ")])])],1)]}}]),model:{value:(_vm.myValue),callback:function ($$v) {_vm.myValue=$$v},expression:"myValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }