export const toThousands = (num) => {
  try {
    if (isNaN(Number(num))) throw ''

    let ss = Number(num).toString().split('.')

    let ft = ss[0].split('').reverse()
    let cnt = 0
    let rft = []
    ft.forEach(el => {
      cnt++
      rft.push(el)
      if (!(cnt%3) && cnt < ft.length) {
        rft.push(',')
      }
    })
    rft = rft.reverse().join('')
    
    return rft + (ss.length > 1 ? '.' + ss[1] : '')
  } catch (err) {
    // console.log(err)
  }
  return num
}


export const formatTime = (number,format) => {  
  
  var formateArr  = ['Y','M','D','h','m','s'];  
  var returnArr   = [];  
  
  var date = new Date(number);  
  returnArr.push(date.getFullYear());  
  returnArr.push(formatNumber(date.getMonth() + 1));  
  returnArr.push(formatNumber(date.getDate()));  
  
  returnArr.push(formatNumber(date.getHours()));  
  returnArr.push(formatNumber(date.getMinutes()));  
  returnArr.push(formatNumber(date.getSeconds()));  
  
  for (var i in returnArr)  
  {  
    format = format.replace(formateArr[i], returnArr[i]);  
  }  
  return format;  
} 

//数据转化  
function formatNumber(n) {  
  n = n.toString()  
  return n[1] ? n : '0' + n  
}  

export const to2Digits = (x) => {
  var f = Math.round(x*100)/100; 
  var s = f.toString(); 
  var rs = s.indexOf('.'); 
  if (rs < 0) { 
    rs = s.length; 
    s += '.'; 
  } 
  while (s.length <= rs + 2) { 
    s += '0'; 
  } 
  return s; 
}

/** 数字金额大写转换(可以处理整数,小数,负数) */
export const transAmountToBig = (n) => {
  var fraction = ['角', '分'];
  var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  var unit = [ ['元', '万', '亿'], ['', '拾', '佰', '仟'] ];
  var head = n < 0? '欠': '';
  n = Math.abs(n);

  var s = '';    

  for (let i = 0; i < fraction.length; i++) {    
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');    
  }    
  s = s || '整';    
  n = Math.floor(n);    

  for (let i = 0; i < unit[0].length && n > 0; i++) {    
      var p = '';    
      for (var j = 0; j < unit[1].length && n > 0; j++) {    
          p = digit[n % 10] + unit[1][j] + p;    
          n = Math.floor(n / 10);    
      }    
      s = p.replace(/(零.)*零$/, '').replace(/^$/, '零')  + unit[0][i] + s;    
  }    
  return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');    
} 

export const dateFormat = (date, fmt) => {
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      }
  }
  return fmt;
}