<template>
  <person-select
    v-model="myValue"
    :label="label"
    :items="myItems"
    :loading="loading"
    :disabled="disabled"
    :rules="rules"
    @change="$emit('change', {...myValue})"
  >
  </person-select>
</template>

<script>
import { mapGetters } from 'vuex'
import personSelect from '@/components/vuetify-extension/person-select'

export default {
  components: {
    personSelect,
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      default_icon: require('@/static/img/avatars/default.jpg'),
      loading: false,
      myValue: null,
      myItems: []
    }
  },
  computed: {
    ...mapGetters('user', [
      'loginStatus'
    ])
  },
  methods: {
    setMyValue (val) {
      try {
        if (!this.myValue || val.email !== this.myValue.email || val.name !== this.myValue.name || val.icon !== this.myValue.icon) {
          this.myValue = {
            email: val.email || '',
            name: val.name || '',
            icon: val.icon || ''
          }
        }
      } catch (e) {}
    },
    setMyItems (items) {
      this.myItems = []
      try {
        let newItems = items
          .filter(item => !!item.email)
          .map(item => ({
            email: item.email || '',
            name: item.name || '',
            icon: item.icon || ''
          }))
        this.myItems = newItems
      } catch (e) {}
    }
  },
  created () {
    this.setMyValue(this.value)
    this.setMyItems(this.items)
  },
  watch: {
    value: {
      deep: true,
      handler (val, oVal) {
        this.setMyValue(val)
      }
    },
    items: {
      deep: true,
      handler (val, oVal) {
        this.setMyItems(val)
      }
    }
  }
}
</script>

<style lang="sass">

</style>
