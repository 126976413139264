<template>
  <v-dialog
    :value="value"
    persistent
    width="fit-content"
  >
    <v-card :class="cardClass" :style="cardStyle">
      <v-list :class="titleClass" :style="titleStyle">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 20px;"
            >{{ title }}</v-list-item-title>
          </v-list-item-content>

          <!-- <v-spacer></v-spacer> -->

          <v-list-item-action>
            <v-btn text icon @click="clickClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div style="overflow: auto;">
        <div style="text-align: left;padding: 12px 24px;display: flex;align-items: start;">
          <v-icon :color="iconColor" style="margin-top: -3px;margin-right: 9px">{{ icon }}</v-icon>
          <slot>{{ comments }}</slot>          
        </div>

        <div style="padding: 8px 18px;text-align: right;">
          <v-btn v-if="showNo" text :color="btnColor" @click="clickNo">
            {{ noText }}
          </v-btn>
          <v-btn v-if="showYes" dark :color="btnColor" @click="clickYes">
            {{ yesText }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    cardClass: {
      type: [Object, Array],
      default: () => ({})
    },
    cardStyle: {
      type: Object,
      default: () => ({})
    },
    titleClass: {
      type: [Object, Array],
      default: () => (['white'])
    },
    titleStyle: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: 'Confirm'
    },
    icon: {
      type: String,
      default: 'mdi-help-circle'
    },
    iconColor: {
      type: String,
      default: 'warning darken-1'
    },
    btnColor: {
      type: String,
      default: 'primary'
    },
    comments: {
      type: String,
      default: 'Please confirm'
    },
    showNo: {
      type: Boolean,
      default: true
    },
    noText: {
      type: String,
      default: 'No'
    },
    noFlat: {
      type: Boolean,
      default: true
    },
    noColor: {
      type: String,
      default: 'info'
    },
    showYes: {
      type: Boolean,
      default: true
    },
    yesText: {
      type: String,
      default: 'Yes'
    },
    yesColor: {
      type: String,
      default: 'info'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  methods: {
    clickClose () {
      this.$emit('change', false)
      this.$emit('click-close')
    },
    clickNo (e) {
      this.$emit('change', false)
      this.$emit('click-no', e)
    },
    clickYes (e) {
      this.$emit('click-yes', e)
    }
  }
}
</script>

<style>

</style>
