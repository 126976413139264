<template>
  <v-list ref="file-list" class="file-list">
    <table cellspacing=0 width="100%">
      <template v-for="(file, fi) in files">
        <tr
          :key="'file_list_' + fi"
          @click="clickItem(file, fi)"
        >
          <td style="width:44px;text-align:left;padding-left:6px">
            <v-tooltip right :disabled="isMobile">
              <template v-slot:activator="{ on }">
                <v-icon color="grey" size="24" v-on="on">
                  mdi-file-document-outline
                </v-icon>
              </template>
              <span>{{ file.name }}</span>
            </v-tooltip>
          </td>
          <template v-if="multipleRows">
            <td style="padding-top: 8px;padding-bottom: 8px;text-align: left;word-break: break-word;">
              <div>{{ file.name }}</div>
              <div
                v-if="file.uploader && file.time"
                style="display: flex;align-items: center;padding-top: 4px;padding-bottom: 4px;"
              >
                <account-profile
                  :email="file.uploader"
                  :hide-tooltip="isMobile"
                  :tag-icon-size="24"
                  :tag-color="themeColor.default"
                  style="width: 150px;"
                >
                </account-profile>
              </div>
              <div class="grey--text">
                {{ file.time }}
              </div>
            </td>
          </template>
          <template v-else>
            <td style="text-align: left;word-break: break-word;">
              {{ file.name }}
            </td>
            <td v-if="file.time" style="width: 150px;padding-right:5px;">
              <span class="grey--text">
                {{ file.time }}
              </span>
            </td>
            <td v-if="file.uploader" style="width: 150px;padding: 5px 6px;">
              <account-profile
                :email="file.uploader"
                :hide-tooltip="isMobile"
                :tag-icon-size="24"
                :tag-color="themeColor.default"
              >
              </account-profile>
            </td>
          </template>
          <slot
            name="buttons"
            :item="file"
            :index="fi"
            :row="fi + 1"
          ></slot>
          <td v-if="downloadable && anyDownload" style="width:24px">
            <v-btn v-if="!!file.download" small text icon
              style="margin:0;padding:3px 5px!important;width:34px;height:34px;"
              @click="clickDownload(file, fi)"
            >
              <v-icon :color="btnColor" size="24px">
                mdi-download
              </v-icon>
            </v-btn>
          </td>
          <td v-if="deletable && anyDelete" style="width:24px">
            <v-btn v-if="!!file.delete" small text icon
              style="margin:0;padding:3px 5px!important;width:34px;height:34px;"
              @click="clickDelete(file, fi)"
            >
              <v-icon size="24" color="grey">
                mdi-close
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </table>
  </v-list>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AccountProfile from '@/components/vuetify-extension/account-profile'

export default {
  props: {
    files: {
      type: Array,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false
    },
    downloadable: {
      type: Boolean,
      default: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    multipleRows: {
      type: Boolean,
      default: false
    },
    btnColor: {
      type: String,
      default: 'primary'
    }
  },
  components: {
    AccountProfile
  },
  computed: {
    ...mapState('app', {
      themeColor: state => state.color
    }),
    ...mapGetters('app', [
      'isMobile'
    ]),
    anyDownload () {
      return this.files.findIndex(el => !!el.download) > -1
    },
    anyDelete () {
      return this.files.findIndex(el => !!el.delete) > -1
    }
  },
  methods: {
    clickItem (item, index) {
      this.$emit('click-item', { item, index, row: index + 1 })
    },
    clickDownload (item, index) {
      this.$emit('click-download', { item, index, row: index + 1 })
    },
    clickDelete (item, index) {
      this.$emit('click-delete', { item, index, row: index + 1 })
    }
  }
}
</script>

<style lang="sass">
.file-list
  padding: 0

  tr:hover
    td
      position: relative
    

    td:before
      border-radius: inherit
      color: inherit
      content: ""
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      opacity: .12
      transition: .3s cubic-bezier(.25,.8,.5,1)
      background-color: rgba(0, 0, 0, .4)
    
  

</style>
