var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"fit-content","persistent":"","fullscreen":_vm.isMobile,"hide-overlay":_vm.isMobile,"transition":_vm.isMobile ? 'dialog-bottom-transition' : 'dialog-transition'},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',{staticClass:"role-card"},[_c('v-list',{class:['white py-0'].concat( _vm.isMobile ? ['sidebar-top'] : []
    )},[_c('v-list-item',[(_vm.isMobile)?[_c('v-list-item-action',[(_vm.isMobile)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.closeShareTo}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-spacer')]:_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{style:(_vm.isMobile ? {'text-align': 'center'} : {'font-size': '20px'})},[_vm._v(_vm._s(_vm.editable ? 'Share Access' : 'Access'))])],1),_c('v-spacer'),_c('v-list-item-action',[(_vm.isMobile)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.saveMembers}},[_c('v-icon',[_vm._v("check")])],1):_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.closeShareTo}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],2)],1),_c('div',{style:({
        'position': 'absolute',
        'top': '64px',
        'bottom': '0',
        'left': '0',
        'right': '0',
        'overflow-x': 'hidden'
      })},[_c('share-access-list',{style:({
          'height': 'calc(100% - ' + (_vm.isMobile ? 0 : 72) + 'px)',
          'padding': '0 30px ' + (_vm.isMobile ? 64 : 12) + 'px 24px',
          'overflow': 'auto'
        }),attrs:{"owner":_vm.owner,"members":_vm.myMembers,"editable":_vm.editable},on:{"change-members":_vm.changeMembers}}),(!_vm.isMobile && _vm.editable)?_c('div',{staticStyle:{"padding":"5px 12px","text-align":"right"}},[_c('v-btn',{attrs:{"dark":"","color":_vm.btnColor},on:{"click":_vm.saveMembers}},[_vm._v(" Done ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }