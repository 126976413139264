<template>
  <v-dialog
    v-model="value"
    width="fit-content"
    persistent
    :fullscreen="isMobile"
    :hide-overlay="isMobile"
    :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
  >
    <v-card class="role-card">
      <v-list :class="[
        ...['white py-0'],
        ...isMobile ? ['sidebar-top'] : []
      ]">
        <v-list-item>
          <template v-if="isMobile">
            <v-list-item-action>
              <v-btn v-if="isMobile" text icon @click="closeShareTo">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>

            <v-spacer></v-spacer>
          </template>

          <v-list-item-content>
            <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">{{ editable ? 'Share Access' : 'Access' }}</v-list-item-title>
          </v-list-item-content>

          <v-spacer></v-spacer>

          <v-list-item-action>
            <v-btn v-if="isMobile" text icon @click="saveMembers">
              <v-icon>check</v-icon>
            </v-btn>
            <v-btn v-else text icon @click="closeShareTo">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <div :style="{
          'position': 'absolute',
          'top': '64px',
          'bottom': '0',
          'left': '0',
          'right': '0',
          'overflow-x': 'hidden'
        }"
      >
        <share-access-list
          :owner="owner"
          :members="myMembers"
          :editable="editable"
          @change-members="changeMembers"
          :style="{
            'height': 'calc(100% - ' + (isMobile ? 0 : 72) + 'px)',
            'padding': '0 30px ' + (isMobile ? 64 : 12) + 'px 24px',
            'overflow': 'auto'
          }"
        >
        </share-access-list>

        <div v-if="!isMobile && editable" style="padding: 5px 12px;text-align: right;">
          <v-btn dark :color="btnColor" @click="saveMembers">
            Done
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import shareAccessList from './share-access-list'

export default {
  components: {
    shareAccessList
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    owner: {
      type: Object,
      default: () => ({})
    },
    members: {
      type: Array,
      default: () => ([])
    },
    editable: {
      type: Boolean,
      default: false
    },
    btnColor: {
      type: String,
      default: 'primary'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      myMembers: []
    }
  },
  computed: {
    ...mapGetters('app', [
      'isMobile'
    ])
  },
  methods: {
    closeShareTo () {
      this.$emit('change', false)
    },
    matchMembers (members) {
      this.myMembers = members.map(el => ({
        email: el.email || '',
        name: el.name || '',
        icon: el.icon || '',
        role: el.role || null,
      }))
    },
    changeMembers (members) {
      this.myMembers = members
      this.$emit('change-members', members)
    },
    saveMembers () {
      this.$emit('save-members', this.myMembers)
    }
  },
  // created () {
  //   this.matchMembers(this.members)
  // },
  watch: {
    value (val, oVal) {
      if (val && !oVal) {
        this.matchMembers(this.members)
      }
    },
    members: {
      deep: true,
      handler (val, oVal) {
        this.matchMembers(val)
      }
    }
  }
}
</script>

<style>
.role-card {
  width: 600px;
  max-width: 100%;
  overflow: hidden;
  height: 480px;
}
</style>
