var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"share-access-list"},[(_vm.editable)?_c('div',{staticClass:"mt-0"},[_c('account-autocomplete',{model:{value:(_vm.searchedMember),callback:function ($$v) {_vm.searchedMember=$$v},expression:"searchedMember"}})],1):_vm._e(),_c('div',{staticClass:"role-banner mt-6"},[_c('span',[_vm._v("Owner")])]),_c('div',{staticClass:"mt-4"},[_c('account-profile',{staticStyle:{"margin-left":"15px"},attrs:{"email":_vm.owner.email,"name":_vm.owner.name,"icon":_vm.owner.icon,"tag-icon-size":32,"tag-color":_vm.themeColor.default,"gap-of-tag-icon-and-name":24}})],1),_c('div',{staticClass:"role-banner mt-6"},[_c('span',[_vm._v("Members "),_c('span',{staticStyle:{"color":"grey"}},[_vm._v("("+_vm._s(_vm.myMembers.length)+")")])])]),_c('div',{style:({
      'position': 'absolute',
      'top': 210 - (_vm.editable ? 0 : 60) + 'px',
      'bottom': 0,
      'left': 0,
      'right': 0,
      'padding-left': '24px',
      'padding-right': (_vm.isMobile ? 30 : 12) + 'px',
      'padding-bottom': (_vm.isMobile ? 60 : 0) + 'px',
      'overflow-y': (_vm.isMobile ? 'auto' : 'scroll')
    })},[_vm._l((_vm.myMembers),function(member,mi){return [_c('div',{key:'shared-name-' + member.email,staticClass:"mt-0 d-flex align-center justify-space-between"},[_c('account-profile',{staticStyle:{"margin-left":"15px"},attrs:{"email":member.email,"name":member.name,"icon":member.icon,"tag-icon-size":32,"tag-color":_vm.themeColor.default,"gap-of-tag-icon-and-name":24}}),_c('v-btn',{staticClass:"my-0",attrs:{"small":"","text":"","icon":""},on:{"click":function($event){return _vm.removeMember(mi)}}},[_c('v-icon',{attrs:{"color":"grey","size":"22px"}},[_vm._v(" mdi-close ")])],1)],1)]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }