var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"history_container"},[_c('v-timeline',{staticClass:"history ml-n5",attrs:{"align-top":"","dense":""}},[_vm._l((_vm.history),function(h,hi){return [(hi < _vm.numOfView || _vm.numOfView < 0)?_c('v-timeline-item',{key:hi,attrs:{"small":"","right":"","color":_vm.spotColor || 'primary'}},[_c('div',{style:(Object.assign({}, (_vm.moreThan1Row ? {} : {
              'display': 'flex',
              'justify-content': 'flex-start'
            }),
            {'margin-top': '-16px',
            'margin-bottom': '18px',
            'margin-left': '-16px'}))},[_c('div',{style:(!_vm.moreThan2Row ? {
            'display': 'flex',
            'justify-content': 'flex-start'
          } : {})},[_c('div',{staticClass:"grey--text text-caption",style:({
                'min-width': '128px',
                'margin-right': '4px',
              })},[_vm._v(" "+_vm._s(h.time)+" ")]),_c('div',{style:(Object.assign({}, (!_vm.moreThan2Row ? {} : {
                  margin: '9px 0px 6px'
                }),
                (_vm.moreThan1Row ? {
                  width: '100%'
                } : {
                  width: '180px'
                })))},[(!!h.user.email)?_c('account-profile',{style:({width: _vm.toPixel(_vm.userWidth), 'max-width': '100%', 'margin-bottom': '-4.5px'}),attrs:{"email":h.user.email,"name":h.user.name,"icon":h.user.icon,"hide-tooltip":_vm.isMobile,"tag-align":"top","tag-color":_vm.themeColor.default,"tag-icon-size":30,"gap-of-tag-icon-and-name":12}}):_vm._e()],1)]),_c('div',{style:({ 'text-align': 'left', 'margin': _vm.moreThan1Row ? (!h.user.email ? (_vm.moreThan2Row ? 0 : '-21px') : '12px') + ' 0 9px ' + (_vm.moreThan2Row ? '0' : '132px') : '0' })},[_c('span',[_vm._v(_vm._s(h.action))]),(h.comment || (!!h.receivers && !!h.receivers.length))?_c('span',[_vm._v(_vm._s(!!h.receivers && !!h.receivers.length ? ' to' : '')+": ")]):_vm._e(),(!!h.receivers && !!h.receivers.length)?_c('div',{staticStyle:{"padding":"3px 0 3px 4px"}},_vm._l((h.receivers),function(receiver,ri){return _c('div',{key:'receiver_' + ri,staticStyle:{"padding":"4px 0"}},[(!!receiver.email)?_c('account-profile',{style:({width: _vm.toPixel(_vm.userWidth), 'max-width': '100%'}),attrs:{"email":receiver.email,"name":receiver.name,"icon":receiver.icon,"hide-tooltip":_vm.isMobile,"tag-align":"top","tag-color":_vm.themeColor.default,"tag-icon-size":24,"gap-of-tag-icon-and-name":14}}):_vm._e()],1)}),0):_vm._e(),(h.comment)?_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(h.comment))]):_vm._e(),(_vm.hasMore(h))?[_c('span',{style:(_vm.moreThan1Row ? {} : { 'margin-right': '18px' })}),(_vm.moreThan1Row)?_c('div',{staticStyle:{"margin-top":"9px"}}):_vm._e(),_c('span',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.isMobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(_vm.hasFiles(h))?_c('v-icon',_vm._g({staticStyle:{"margin":"-10px 12px -2px 0","cursor":"pointer"},attrs:{"size":"22","color":_vm.btnColor},on:{"click":function($event){return _vm.showFilesDialog(h)}}},on),[_vm._v(" mdi-file ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Updated Files")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.isMobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(false)?_c('v-icon',_vm._g({staticStyle:{"margin":"-10px 18px -3px 0","cursor":"pointer"},attrs:{"size":"24","color":_vm.btnColor}},on),[_vm._v(" more_horiz ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Details")])])],1)]:_vm._e()],2)]),(hi < _vm.history.length - 1 && (hi < _vm.numOfView - 1 || _vm.numOfView < 0))?_c('v-divider',{staticClass:"mb-4 ml-n4"}):_vm._e()],1):_vm._e()]})],2),_c('div',{staticClass:"mt-3",staticStyle:{"width":"100%","text-align":"center"}},[(_vm.numOfView > 0 && _vm.history.length > _vm.numOfView)?_c('v-tooltip',{attrs:{"nudge-right":"12","left":"","disabled":_vm.isMobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"view-more-workflow-btn",attrs:{"small":"","text":"","color":"grey"},on:{"click":function($event){return _vm.viewAll()}}},on),[_c('div',{staticClass:"view-more-workflow-btn-text btn-text-color-default"},[_c('v-icon',{staticClass:"view-more-workflow-btn-icon mt-n2",attrs:{"size":"24","color":_vm.btnColor}},[_vm._v("mdi-chevron-double-down")])],1)])]}}],null,false,595981804)},[_c('span',[_vm._v("View All")])]):_vm._e()],1),_c('v-dialog',{attrs:{"width":"fit-content","fullscreen":_vm.isMobile,"hide-overlay":_vm.isMobile,"transition":_vm.isMobile ? 'dialog-bottom-transition' : 'dialog-transition'},model:{value:(_vm.filesDialogShowing),callback:function ($$v) {_vm.filesDialogShowing=$$v},expression:"filesDialogShowing"}},[_c('v-card',{staticClass:"history-file-dialog-card"},[_c('v-list',{class:_vm.isMobile ? ['white', 'sidebar-top'] : ['white']},[_c('v-list-item',[(_vm.isMobile)?[_c('v-list-item-action',[(_vm.isMobile)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.closeAndClearFilesDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-spacer')]:_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{style:(_vm.isMobile ? {'text-align': 'center'} : {'font-size': '20px'})},[_vm._v(" Files ")])],1),_c('v-spacer'),_c('v-list-item-action',[(!_vm.isMobile)?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.closeAndClearFilesDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],2)],1),_c('file-list',{style:({
          'max-height': 'calc(' + (_vm.isMobile ? '100vh' : '90vh') + ' - 64px)',
          padding: (_vm.isMobile ? '12px' : '6px') + ' 12px 18px 12px',
          'overflow-y': 'auto'
        }),attrs:{"files":_vm.dialogFiles,"multiple-rows":_vm.windowWidth < 650},on:{"click-download":_vm.downloadFile}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }