var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"value":_vm.show,"offset-overflow":_vm.offsetOverflow,"bottom":_vm.bottom || !(_vm.top || _vm.left || _vm.right),"top":_vm.top,"left":_vm.left,"right":_vm.right,"disabled":_vm.hideTooltip || !this.info.name},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('msal-functions',{ref:_vm.refMsalFunction}),_c('div',_vm._g({staticStyle:{"width":"100%"},on:{"mouseenter":_vm.mouseenterTag,"mouseleave":_vm.mouseleaveTag}},on),[_vm._t("tag",[_c('div',_vm._g({class:{
            'account-profile-tag': true,
            'account-profile-flex-center': true,
            'account-profile-flex-top': _vm.tagAlign === 'top'
          }},on),[(!_vm.hideIcon)?_c('v-avatar',{style:({
              'margin-top': _vm.tagAlign === 'top' ? 'calc(((' + _vm.toPixel(_vm.tagFontSize) +' * 1.5) - ' + _vm.toPixel(_vm.tagIconSize) +')/2)' : 0
            }),attrs:{"color":"blue lighten-2","size":_vm.tagIconSize}},[(_vm.info.icon)?_c('v-img',{attrs:{"src":_vm.info.icon}}):(_vm.email)?_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(_vm._s(_vm.email[0].toUpperCase()))]):_vm._e()],1):_vm._e(),_c('div',{style:(Object.assign({}, {'padding': '0 12px 0 6px',
              'padding-left': _vm.toPixel(_vm.hideIcon ? 0 : _vm.gapOfTagIconAndName),
              'font-size': _vm.toPixel(_vm.tagFontSize),
              'z-index': '2',
              'max-width': 'calc(100% - ' + (_vm.hideIcon ? 0 : _vm.tagIconSize + 'px') + ')',
              color: _vm.tagColor},
              (_vm.tagOverflow === 'wrap' ? {
                'overflow-wrap': 'break-word',
                overflow: 'hidden'
              } : {
                'text-overflow': 'ellipsis', 
                'white-space': 'nowrap',
                overflow: 'hidden'
              })))},[_vm._v(" "+_vm._s(_vm.info.name)+" ")])],1)])],2)]}}],null,true)},[_c('div',{staticStyle:{"text-align":"left","padding":"3px 12px 12px 12px"},on:{"mouseenter":_vm.mouseenterTooltip,"mouseleave":_vm.mouseleaveTooltip}},[_c('div',[_c('div',{staticClass:"account-profile-flex-center",style:({
          'margin-top': _vm.toPixel(_vm.gapOfTitleAndContent),
          'margin-bottom': _vm.toPixel(_vm.gapOfTitleAndContent)
        })},[_c('v-avatar',{attrs:{"size":_vm.profileTagIconSize,"color":"blue lighten-2"}},[(_vm.info.icon)?_c('v-img',{attrs:{"src":_vm.info.icon}}):(_vm.email)?_c('span',{staticClass:"white--text font-weight-bold"},[_vm._v(_vm._s(_vm.email[0].toUpperCase()))]):_vm._e()],1),_c('div',{style:({
            'padding': '0 12px 0 6px',
            'padding-left': _vm.toPixel(_vm.gapOfTitleIconAndName),
            'font-size': _vm.toPixel(_vm.profileTitleFontSize)
          })},[_vm._v(" "+_vm._s(_vm.info.name)+" ")])],1),_c('div',{staticClass:"account-profile-flex-top account-profile-profiles"},[_c('v-icon',{staticStyle:{"line-height":"1.5"},attrs:{"size":_vm.profileFontSize}},[_vm._v(" mdi-email ")]),_c('div',{style:({ 'margin-left': _vm.toPixel(_vm.gapOfIconAndText), 'font-size': _vm.toPixel(_vm.profileFontSize) })},[_vm._v(" "+_vm._s(_vm.email)+" ")])],1),(!!_vm.info.title || !!_vm.info.department)?_c('div',{staticClass:"account-profile-flex-top account-profile-profiles"},[_c('v-icon',{staticStyle:{"line-height":"1.5"},attrs:{"size":_vm.profileFontSize}},[_vm._v(" mdi-account ")]),_c('div',{style:({ 'margin-left': _vm.toPixel(_vm.gapOfIconAndText), 'font-size': _vm.toPixel(_vm.profileFontSize) })},[_vm._v(" "+_vm._s([_vm.info.title, _vm.info.department].filter(function (el) { return el; }).join(' · '))+" ")])],1):_vm._e(),(!!_vm.info.location)?_c('div',{staticClass:"account-profile-flex-top account-profile-profiles"},[_c('v-icon',{staticStyle:{"line-height":"1.5"},attrs:{"size":_vm.profileFontSize}},[_vm._v(" mdi-map-marker ")]),_c('div',{style:({ 'margin-left': _vm.toPixel(_vm.gapOfIconAndText), 'font-size': _vm.toPixel(_vm.profileFontSize) })},[_vm._v(" "+_vm._s(_vm.info.location)+" ")])],1):_vm._e(),(!!_vm.info.phone)?_c('div',{staticClass:"account-profile-flex-top account-profile-profiles"},[_c('v-icon',{staticStyle:{"line-height":"1.5"},attrs:{"size":_vm.profileFontSize}},[_vm._v(" mdi-phone-classic ")]),_c('div',{style:({ 'margin-left': _vm.toPixel(_vm.gapOfIconAndText), 'font-size': _vm.toPixel(_vm.profileFontSize) })},[_vm._v(" "+_vm._s(_vm.info.phone)+" ")])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }