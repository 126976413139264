<template>
  <div ref="history_container">
    <v-timeline
      align-top
      dense
      class="history ml-n5"
    >
      <template v-for="(h, hi) in history">
        <v-timeline-item
          v-if="hi < numOfView || numOfView < 0"
          :key="hi"
          small
          right
          :color="spotColor || 'primary'"
        >
          <div
            :style="{
              ...(moreThan1Row ? {} : {
                'display': 'flex',
                'justify-content': 'flex-start'
              }),
              'margin-top': '-16px',
              'margin-bottom': '18px',
              'margin-left': '-16px'
            }"
          >
            <div :style="!moreThan2Row ? {
              'display': 'flex',
              'justify-content': 'flex-start'
            } : {}">
              <div class="grey--text text-caption"
                :style="{
                  'min-width': '128px',
                  'margin-right': '4px',
                }"
              >
                {{ h.time }}
              </div>
              <div 
                :style="{
                  ...(!moreThan2Row ? {} : {
                    margin: '9px 0px 6px'
                  }),
                  ...(moreThan1Row ? {
                    width: '100%'
                  } : {
                    width: '180px'
                  }),
                }"
              >
                <account-profile
                  v-if="!!h.user.email"
                  :email="h.user.email"
                  :name="h.user.name"
                  :icon="h.user.icon"
                  :hide-tooltip="isMobile"
                  tag-align="top"
                  :tag-color="themeColor.default"
                  :tag-icon-size="30"
                  :gap-of-tag-icon-and-name="12"
                  :style="{width: toPixel(userWidth), 'max-width': '100%', 'margin-bottom': '-4.5px'}"
                >
                </account-profile>
              </div>
            </div>
            <div :style="{ 'text-align': 'left', 'margin': moreThan1Row ? (!h.user.email ? (moreThan2Row ? 0 : '-21px') : '12px') + ' 0 9px ' + (moreThan2Row ? '0' : '132px') : '0' }">
              <span>{{ h.action }}</span><span v-if="h.comment || (!!h.receivers && !!h.receivers.length)">{{ !!h.receivers && !!h.receivers.length ? ' to' : ''}}: </span>
              <div v-if="!!h.receivers && !!h.receivers.length"
                style="padding:3px 0 3px 4px;"
              >
                <div
                  v-for="(receiver, ri) in h.receivers"
                  :key="'receiver_' + ri"
                  style="padding:4px 0;">
                  <account-profile
                    v-if="!!receiver.email"
                    :email="receiver.email"
                    :name="receiver.name"
                    :icon="receiver.icon"
                    :hide-tooltip="isMobile"
                    tag-align="top"
                    :tag-color="themeColor.default"
                    :tag-icon-size="24"
                    :gap-of-tag-icon-and-name="14"
                    :style="{width: toPixel(userWidth), 'max-width': '100%'}"
                  >
                  </account-profile>
                </div>
              </div>
              <span v-if="h.comment" class="grey--text">{{ h.comment }}</span>
              <template v-if="hasMore(h)">
                <span :style="moreThan1Row ? {} : { 'margin-right': '18px' }"></span>
                <div v-if="moreThan1Row" style="margin-top: 9px;"></div>
                <span>
                  <v-tooltip bottom :disabled="isMobile">
                    <template v-slot:activator="{ on }">
                      <v-icon size="22" :color="btnColor" style="margin: -10px 12px -2px 0;cursor: pointer;"
                        v-if="hasFiles(h)"
                        v-on="on"
                        @click="showFilesDialog(h)"
                      >
                        mdi-file
                      </v-icon>
                    </template>
                    <span>Updated Files</span>
                  </v-tooltip>
                  <v-tooltip bottom :disabled="isMobile">
                    <template v-slot:activator="{ on }">
                      <v-icon size="24" :color="btnColor" style="margin: -10px 18px -3px 0;cursor: pointer;"
                        v-if="false"
                        v-on="on"
                      >
                        more_horiz
                      </v-icon>
                    </template>
                    <span>Details</span>
                  </v-tooltip>
                </span>
              </template>
            </div>
          </div>
          <v-divider v-if="hi < history.length - 1 && (hi < numOfView - 1 || numOfView < 0)" class="mb-4 ml-n4"></v-divider>
        </v-timeline-item>
      </template>
    </v-timeline>

    <div class="mt-3" style="width: 100%;text-align: center;">
      <v-tooltip nudge-right="12" left v-if="numOfView > 0 && history.length > numOfView" :disabled="isMobile">
        <template v-slot:activator="{ on }">
            <v-btn small text color="grey" class="view-more-workflow-btn"
              v-on="on"
              @click="viewAll()"
            >
              <div class="view-more-workflow-btn-text btn-text-color-default">
                <v-icon class="view-more-workflow-btn-icon mt-n2" size="24" :color="btnColor">mdi-chevron-double-down</v-icon>
              </div>
              <!-- <div>View More</div> -->
            </v-btn>
        </template>
        <span>View All</span>
      </v-tooltip>
    </div>

    <v-dialog
      v-model="filesDialogShowing"
      width="fit-content"
      :fullscreen="isMobile"
      :hide-overlay="isMobile"
      :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
    >
      <v-card class="history-file-dialog-card">
        <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
          <v-list-item>
            <template v-if="isMobile">
              <v-list-item-action>
                <v-btn v-if="isMobile" text icon @click="closeAndClearFilesDialog">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>

              <v-spacer></v-spacer>
            </template>

            <v-list-item-content>
              <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                Files
              </v-list-item-title>
            </v-list-item-content>

            <v-spacer></v-spacer>

            <v-list-item-action>
              <v-btn v-if="!isMobile" text icon @click="closeAndClearFilesDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <file-list
          :files="dialogFiles"
          :multiple-rows="windowWidth < 650"
          :style="{
            'max-height': 'calc(' + (isMobile ? '100vh' : '90vh') + ' - 64px)',
            padding: (isMobile ? '12px' : '6px') + ' 12px 18px 12px',
            'overflow-y': 'auto'
          }"
          @click-download="downloadFile"
        ></file-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AccountProfile from '@/components/vuetify-extension/account-profile'
import fileList from '@/components/vuetify-extension/file-list'
import { xDownload } from '@/functions/http/axios'

export default {
  props: {
    history: {
      type: Array,
      required: true
      /**
        [{
          user: {
            email: [String] for email in <account-profile>,
            name: [String] for name in <account-profile>,
            icon: [String] for icon in <account-profile>,
          }
          time: [String] time record,
          action: [String] action record,
          comment: [String] comment record,
          files: [{
            url: [String] file src url,
            name: [String] file downloaded as name
          }, ...]
          receivers: [{
            email: [String] for email in <account-profile>,
            name: [String] for name in <account-profile>,
            icon: [String] for icon in <account-profile>,
          }, ...]
        }, ...]
       */
    },

    spotColor:{
      type: String,
      default: 'primary'
    },

    userWidth: {
      type: [String, Number],
      default: 200
    },

    btnColor: {
      type: String,
      default: 'primary'
    }
  },
  components: {
    fileList,
    AccountProfile
  },
  data () {
    return {
      filesDialogShowing: false,
      dialogFiles: [],
      myWidth: 0,
      numOfView: 3
    }
  },
  computed: {
    ...mapState('app', {
      windowWidth: state => state.windowSize.width,
      themeColor: state => state.color
    }),
    ...mapGetters('user', [
      'loginStatus'
    ]),
    ...mapGetters('app', [
      'isMobile'
    ]),
    moreThan1Row () {
      return this.myWidth < 600
    },
    moreThan2Row () {
      return this.moreThan1Row && this.myWidth < 400
    }
  },
  methods: {
    toPixel (value) {
      return typeof value === 'number' ? value + 'px' : value
    },
    hasFiles (history) {
      return history.files && history.files.filter(el => !!el.url && !!el.name).length > 0
    },
    hasMore (history) {
      return this.hasFiles(history)
    },
    showFilesDialog (history) {
      this.dialogFiles = history.files.map(el => ({
        url: el.url,
        name: el.name,
        download: true
      }))
      this.filesDialogShowing = true
    },
    closeAndClearFilesDialog () {
      this.filesDialogShowing = false
    },
    async downloadFile (data) {
      try {
        const res = await xDownload(
          data.item.url,
          data.item.name,
          { 
            loginStatus: this.loginStatus
          }
        )
        try {
          if (res.data.message === 'Authorize Failed') {
            this.$router.push('/login')
            return
          }
        } catch (e) {}
      } catch (e) {
        console.log('Error [download file]')
        // console.log(e)
      }
    },
    refreshMyWidth () {
      try {
        this.myWidth = this.$refs['history_container'].clientWidth
      } catch (e) {
        // console.log(e)
      }
    },
    viewAll () {
      this.numOfView = -1
    }
  },
  mounted () {
    this.refreshMyWidth()
    window.addEventListener("resize", this.refreshMyWidth)
  },
  destroyed () {
    window.removeEventListener("resize", this.refreshMyWidth)
  }
}
</script>

<style lang="scss">
.history {
  .history-content {
    padding-right: 0;
  }

  &.v-timeline--dense {
    .v-timeline-item__body {
      z-index: 2;
      max-width: calc(100% - 56px);
    }
  }

  .v-timeline-item {
    padding-bottom: 24px;

    &:last-child {
      padding-bottom: 0px;
    }
  }

  .v-timeline-item__dot--small {
    margin-top: -21px !important;
  }
}

.history-file-dialog-card {
  width: 400px;
  max-width: 100%;
  max-height: 90vh;
  overflow: hidden;
}
</style>
