<template>
  <v-select
    v-model="myValue"
    :label="label"
    :items="!!myValue ? (myItems.findIndex(item => item.email === myValue.email && item.name === myValue.name && (item.icon === myValue.icon || (!item.icon && !myValue.icon))) > -1 ? myItems : [{...myValue, disabled:true}].concat(myItems)) : myItems"
    :loading="false"
    :disabled="disabled"
    item-text="email"
    item-value="email"
    return-object
    :height="46"
    :rules="rules"
    @focus="focus"
    @change="$emit('change', {...myValue})"
    class="person-select"
  >
    <template v-slot:no-data>
      <div
        v-if="loading"
        :style="{
          margin: '12px 15px',
          display: 'flex',
          'align-items': 'center'
        }"
      >
        <v-progress-circular
          :width="4"
          color="grey"
          indeterminate
          :size="24"
        ></v-progress-circular>
        <div
          class="grey--text text--lighten-1"
          :style="{
            'margin-left': '16px'
          }"
        >
          Loading
        </div>
      </div>
      <div
        v-else
        :style="{
          margin: '12px 15px',
          display: 'flex',
          'align-items': 'center'
        }"
      >
        No Matched Data
      </div>
    </template>
    <template v-slot:selection="data">
      <div style="display:flex;align-items:center;">
        <v-avatar size="30" style="margin-right: 12px;">
          <img :src="data.item.icon || default_icon">
        </v-avatar>
        <div>{{ data.item.name }}</div>
      </div>
    </template>
    <template v-slot:item="data">
      <div
        v-if="!items.length"
        :style="{
          margin: '12px 0',
          display: 'flex',
          'align-items': 'center'
        }"
      >
        <v-progress-circular
          :width="4"
          color="grey"
          indeterminate
          :size="24"
        ></v-progress-circular>
        <div
          class="grey--text text--lighten-1"
          :style="{
            'margin-left': '16px'
          }"
        >
          Loading
        </div>
      </div>
      <v-list-item-content v-else-if="typeof data.item !== 'object'" v-text="data.item"></v-list-item-content>
      <div v-else :class="data.item.disabled?['person-select-disabled']:[]" style="display:flex;align-items:center;margin-right:8px">
        <v-avatar size="30" style="margin-right: 16px;">
          <img :src="data.item.icon || default_icon">
        </v-avatar>
        <div style="text-align:left;">
          <div style="height: 14px;line-height: 16px;">
            {{ data.item.name }}
          </div>
          <div style="height: 14px;line-height: 16px;color: grey">
            {{ data.item.email }}
        </div>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      default_icon: require('./default.jpg'),
      myValue: null,
      myItems: []
    }
  },
  methods: {
    focus (param) {
      this.$emit('focus', { ...param })
    },
    setMyValue (val) {
      this.myValue = null
      try {
        if (!!val.email) {
          this.myValue = {
            email: val.email,
            name: val.name || val.email,
            icon: val.icon || ''
          }
        }
      } catch (e) {}
    },
    setMyItems (items) {
      this.myItems = []
      try {
        let newItems = items
          .filter(item => !!item.email && !!item.name)
          .map(item => ({
            email: item.email || '',
            name: item.name || '',
            icon: item.icon || ''
          }))
        this.myItems = newItems
      } catch (e) {}
    }
  },
  created () {
    this.setMyValue(this.value)
    this.setMyItems(this.items)
  },
  watch: {
    value: {
      deep: true,
      handler (val, oVal) {
        this.setMyValue(val)
      }
    },
    items: {
      deep: true,
      handler (val, oVal) {
        this.setMyItems(val)
      }
    }
  }
}
</script>

<style lang="sass">
  .person-select
    .v-label--active
      -webkit-transform: translateY(-25px) scale(.75)
      transform: translateY(-25px) scale(.75)
    
  

  .person-select-disabled
    &:before
      border-radius: inherit
      color: inherit
      content: ""
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      opacity: .5
      transition: .3s cubic-bezier(.25,.8,.5,1)
      background-color: white
    
  
</style>
