<template>
  <v-fade-transition mode="out-in">
    <div class="legal-request-other-detail">
      <msal-functions :ref="refMsalFunction"></msal-functions>
      <div class="legal-request-other-detail-loading" v-if="isPageLoading">
        <v-progress-circular
          :width="5"
          color="grey"
          indeterminate
          :size="30"
        ></v-progress-circular>
        <div
          class="grey--text"
          :style="{
            'margin-top': '12px',
            'font-size': '14px'
          }"
        >
          Loading
        </div>
      </div>
      <v-layout wrap v-else>
        <v-flex
          xs12
          lg8
          order-xs2
          pt-0
          pb-0
        >
          <material-app-card
            id="legal-request-other-type"
            color="primary"
            icon="mdi-toolbox-outline"
            :title="(isPendingRequest ? '1.0 ' : '') + 'Request Type'"
          >
            <template v-slot>
              <v-form ref="legal-request-other-type-form" :lazy-validation="true">
                <v-layout row wrap class="v-card-x-layout">
                  <v-flex xs12 sm6 pt-3 pl-3 pr-3 pb-2 class="v-card-x-cell">
                    <div style="margin-top: -16px">
                      <v-select
                        v-model="request.type"
                        :items="requestTypesForSelect"
                        :disabled="!canEditRequestType"
                        label="Request Type"
                        ref="request_form_type"
                        item-text="text"
                        item-value="value"
                        :rules="[
                          v => !!requestTypesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!requestTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                  <v-flex xs12 pt-1 pl-3 pr-3 pb-2 class="v-card-x-cell"
                    v-if="canSelectGeneratorUsage"
                  >
                    <div style="margin-top: -16px">
                      <v-radio-group class="legal-request-other-radio-group-vertical"
                        :disabled="!canEditRequestType"
                        v-model="request.generatorUsage"
                        :rules="[v => (v === true || v === false) || 'Please select']"
                      >
                        <v-radio :value="true" color="primary">
                          <template v-slot:label>
                            <span>Contract Generator: client accept to use NielsenIQ template without any revision.</span>
                          </template>
                        </v-radio>
                        <v-radio :value="false" color="primary">
                          <template v-slot:label>
                            <span>Client request to use client template/ client request revision regarding to NielsenIQ template</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      tabindex="0"
                      ref="request_form_generator_usage"
                      style="height: 1px;"
                    ></div>
                  </v-flex>
                  <v-flex xs12 class='v-flex-row-breaker'>
                  </v-flex>
                  <template v-if="!isPageLoading && canEditRequestType">
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="error lighten-1"
                          v-if="isNewRequest"
                          @click="clickCancelNewRequest"
                        >
                          Cancel
                        </v-btn>
                      </div>
                    </v-flex>
                    <template>
                      <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                        <div class="bottom-btn-container-right">
                          <v-btn color="primary"
                            @click="clickNextDraftStep"
                          >
                            Next
                          </v-btn>
                        </div>
                      </v-flex>
                    </template>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>

          <material-app-card
            id="legal-request-other-client-request-form"
            color="primary"
            icon="mdi-text-box-outline"
            v-if="(!isPendingRequest || isDraftStepInClientRequestForm || isDraftStepInDraftedContract || isDraftStepInContractInfo || isDraftStepInEPMUpload || isDraftStepInApprovalInfo) && needClientRequestForm"
            :title="(isPendingRequest ? '2.0 Upload ' : '') + 'Client Request Form'"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-3 pb-4 px-4 class="v-card-x-cell">
                  <div>Please upload the completed client request form here. This is a required document and should be signed (with company stamp) by your client. </div>
                  <div>请在此处上传完整的申请表。申请表为必要文件，须客户签名且盖公章。</div>
                  <div v-if="templateURLClientRequestForm" class="pt-4">
                    <div>Please refer to our template <a class="template" target="_new" :href="templateURLClientRequestForm">HERE.</a></div>
                    <div>请<a class="template" target="_new" :href="templateURLClientRequestForm">点击这里</a>，获取更多模板。</div>
                  </div>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell" v-if="canEditClientRequestForm">
                  <div style="width: 100%;display: flex;align-items: center;">
                    <x-upload
                      request-class="file"
                      request-function="upload"
                      accept=".pdf"
                      :params="{
                        email: userEmail,
                        loginStatus: loginStatus
                      }"
                      :before-upload="beforeUploadClientRequetForm"
                      :on-error="errorUploadClientRequetForm"
                      :on-success="successUploadClientRequetForm"
                    >
                      <v-btn text
                        style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                      >
                        <v-icon color="primary" size="24" style="margin-right:12px;">
                          mdi-upload
                        </v-icon>
                        <div v-if="request.clientRequestForm.length > 0">Upload more <b>Client Request Form</b> (PDF Only)</div>
                        <div v-else>Please upload <b>Client Request Form</b> (PDF Only)</div>
                      </v-btn>
                    </x-upload>
                  </div>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <file-list
                    :files="request.clientRequestForm"
                    :deletable="canEditClientRequestForm"
                    :multiple-rows="windowWidth < 650"
                    @click-download="downloadFile"
                    @click-delete="deleteClientRequestForm"
                  ></file-list>
                  <div
                    tabindex="0"
                    ref="legal_request_other_form_client_request_form"
                    style="height: 1px;"
                  ></div>
                </v-flex>
                <template v-if="!isPageLoading && canEditClientRequestForm">
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container">
                      <v-btn color="primary"
                        @click="clickPrevDraftStep"
                      >
                        Previous
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container-right">
                      <v-btn color="primary"
                        :disabled="!request.clientRequestForm.length"
                        @click="clickNextDraftStep"
                      >
                        Next
                      </v-btn>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="legal-request-other-drafted-contract"
            color="primary"
            icon="mdi-text-box-check-outline"
            v-if="(!isPendingRequest || isDraftStepInDraftedContract || isDraftStepInContractInfo || isDraftStepInEPMUpload || isDraftStepInApprovalInfo) && !request.generatorUsage"
            :title="(isPendingRequest ? '2.' + (0 + !!needClientRequestForm) +' Upload ' : '') + (draftedContractTitle || 'Drafted Contract')"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-3 pb-4 px-4 class="v-card-x-cell">
                  <div v-if="typeof draftedContractLabelEn === 'string' && draftedContractLabelEn">{{ draftedContractLabelEn }}</div>
                  <div v-else>Please ONLY upload the documents you need to sign. (Other supporting document you can upload with "comments")</div>
                  <div v-if="typeof draftedContractLabelEn === 'string' && draftedContractLabelEn && typeof draftedContractLabelCn === 'string' && draftedContractLabelCn">{{ draftedContractLabelCn }}</div>
                  <div v-if="templateURLDraftedContract" class="pt-4">
                    <div>Please refer to our template <a class="template" target="_new" :href="templateURLDraftedContract">HERE</a>.</div>
                    <div>请<a class="template" target="_new" :href="templateURLDraftedContract">点击这里</a>，获取更多模板。</div>
                  </div>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell" v-if="canEditDraftedContract">
                  <div style="width: 100%;display: flex;align-items: center;">
                    <x-upload
                      request-class="file"
                      request-function="upload"
                      accept=".doc,.docx,.pdf"
                      :params="{
                        email: userEmail,
                        loginStatus: loginStatus
                      }"
                      :before-upload="beforeUploadDraftedContract"
                      :on-error="errorUploadDraftedContract"
                      :on-success="successUploadDraftedContract"
                    >
                      <v-btn text
                        style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                      >
                        <v-icon color="primary" size="24" style="margin-right:12px;">
                          mdi-upload
                        </v-icon>
                        <div v-if="request.draftedContract.length > 0">Upload more <b>{{ draftedContractButtonType || 'Client Confirm Contract' }}</b></div>
                        <div v-else>Please upload <b>{{ draftedContractButtonType || 'Client Confirm Contract' }}</b></div>
                      </v-btn>
                    </x-upload>
                  </div>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <file-list
                    :files="request.draftedContract"
                    :deletable="canEditDraftedContract"
                    :multiple-rows="windowWidth < 650"
                    @click-download="downloadFile"
                    @click-delete="deleteDraftedContract"
                  ></file-list>
                  <div
                    tabindex="0"
                    ref="legal_request_other_form_draft_contract"
                    style="height: 1px;"
                  ></div>
                </v-flex>
                <template v-if="!isPageLoading && canEditDraftedContract">
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container">
                      <v-btn color="primary"
                        @click="clickPrevDraftStep"
                      >
                        Previous
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container-right">
                      <v-btn color="primary"
                        :disabled="!request.draftedContract.length"
                        @click="clickNextDraftStep"
                      >
                        Next
                      </v-btn>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="legal-request-other-form-card"
            color="primary"
            icon="mdi-file-document-edit-outline"
            v-if="(!isPendingRequest || isDraftStepInContractInfo || isDraftStepInGeneratedContract || isDraftStepInEPMUpload || isDraftStepInApprovalInfo) && needForm"
            :title="(isPendingRequest ? ('2.' + (0 + !request.generatorUsage + !!needClientRequestForm) + ' ') : '') + (formTitle || 'Contract Information')"
          >
            <template v-slot>
              <v-form ref="legal-request-other-form" :lazy-validation="true">
                <v-layout row wrap class="v-card-x-layout pb-1">
                  <v-flex xs12
                    v-if="isPageLoading"
                    :style="{
                      margin: '12px',
                      display: 'flex',
                      'align-items': 'center',
                      'justify-content': 'center'
                    }"
                  >
                    <v-progress-circular
                      :width="4"
                      color="grey"
                      indeterminate
                      :size="24"
                    ></v-progress-circular>
                    <div
                      class="grey--text text--lighten-1"
                      :style="{
                        'margin-left': '9px'
                      }"
                    >
                      Loading
                    </div>
                  </v-flex>
                  <template v-else>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needLanguage"
                    >
                      <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && !request.language ? ['red--text'] : []), ...(canEditRequestType ? [] : ['my-disabled--text'])]">Language</div>
                      <div>
                        <v-radio-group row class="legal-request-other-radio-group-horizonal"
                          :disabled="!canEditRequestForm"
                          v-model="request.language"
                          :rules="[v => (!!v && v != '') || 'Please select']"
                        >
                          <v-radio label="English" value="English" color="primary"></v-radio>
                          <v-radio label="中文" value="Chinese" color="primary"></v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="legal_request_other_form_language"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needProduct"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="Product"
                        :items="productsForSelect"
                        item-text="text"
                        v-model="request.product"
                        ref="legal_request_other_form_product"
                        :rules="[
                          v => !!productsForSelect.find(el => el.value === v) || 'Please select',
                          v => !!productsForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needVertical"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="Vertical"
                        :items="verticalsForSelect"
                        item-text="text"
                        v-model="request.vertical"
                        ref="legal_request_other_form_vertical"
                        :rules="[
                          v => !!verticalsForSelect.find(el => el.value === v) || 'Please select',
                          v => !!verticalsForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needSoldTo"
                    >
                      <v-combobox
                        :disabled="!canEditRequestForm"
                        label="Sold To Party (SAP)"
                        ref="legal_request_other_form_sold_to"
                        no-filter
                        :search-input.sync="soldToKey"
                        v-model="request.soldTo"
                        :items="soldToItems"
                        :loading="soldToLoading"
                        :rules="[v => !!v || 'Please input and select']"
                        @blur="() => request.soldTo = soldToKey"
                      ></v-combobox>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needOpportunity"
                    >
                      <v-text-field
                        :disabled="!canEditRequestForm"
                        label="Project Number (Opportunity ID)"
                        v-model="request.opportunityId"
                        ref="legal_request_other_form_opportunity_id"
                        :class="{
                          'black--text': canEditRequestForm,
                        }"
                        @focus="addingOpportunity = true"
                        :rules="[v => !!v || 'Please input and select']"
                      >
                      <!-- :append-icon="canEditRequestForm ? 'mdi-magnify' : ''" -->
                        <template v-slot:append>
                          <v-icon v-if="canEditRequestForm" color="primary" size="24">
                            mdi-magnify
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needNielsenEntityName"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="NielsenIQ Entity Name"
                        :items="salesOrgsForSelect"
                        :item-text="needLanguage ? request.language === 'Chinese' ? 'textCn' : 'textEn' : 'text'"
                        :placeholder="salesOrgPlaceholder"
                        v-model="request.nielsenEntityName"
                        ref="legal_request_other_form_nielsen_entity_name"
                        :rules="[
                          v => !!salesOrgsForSelect.find(el => el.value === v) || 'Please select',
                          v => !!salesOrgsForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div
                        v-if="needClientEntityName"
                      >
                        <v-combobox
                          :disabled="!canEditRequestForm"
                          label="Client Entity Name"
                          ref="legal_request_other_form_client_entity_name"
                          no-filter
                          :search-input.sync="clientEntityNameKey"
                          v-model="request.clientEntityName"
                          :items="clientEntityNameItems"
                          :loading="clientEntityNameLoading"
                          :rules="[v => !!v || 'Please input and select']"
                          @blur="() => request.clientEntityName = clientEntityNameKey"
                        ></v-combobox>
                      </div>
                      <div
                        v-if="needRetailerEntityName"
                      >
                        <v-combobox
                          :disabled="!canEditRequestForm"
                          label="Retailer Entity Name"
                          ref="legal_request_other_form_retailer_entity_name"
                          no-filter
                          :search-input.sync="retailerEntityNameKey"
                          v-model="request.retailerEntityName"
                          :items="retailerEntityNameItems"
                          :loading="retailerEntityNameLoading"
                          :rules="[v => !!v || 'Please input and select']"
                          @change="changeRetailerEntityName"
                          @blur="() => request.retailerEntityName = retailerEntityNameKey"
                        ></v-combobox>
                      </div>
                      <div
                        v-if="needCooperatorEntityname"
                      >
                        <v-combobox
                          :disabled="!canEditRequestForm"
                          label="Cooperator Entity Name"
                          ref="legal_request_other_form_cooperator_entity_name"
                          no-filter
                          :search-input.sync="cooperatorEntityNameKey"
                          v-model="request.cooperatorEntityName"
                          :items="cooperatorEntityNameItems"
                          :loading="cooperatorEntityNameLoading"
                          :rules="[v => !!v || 'Please input and select']"
                          @change="changeCooperatorEntityName"
                          @blur="() => request.cooperatorEntityName = cooperatorEntityNameKey"
                        ></v-combobox>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needNielsenResponsibleContactPerson"
                    >
                      <div class="nielsen-responsible-contact-person">
                        <account-autocomplete
                          v-model="request.nielsenResponsibleContactPerson"
                          label="NielsenIQ Responsible Contact Person"
                          :disabled="!canEditRequestForm"
                          :hide-details="false"
                          @change="changeNielsenResponsibleContactPerson"
                          :rules="[v => !!v.email || 'Please input and select']"
                        ></account-autocomplete>
                      </div>
                      <div
                        tabindex="0"
                        ref="legal_other_request_form_nielsen_responsible_contact_person"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needRetailerResponsibleContactPerson"
                    >
                      <div class="retailer-responsible-contact-person">
                        <v-text-field
                          v-model="request.retailerResponsibleContactPerson"
                          label="Retailer Responsible Contact Person"
                          ref="legal_other_request_form_retailer_responsible_contact_person"
                          :disabled="!canEditRequestForm"
                          :rules="[v => !!v || 'Please input']"
                          @input="changeRetailerResponsibleContactPerson"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needNielsenEntityAddress"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.nielsenEntityAddress ? ['red--text'] : [])]">NielsenIQ Entity Address</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenNielsenEntityAddress"
                          v-model="request.nielsenEntityAddress"
                          :readonly="!canEditRequestForm"
                          ref="legal_other_request_form_nielsen_entity_address"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changeNielsenEntityAddress"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needRetailerEntityAddress"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.retailerEntityAddress ? ['red--text'] : [])]">Retailer Entity Address</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenRetailerEntityAddress"
                          v-model="request.retailerEntityAddress"
                          :readonly="!canEditRequestForm"
                          ref="legal_other_request_form_retailer_entity_address"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changeRetailerEntityAddress"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needContractEffectiveDate"
                    >
                      <div>
                        <v-dialog
                          ref="legal_other_request_form_contract_effective_date"
                          v-model="isPickerContractEffectiveDateDisplayed"
                          :return-value.sync="request.contractEffectiveDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="request.contractEffectiveDate"
                              label="Contract Effective Date"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!canEditRequestForm"
                              ref="legal_other_request_form_contract_effective_date_text_field"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="request.contractEffectiveDate" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedContractEffectiveDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="isPickerContractEffectiveDateDisplayed = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="clickOkContractEffectiveDate">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needContractEndDate"
                    >
                      <div>
                        <v-dialog
                          ref="legal_other_request_form_contract_end_date"
                          v-model="isPickerContractEndDateDisplayed"
                          :return-value.sync="request.contractEndDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="request.contractEndDate"
                              label="Contract End Date"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!canEditRequestForm"
                              ref="legal_other_request_form_contract_end_date_text_field"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="request.contractEndDate" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedContractEndDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="isPickerContractEndDateDisplayed = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="clickOkContractEndDate">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needContractSigningDate"
                    >
                      <div>
                        <v-dialog
                          ref="legal_other_request_form_contract_signing_date"
                          v-model="isPickerContractSigningDateDisplayed"
                          :return-value.sync="request.contractSigningDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="request.contractSigningDate"
                              label="Contract Signing Date"
                              append-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :disabled="!canEditRequestForm"
                              ref="legal_other_request_form_contract_signing_date_text_field"
                              :rules="[v => (!!v && v != '') || 'Please select']"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="request.contractSigningDate" scrollable color="primary" header-color="primary"
                            :allowed-dates="allowedContractSigningDates"
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" class="mb-2" @click="isPickerContractSigningDateDisplayed = false">CANCEL</v-btn>
                            <v-btn dark color="primary" class="mb-2" @click="clickOkContractSigningDate">OK</v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needProjectDescriptions"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.projectDescriptions ? ['red--text'] : [])]">Project Descriptions</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenProjectDescriptions"
                          v-model="request.projectDescriptions"
                          :readonly="!canEditRequestForm"
                          ref="legal_other_request_form_project_descriptions"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changeProjectDescriptions"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needNielsenAppointedEntityNamePayee"
                    >
                      <div>
                        <v-combobox
                          :disabled="!canEditRequestForm"
                          label="NielsenIQ Appointed Entity Name (Payee)"
                          ref="legal_request_other_form_nielsen_appointed_entity_name_payee"
                          no-filter
                          :search-input.sync="nielsenAppointedEntityNamePayeeKey"
                          v-model="request.nielsenAppointedEntityNamePayee"
                          :items="nielsenAppointedEntityNamePayeeItems"
                          :rules="[v => !!v || 'Please input and select']"
                          @change="changeNielsenAppointedEntityNamePayee"
                          @blur="() => request.nielsenAppointedEntityNamePayee = nielsenAppointedEntityNamePayeeKey"
                        ></v-combobox>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needNielsenAssigningEntityNamePayer"
                    >
                      <div>
                        <v-combobox
                          :disabled="!canEditRequestForm"
                          label="NielsenIQ Assigning Entity Name (Payer)"
                          ref="legal_request_other_form_nielsen_assigning_entity_name_payer"
                          no-filter
                          :search-input.sync="nielsenAssigningEntityNamePayerKey"
                          v-model="request.nielsenAssigningEntityNamePayer"
                          :items="nielsenAssigningEntityNamePayerItems"
                          :rules="[v => !!v || 'Please input and select']"
                          @change="changeNielsenAssigningEntityNamePayer"
                          @blur="() => request.nielsenAssigningEntityNamePayer = nielsenAssigningEntityNamePayerKey"
                        ></v-combobox>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPayeeEntityAddress"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.payeeEntityAddress ? ['red--text'] : [])]">Payee Entity Address</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenPayeeEntityAddress"
                          v-model="request.payeeEntityAddress"
                          :readonly="!canEditRequestForm"
                          ref="legal_other_request_form_payee_entity_address"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changePayeeEntityAddress"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPayerEntityAddress"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.payerEntityAddress ? ['red--text'] : [])]">Payer Entity Address</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenPayerEntityAddress"
                          v-model="request.payerEntityAddress"
                          :readonly="!canEditRequestForm"
                          ref="legal_other_request_form_payer_entity_address"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changePayerEntityAddress"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPayeeEntityTelephoneNumber"
                    >
                      <div class="payee-entity-telephone-number">
                        <v-text-field
                          v-model="request.payeeEntityTelephoneNumber"
                          :disabled="!canEditRequestForm"
                          label="Payee Entity Telephone Number"
                          ref="legal_other_request_form_payee_entity_telephone_number"
                          :rules="[v => !!v || 'Please input']"
                          @input="changePayeeEntityTelephoneNumber"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPayerEntityTelephoneNumber"
                    >
                      <div class="payer-entity-telephone-number">
                        <v-text-field
                          v-model="request.payerEntityTelephoneNumber"
                          :disabled="!canEditRequestForm"
                          label="Payer Entity Telephone Number"
                          ref="legal_other_request_form_payer_entity_telephone_number"
                          :rules="[v => !!v || 'Please input']"
                          @input="changePayerEntityTelephoneNumber"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPayeeEntityFaxNumber"
                    >
                      <div class="payee-entity-fax-number">
                        <v-text-field
                          v-model="request.payeeEntityFaxNumber"
                          :disabled="!canEditRequestForm"
                          label="Payee Entity Fax Number"
                          ref="legal_other_request_form_payee_entity_fax_number"
                          :rules="[v => !!v || 'Please input']"
                          @input="changePayeeEntityFaxNumber"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPayerEntityFaxNumber"
                    >
                      <div class="payer-entity-fax-number">
                        <v-text-field
                          v-model="request.payerEntityFaxNumber"
                          :disabled="!canEditRequestForm"
                          label="Payer Entity Fax Number"
                          ref="legal_other_request_form_payer_entity_fax_number"
                          @input="changePayerEntityFaxNumber"
                          :rules="[v => !!v || 'Please input']"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needProjectNumber"
                    >
                      <div class="project-number">
                        <v-text-field
                          v-model="request.projectNumber"
                          :disabled="!canEditRequestForm"
                          label="Project Number"
                          ref="legal_other_request_form_project_number"
                          @input="changeProjectNumber"
                          :rules="[v => !!v || 'Please input']"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needFieldWorkCountry"
                    >
                      <div class="field-work-country">
                        <v-text-field
                          v-model="request.fieldWorkCountry"
                          :disabled="!canEditRequestForm"
                          label="Field Work Country"
                          ref="legal_other_request_form_field_work_country"
                          @input="changeFieldWorkCountry"
                          :rules="[v => !!v || 'Please input']"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needStaffSendToAbroad"
                    >
                      <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && (request.staffSendToAbroad === null) ? ['red--text'] : []), ...(canEditRequestType ? [] : ['my-disabled--text'])]">Staff send to abroad</div>
                      <div>
                        <v-radio-group row class="legal-request-other-radio-group-horizonal"
                          :disabled="!canEditRequestForm"
                          v-model="request.staffSendToAbroad"
                          :rules="[v => (v !== null && v !== '') || 'Please select']"
                          @change="changeStaffSendToAbroad"
                        >
                          <v-radio :value="true" color="primary">
                            <template v-slot:label>
                              <span>YES</span>
                            </template>
                          </v-radio>
                          <v-radio :value="false" color="primary">
                            <template v-slot:label>
                              <span>NO</span>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="legal_other_request_form_staff_send_to_abroad"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needProjectDuration"
                    >
                      <div class="field-work-country">
                        <v-text-field
                          v-model="request.projectDuration"
                          :disabled="!canEditRequestForm"
                          label="Project Duration"
                          ref="legal_other_request_form_project_duration"
                          @input="changeProjectDuration"
                          :rules="[v => !!v || 'Please input']"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needLaborEntryFromOverseas"
                    >
                      <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && (request.laborEntryFromOverseas === null) ? ['red--text'] : []), ...(canEditRequestType ? [] : ['my-disabled--text'])]">
                        <div>Labor entry from overseas</div>
                        <div>(incl. Hong Kong, Macao and Taiwan)</div>
                      </div>
                      <div style="margin-top: 36px">
                        <v-radio-group row class="legal-request-other-radio-group-horizonal"
                          :disabled="!canEditRequestForm"
                          v-model="request.laborEntryFromOverseas"
                          :rules="[v => (v !== null && v !== '') || 'Please select']"
                          @change="changeLaborEntryFromOverseas"
                        >
                          <v-radio :value="true" color="primary">
                            <template v-slot:label>
                              <span>YES</span>
                            </template>
                          </v-radio>
                          <v-radio :value="false" color="primary">
                            <template v-slot:label>
                              <span>NO</span>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <div
                        tabindex="0"
                        ref="legal_other_request_form_labor-entryfrom-overseas"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needServiceType"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="Service Type"
                        :items="serviceTypesForSelect"
                        item-text="value"
                        v-model="request.serviceType"
                        ref="legal_request_other_form_service_type"
                        :rules="[
                          v => !!serviceTypesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!serviceTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needCurrency"
                    >
                      <v-select
                        :disabled="!canEditRequestForm"
                        label="Currency"
                        :items="currenciesForSelect"
                        item-text="text"
                        v-model="request.currency"
                        ref="legal_request_other_form_currency"
                        :rules="[
                          v => !!currenciesForSelect.find(el => el.value === v) || 'Please select',
                          v => !!currenciesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                        ]"
                        @change="changeCurrency"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needServiceFeeAmount"
                    >
                      <div class="v-card-contract-amount">
                        <v-text-field
                          append-icon="mdi-pencil"
                          :label="'Service Fee Amount' + (!!serviceFeeAmountToThousands ? ': ' + serviceFeeAmountToThousands : '')"
                          type="number"
                          :disabled="!canEditRequestForm"
                          v-model="request.serviceFeeAmount"
                          @blur="blurServiceFeeAmount"
                          ref="legal_request_other_form_service_fee_amount"
                          :rules="[
                            v => !isNaN(parseFloat(v)) || 'Please input number',
                            v => parseFloat(v) > 0 || 'Amount should be greater than 0'
                          ]"
                          @input="changeSrvFeeAmount"
                        >
                        </v-text-field>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needProjectNameAndDescriptions"
                    >
                      <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.projectNameAndDescriptions ? ['red--text'] : [])]">Project Name and Descriptions</div>
                      <div class="title-solo-textarea">
                        <v-textarea
                          solo
                          no-resize
                          rows="3"
                          placeholder="Please input"
                          style="margin-top: 0px"
                          :counter="maxLenProjectNameAndDescriptions"
                          v-model="request.projectNameAndDescriptions"
                          :readonly="!canEditRequestForm"
                          ref="legal_other_request_form_project_name_and_descriptions"
                          :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                          @input="changeProjectNameAndDescriptions"
                        >
                        </v-textarea>
                      </div>
                    </v-flex>
                    <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPayerEntityContactPerson"
                    >
                      <div class="payer-entity-contact-person">
                        <account-autocomplete
                          v-model="request.payerEntityContactPerson"
                          label="Payer Entity Contact Person"
                          :disabled="!canEditRequestForm"
                          :hide-details="false"
                          @change="changePayerEntityContactPerson"                          
                          :rules="[v => !!v.email || 'Please input and select']"
                        ></account-autocomplete>
                      </div>
                      <div
                        tabindex="0"
                        ref="legal_other_request_form_payer_entity_contact_person"
                        style="height: 1px;"
                      ></div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needCIIsListProvided"
                    >
                      <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && !request.ci.isListProvided ? ['red--text'] : []), ...(canEditRequestForm ? [] : ['my-disabled--text'])]">
                        <div>Was the city/store/brand list provided by the client during the research design process?</div>
                        <div>项目设计过程中是否使用了客户提供的城市/店铺或品牌列表等</div>
                      </div>
                      <div style="margin-top: 24px">
                        <v-select
                          :disabled="!canEditRequestForm"
                          :items="getOptions(options.ci.isListProvided, request.ci.isListProvided)"
                          v-model="request.ci.isListProvided"
                          ref="legal_request_other_form_ci_is_list_provided"
                          :rules="[
                            v => !!options.ci.isListProvided.find(el => el.value === v) || 'Please select',
                            v => !!options.ci.isListProvided.find(el => (el.value === v) && !!el.active) || 'Selection invalid. Please select another one',
                          ]"
                        >
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needPurpose"
                    >
                      <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && !request.ci.isListProvided ? ['red--text'] : []), ...(canEditRequestForm ? [] : ['my-disabled--text'])]">
                        <div>Purpose of NielsenIQ's involvement in the external publicity materials</div>
                        <div>尼尔森爱科参与对外宣传发表的目的</div>
                      </div>
                      <div style="margin-top: 24px">
                        <v-select
                          :disabled="!canEditRequestForm"
                          :items="getOptions(options.purposes, request.purpose)"
                          v-model="request.purpose"
                          ref="legal_request_other_form_ci_is_list_provided"
                          :rules="[
                            v => !!options.purposes.find(el => el.value === v) || 'Please select',
                            v => !!options.purposes.find(el => (el.value === v) && !!el.active) || 'Selection invalid. Please select another one',
                          ]"
                        >
                        </v-select>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                      v-if="request.generatorUsage && needOtherRequiredDataElements"
                    >
                      <div
                        v-if="!canEditRequestForm"
                        class="v-card-x-cell-title pb-1"
                      >
                        Other Required Data Elements
                      </div>
                      <div v-else style="width: 100%;display: flex;align-items: center;">
                        <x-upload
                          request-class="file"
                          request-function="upload"
                          accept=".docx"
                          :params="{
                            email: userEmail,
                            loginStatus: loginStatus
                          }"
                          :before-upload="beforeUploadOtherRequiredDataElements"
                          :on-error="errorUploadOtherRequiredDataElements"
                          :on-success="successUploadOtherRequiredDataElements"
                        >
                          <v-btn text
                            v-if="request.otherRequiredDataElements.length === 0"
                            style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          >
                            <v-icon color="primary" size="24" style="margin-right:12px;">
                              mdi-upload
                            </v-icon>
                            <div>Upload <b>Other Required Data Elements</b> (Word .DOCX Only)</div>
                          </v-btn>
                        </x-upload>

                        <v-btn text
                          v-if="request.otherRequiredDataElements.length > 0"
                          style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          @click="isOtherRequiredDataElementsUploadConfirming = true"
                        >
                          <v-icon color="primary" size="24" style="margin-right:12px;">
                            mdi-upload
                          </v-icon>
                          <div>Change <b>Other Required Data Elements</b> (Word .DOCX Only)</div>
                        </v-btn>
                      </div>

                      <v-expand-transition>
                        <div 
                          v-if="isRequestInfoChecked && request.otherRequiredDataElements.length === 0" 
                          class="red--text"
                          style="font-size:12px;"
                        >
                          Please upload Other Required Data Elements
                        </div>
                      </v-expand-transition>
                      
                      <div style="padding-bottom: 16px;">
                        <file-list
                          :files="request.otherRequiredDataElements"
                          :deletable="canEditRequestForm"
                          :multiple-rows="windowWidth < 650"
                          @click-download="downloadFile"
                          @click-delete="deleteOtherRequiredDataElements"
                        ></file-list>
                        <div
                          tabindex="0"
                          ref="legal_request_other_other_required_data_elements"
                          style="height: 1px;"
                        ></div>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                    <v-flex xs12 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                      v-if="request.generatorUsage && needNielsenServiceSuppliedToCooperator"
                    >
                      <div
                        v-if="!canEditRequestForm"
                        class="v-card-x-cell-title pb-1"
                      >
                        NielsenIQ Service Supplied to Cooperator
                      </div>
                      <div v-else style="width: 100%;display: flex;align-items: center;">
                        <x-upload
                          request-class="file"
                          request-function="upload"
                          accept=".docx"
                          :params="{
                            email: userEmail,
                            loginStatus: loginStatus
                          }"
                          :before-upload="beforeUploadNielsenServiceSuppliedToCooperator"
                          :on-error="errorUploadNielsenServiceSuppliedToCooperator"
                          :on-success="successUploadNielsenServiceSuppliedToCooperator"
                        >
                          <v-btn text
                            v-if="request.nielsenServiceSuppliedToCooperator.length === 0"
                            style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          >
                            <v-icon color="primary" size="24" style="margin-right:12px;">
                              mdi-upload
                            </v-icon>
                            <div>Upload <b>NielsenIQ Service Supplied to Cooperator</b> (Word .DOCX Only)</div>
                          </v-btn>
                        </x-upload>

                        <v-btn text
                          v-if="request.nielsenServiceSuppliedToCooperator.length > 0"
                          style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                          @click="isNielsenServiceSuppliedToCooperatorUploadConfirming = true"
                        >
                          <v-icon color="primary" size="24" style="margin-right:12px;">
                            mdi-upload
                          </v-icon>
                          <div>Change <b>NielsenIQ Service Supplied to Cooperator</b> (Word .DOCX Only)</div>
                        </v-btn>
                      </div>

                      <v-expand-transition>
                        <div 
                          v-if="isRequestInfoChecked && request.nielsenServiceSuppliedToCooperator.length === 0" 
                          class="red--text"
                          style="font-size:12px;"
                        >
                          Please upload NielsenIQ Service Supplied to Cooperator
                        </div>
                      </v-expand-transition>
                      
                      <div style="padding-bottom: 16px;">
                        <file-list
                          :files="request.nielsenServiceSuppliedToCooperator"
                          :deletable="canEditRequestForm"
                          :multiple-rows="windowWidth < 650"
                          @click-download="downloadFile"
                          @click-delete="deleteNielsenServiceSuppliedToCooperator"
                        ></file-list>
                        <div
                          tabindex="0"
                          ref="legal_request_other_nielsen_service_supplied_to_cooperator"
                          style="height: 1px;"
                        ></div>
                      </div>
                    </v-flex>
                    <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                  </template>
                  <template v-if="!isPageLoading && canEditRequestForm">
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="primary"
                          @click="clickPrevDraftStep"
                        >
                          Previous
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container-right">
                        <v-btn color="primary"
                          @click="clickSaveRequest"
                        >
                          Save
                        </v-btn>
                        <v-btn color="primary"
                          @click="clickNextDraftStep"
                        >
                          Next
                        </v-btn>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>

          <material-app-card
            id="legal-request-other-generated-contract"
            color="primary"
            icon="mdi-text-box-check-outline"
            v-if="(!isPendingRequest || isDraftStepInGeneratedContract || isDraftStepInEPMUpload || isDraftStepInApprovalInfo) && request.generatorUsage"
            :title="(isPendingRequest ? '2.' + (1 + !!needClientRequestForm) + ' ' : '') + 'Contract'"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell v-card-x-cell">
                  <div style="padding-bottom: 16px;">
                    <file-list
                      v-if="request.generatorUsage"
                      :files="dummyContractUnderReview"
                      :multiple-rows="windowWidth < 650"
                    >
                      <template v-slot:buttons>
                        <td style="width: 86px;">
                          <v-btn small text icon
                            class="mt-0 mb-0"
                            @click="downloadContractDoc"
                          >
                            <v-icon color="primary">
                              mdi-download
                            </v-icon>
                          </v-btn>
                          <v-btn small text icon
                            class="mt-0 mb-0"
                            @click="isGeneratorDisplayed = true"
                          >
                            <v-icon color="primary">
                              mdi-eye-settings
                            </v-icon>
                          </v-btn>
                        </td>
                      </template>
                    </file-list>
                  </div>
                  <div v-if="isDraftStepInGeneratedContract" class="error lighten-1 white--text" style="text-align:left;padding:21px 21px 18px 18px;display:flex;align-items:start;border-radius:6px;margin-bottom:16px;">
                    <v-icon color="white" style="margin-top: -3px;margin-right: 18px">
                      mdi-alert-circle-outline
                    </v-icon>
                    <div>
                      <div>Based on your input, we have generated a NielsenIQ standard contract. Please double check the contract carefully, <span style="font-weight:bold;text-decoration:underline;">especially the APPENDIX.</span></div>
                      <div>Now you can download and <span style="font-weight: bold;text-decoration: underline;">confirm the contract with your client</span>, or re-edit your contract.</div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 class="v-flex-row-breaker">
                </v-flex>
                <template v-if="!isPageLoading && canEditGeneratedContract">
                  <v-flex xs6 pt-1 pl-3 pb-3>
                    <div class="bottom-btn-container">
                      <v-btn color="primary"
                        @click="clickPrevDraftStep"
                      >
                        Re-edit
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-1 pr-3 pb-3>
                    <div class="bottom-btn-container-right">
                      <v-btn color="primary"
                        @click="clickNextDraftStep"
                      >
                        Next
                      </v-btn>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="legal-request-other-epm-upload"
            color="primary"
            icon="mdi-text-box-outline"
            v-if="(!isPendingRequest || isDraftStepInEPMUpload || isDraftStepInApprovalInfo) && needEPMUpload"
            :title="(isPendingRequest ? '2.' + (2 + !!needClientRequestForm) + ' Upload ' : '') + 'External Publicity Materials' + (isEPMEssential ? '' : ' (If applicable)')"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pt-3 pl-3 px-4 pb-4 class="v-card-x-cell">
                  <div>If your client has requested to publish the data in any external publicity material such as but not limited to press releases, commissioned reports / whitepapers, advertisements, please upload the document(s) here. Your client should have communicated their plans to publish the data externally during the proposal stage, and this should have been approved by the Marcomms team.</div>
                  <div>如果您的客户要求以任何外部宣传材料（例如但不限于新闻稿，委托的报告/白皮书，广告）发表数据，请在此处上传要求发表的相关文件。在此之前，您的客户应该已经在项目启动之前传达了他们计划对外发表数据的计划，并且该计划已经得到NielsenIQ市场部的批准。</div>
                </v-flex>
                <v-flex v-if="canEditEPMUpload" xs12 pt-1 pb-1 pl-3 pr-3 class="v-card-x-cell">
                  <div class="error lighten-1 white--text" style="text-align:left;padding:21px 21px 12px 18px;display:flex;align-items:start;border-radius:6px;">
                    <v-icon color="white" style="margin-top: -3px;margin-right: 18px">
                      mdi-alert-circle-outline
                    </v-icon>
                    <div>
                      <div>Before you upload the document(s), please confirm that the content in the document(s) does not include: </div>
                      <div>在您上传文件之前，请确认文件内容不包含以下内容：</div>
                      <div class="ma-3 d-flex flex-row justify-start">
                        <div class="mr-1" style="flex-grow:0!important">-</div>
                        <div>
                          <div>Any client’s competitor names or client’s competitor brand-level specifics from NielsenIQ-licensed materials</div>
                          <div>NielsenIQ 许可材料中的任何客户竞争对手名称或客户竞争对手品牌级别的详细信息</div>
                        </div>
                      </div>
                      <div class="ma-3 d-flex flex-row justify-start">
                        <div class="mr-1" style="flex-grow:0!important">-</div>
                        <div>
                          <div>Any ranking of the client’s brand / product performance</div>
                          <div>客户品牌/产品性能的任何排名</div>
                        </div>
                      </div>
                      <div class="ma-3 d-flex flex-row justify-start">
                        <div class="mr-1" style="flex-grow:0!important">-</div>
                        <div>
                          <div>Any subjective phrases such as “Number 1 brand”, “#1”, “top brand”, “preferred brand”, “best”, “leading”, “most successful” or “favorite”, which is prohibited to be used in advertisement by &lt;China advertisement law&gt;</div>
                          <div>任何主观用语或绝对化用语，如“第一品牌”、“顶级品牌”、“偏爱的品牌”、“最好”、“领导品牌”、“最成功的” 或者 “最喜欢的”，这些绝对化用语根据《广告法》禁止在广告中使用。</div>
                        </div>
                      </div>
                      <div class="ma-3 d-flex flex-row justify-start">
                        <div class="mr-1" style="flex-grow:0!important">-</div>
                        <div>
                          <div>Any social survey questions (i.e. questions related to national policy, national economic status, opinion of government party / policies etc.）</div>
                          <div>任何与社会调查相关的问题（如与国家政策、国民经济状况、政党/政策意见等有关的问题）</div>
                        </div>
                      </div>
                      <div class="ma-3 d-flex flex-row justify-start">
                        <div class="mr-1" style="flex-grow:0!important">-</div>
                        <div>
                          <div>Any endorsements of the client’s market performance/product effectiveness (the document(s) should only cover general market trends)</div>
                          <div>对客户市场表现、产品效能的任何背书（文件应仅涵盖市场总体趋势）</div>
                        </div>
                      </div>
                      <div class="ma-3 d-flex flex-row justify-start">
                        <div class="mr-1" style="flex-grow:0!important">-</div>
                        <div>
                          <div>[For commissioned reports / whitepapers] It does not state that the reports are owned by NielsenIQ (i.e. it should state that it is a commissioned report done by NielsenIQ) </div>
                          <div>[委托报告/白皮书] 未声明是NielsenIQ 所有的（报告须声明是委托NielsenIQ撰写的）</div>
                        </div>
                      </div>
                      <div class="ma-3 d-flex flex-row justify-start">
                        <div class="mr-1" style="flex-grow:0!important">-</div>
                        <div>
                          <div>Sensitive topics (e.g. race, religion, sexual orientation, human right, politics). Please refer to our <a class="template alert" target="_blank" href="https://drive.google.com/file/d/1KQzly-yd8mvyxF53gOWISFAWlYLBetBZ/view">sensitive survey research intake policy</a> for more details.</div>
                          <div>敏感问题（如种族、宗教、性取向、人权、政治）的报告。请参阅<a class="template alert" target="_blank" href="https://drive.google.com/file/d/1KQzly-yd8mvyxF53gOWISFAWlYLBetBZ/view">NielsenIQ 敏感调查研究政策</a>获取更多详细信息。</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 py-0 pl-3 pr-3 class="v-card-x-cell" v-if="canEditEPMUpload">
                  <div class="mt-1 mb-3 mr-4 pb-1 d-flex flex-row justify-end align-center">
                    <div style="flex-grow:0!important;margin-bottom:-16px;">
                      <v-checkbox
                        light
                        color="primary"
                        class="mt-0"
                        v-model="request.checkAlertEPM"
                      >
                        <template v-slot:label>
                          <div :class="{
                            'pl-1': true,
                            'error--text': (isEPMEssential || request.epm.length) && !request.checkAlertEPM,
                          }">
                            <div>I have read and confirm the above contents.</div>
                            <div>我已阅读且确认以上内容。</div>
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell" v-if="canEditEPMUpload">
                  <div style="width: 100%;display: flex;align-items: center;">
                    <x-upload
                      request-class="file"
                      request-function="upload"
                      accept=".doc,.docx,.pdf"
                      :params="{
                        email: userEmail,
                        loginStatus: loginStatus
                      }"
                      :before-upload="beforeUploadEPM"
                      :on-error="errorUploadEPM"
                      :on-success="successUploadEPM"
                    >
                      <v-btn text
                        style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                      >
                        <v-icon color="primary" size="24" style="margin-right:12px;">
                          mdi-upload
                        </v-icon>
                        <div v-if="request.epm.length > 0">Upload more <b>External Publicity Materials</b>{{ isEPMEssential ? '' : ' (If applicable)' }}</div>
                        <div v-else>Please upload <b>External Publicity Materials</b>{{ isEPMEssential ? '' : ' (If applicable)' }}</div>
                      </v-btn>
                    </x-upload>
                  </div>
                </v-flex>
                <v-flex xs12 pt-2 px-4 class="v-card-x-cell grey--text" v-else-if="!request.epm.length">
                  No External Publicity Materials
                </v-flex>
                <v-flex xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                  <file-list
                    :files="request.epm"
                    :deletable="canEditEPMUpload"
                    :multiple-rows="windowWidth < 650"
                    @click-download="downloadFile"
                    @click-delete="deleteEPM"
                  ></file-list>
                  <div
                    tabindex="0"
                    ref="legal_request_other_form_epm"
                    style="height: 1px;"
                  ></div>
                </v-flex>
                <template v-if="!isPageLoading && canEditEPMUpload">
                  <v-flex xs6 pt-3 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container">
                      <v-btn color="primary"
                        @click="clickPrevDraftStep"
                      >
                        Previous
                      </v-btn>
                    </div>
                  </v-flex>
                  <v-flex xs6 pt-3 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div class="bottom-btn-container-right">
                      <v-btn color="primary"
                        :disabled="isEPMEssential ? !(request.epm.length && request.checkAlertEPM) : (!!request.epm.length && !request.checkAlertEPM)"
                        @click="clickNextDraftStep"
                      >
                        Next
                      </v-btn>
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            id="legal-request-other-approval-info"
            color="primary"
            icon="mdi-stamper"
            :title="isPendingRequest ? '3.0 Send For Approval' : 'Approval Information'"
            v-if="!isPendingRequest || isDraftStepInApprovalInfo"
          >
            <template v-slot>
              <v-form ref="approval-info-form" :lazy-validation="true">
                <v-layout row wrap class="v-card-x-layout">
                  <v-flex xs12 sm6 pa-0
                    v-if="needApproval"
                  >
                    <v-flex xs12 pl-3 pr-3 pb-1 class="v-card-x-cell"
                      v-if="needLeaderApproval"
                    >
                      <div class="approver-selector-without-details">
                        <account-profile
                          :email="request.leaderApprover.email"
                          :icon="request.leaderApprover.icon"
                          :name="request.leaderApprover.name"
                          :hide-tooltip="isMobile || !request.leaderApprover.email"
                          :gap-of-tag-icon-and-name="12"
                          :tag-color="themeColor.default"
                          left
                        >
                          <template v-slot:tag>
                            <leader-select
                              v-model="request.leaderApprover"
                              label="Commercial Leader Approver"
                              :owner="request.requestor.email"
                              :leader-titles="leaderTitles"
                              :disabled="!canEditApprovalForm"
                              :rules="[v => !!v && !!v.email || 'Please select']"
                            ></leader-select>
                          </template>
                        </account-profile>
                      </div>
                      <div
                        tabindex="0"
                        ref="legal_request_other_form_leader_approver"
                        style="height: 1px;"
                      ></div>
                    </v-flex>

                    <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell"
                      v-if="needCILeaderApproval"
                    >
                      <div class="v-card-x-cell-title my-disabled--text">Commercial Leader Approver</div>
                      <div style="padding-top: 2px;max-width: 270px;">
                        <div class="pb-2">
                          <account-profile
                            :email="request.ciLeaderApprover.email"
                            :name="request.ciLeaderApprover.name"
                            :icon="request.ciLeaderApprover.icon"
                            :hide-tooltip="isMobile"
                            :gap-of-tag-icon-and-name="12"
                            tag-color="rgba(0,0,0,.68)"
                          >
                          </account-profile>
                        </div>
                      </div>
                    </v-flex>

                    <v-flex xs12 pt-3 pl-3 pr-3 pb-4 class="v-card-x-cell"
                      v-if="needProductDSApproval"
                    >
                      <div class="v-card-x-cell-title my-disabled--text">DS Approver</div>
                      <div style="padding-top: 2px;max-width: 270px;">
                        <div class="pb-2">
                          <account-profile
                            :email="request.productDSApprover.email"
                            :name="request.productDSApprover.name"
                            :icon="request.productDSApprover.icon"
                            :hide-tooltip="isMobile"
                            :gap-of-tag-icon-and-name="12"
                            tag-color="rgba(0,0,0,.68)"
                          >
                          </account-profile>
                        </div>
                      </div>
                    </v-flex>
                    
                    <v-flex xs12 py-0 pl-3 pr-3 class="v-card-x-cell">
                      <div v-if="needLegalApproval" class="approver-selector-with-details pb-1">
                        <account-profile
                          :email="request.legalApprover.email"
                          :icon="request.legalApprover.icon"
                          :name="request.legalApprover.name"
                          :hide-tooltip="isMobile || !request.legalApprover.email"
                          :gap-of-tag-icon-and-name="12"
                          :tag-color="themeColor.default"
                          left
                        >
                          <template v-slot:tag>
                            <user-select-sync
                              v-model="request.legalApprover"
                              label="Legal Approver"
                              :items="legalApproversForSelect"
                              :disabled="!canEditApprovalForm"
                              :rules="[v => !!v && !!v.email || 'Please select']"
                            ></user-select-sync>
                          </template>
                        </account-profile>
                        
                        <v-tooltip bottom :disabled="isMobile || !legalApproversActiveInGroup.length">
                          <template v-slot:activator="{ on }">
                            <v-icon size="28" class="mt-3 ml-2 mr-1 on-cursor-pointer" v-on="on">
                              mdi-help-circle
                            </v-icon>
                          </template>
                          <div class="legal-request-other-details-approver-tooltip pb-2 pr-1">
                            <div class="mb-1">Legal Approver:</div>
                            <template v-for="(la, li) in legalApproversActiveInGroup">
                              <div v-if="la && la.group && la.name" :key="'legal_approver_' + li" class="ml-1"
                              >- {{ la.group }}: {{ la.name }}</div>
                            </template>
                          </div>
                        </v-tooltip>
                      </div>
                      <div v-else-if="needLegalExApproval" class="approver-selector-without-details">
                        <account-profile
                          :email="request.legalExApprover.email"
                          :icon="request.legalExApprover.icon"
                          :name="request.legalExApprover.name"
                          :hide-tooltip="isMobile || !request.legalExApprover.email"
                          :gap-of-tag-icon-and-name="12"
                          :tag-color="themeColor.default"
                          left
                        >
                          <template v-slot:tag>
                            <user-select-sync
                              v-model="request.legalExApprover"
                              label="Legal Approver"
                              :items="[request.legalExApprover]"
                              :disabled="true"
                              :rules="[v => !!v && !!v.email || 'Please select']"
                            ></user-select-sync>
                          </template>
                        </account-profile>
                      </div>
                      <div class="mb-4" v-if="false">
                        <span class="v-card-x-cell-title v-card-x-cell-title-absolute my-disabled--text">Legal Approver</span>
                        <div class="legal-request-other-approver-no-need">
                          <div>No Need</div>
                        </div>
                      </div>
                      <div
                        tabindex="0"
                        ref="legal_request_other_form_legal_approver"
                        style="height: 1px;"
                      ></div>
                    </v-flex>

                    <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell"
                      v-if="needMarcomApproval"
                    >
                      <div class="v-card-x-cell-title my-disabled--text">Marcom Approver</div>
                      <div style="padding-top: 2px;max-width: 270px;">
                        <div class="pb-2">
                          <account-profile
                            :email="request.marcomApprover.email"
                            :name="request.marcomApprover.name"
                            :icon="request.marcomApprover.icon"
                            :hide-tooltip="isMobile"
                            :gap-of-tag-icon-and-name="12"
                            tag-color="rgba(0,0,0,.68)"
                          >
                          </account-profile>
                        </div>
                      </div>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 sm6 pa-0>
                    <v-flex xs12 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="v-card-x-cell-title my-disabled--text">Requester</div>
                      <div style="padding-top: 2px;max-width: 270px;">
                        <account-profile
                          :email="request.requestor.email"
                          :name="request.requestor.name"
                          :icon="request.requestor.icon"
                          :hide-tooltip="isMobile"
                          :gap-of-tag-icon-and-name="12"
                          tag-color="rgba(0,0,0,.68)"
                          tag-font-size="16"
                        >
                        </account-profile>
                      </div>
                    </v-flex>
                    <v-flex xs12 mt-6 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="v-card-x-cell-title my-disabled--text">Cost Center</div>
                      <div
                        class="my-disabled--text"
                        style="padding-top: 6px;"
                      >
                        {{ request.costCenter }}
                      </div>
                    </v-flex>
                    <v-flex xs12 mt-6 pl-3 pr-3 pb-1 class="v-card-x-cell">
                      <div class="v-card-x-cell-title my-disabled--text">Team</div>
                      <div
                        class="my-disabled--text"
                        style="margin-top: 6px;"
                      >
                        {{ request.team }}
                      </div>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                  <v-flex xs12 mt-4 pt-3 pl-3 pr-3 pb-3 class="v-card-x-cell">
                    <div :class="['v-card-x-cell-title', ...(isApprovalInfoChecked && !request.backgroundBriefing ? ['red--text'] : []), ...(canEditApprovalForm ? [] : ['my-disabled--text'])]">Background Briefing</div>
                    <div class="title-solo-textarea">
                      <v-textarea
                        solo
                        no-resize
                        rows="3"
                        placeholder="Please specify"
                        style="margin-top: 0px"
                        :counter="maxLenBackgroundBriefing"
                        v-model="request.backgroundBriefing"
                        :readonly="!canEditApprovalForm"
                        :rules="[v => !!v && v !== '' || 'Please input']"
                        ref="legal_request_other_form_background_briefing"
                      >
                      </v-textarea>
                    </div>
                  </v-flex>
                  <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                  <template v-if="!isPageLoading && canEditApprovalForm">
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div class="bottom-btn-container">
                        <v-btn color="primary"
                          @click="clickPrevDraftStep"
                        >
                          Previous
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs6 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell">
                      <div
                        class="bottom-btn-container-right"
                      >
                        <v-btn color="primary"
                          @click="clickSaveRequest"
                        >
                          Save
                        </v-btn>
                        <v-btn color="primary"
                          @click="clickSubmitRequest"
                        >
                          Submit
                        </v-btn>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </v-form>
            </template>
          </material-app-card>
        </v-flex>

        <v-flex
          xs12
          lg4
          order-lg2
          pt-0
          pb-0
        >
          <material-app-card
            v-if="!isPageLoading"
            color="primary"
            icon="mdi-radar"
            :title="isNewRequest ? 'Other Legal Requests' : request.requestId"
          >
            <template v-slot:extra>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-if="canRecall"
                    @click="clickRecall"
                    v-on="on"
                  >
                    <v-icon>mdi-restart</v-icon>
                  </v-btn>
                </template>
                <span>Recall</span>
              </v-tooltip>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-show="canAbandon"
                    @click="isRequestAbandoning = true"
                    v-on="on"
                  >
                    <v-icon size="22">mdi-cancel</v-icon>
                  </v-btn>
                </template>
                <span>Abandon</span>
              </v-tooltip>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-show="!isNewRequest"
                    @click="showShareTo"
                    v-on="on"
                  >
                    <v-icon v-if="canShareAccess">mdi-account-plus</v-icon>
                    <v-icon v-else>mdi-account</v-icon>
                  </v-btn>
                </template>
                <span v-if="canShareAccess">Share Access</span>
                <span v-else>Access</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <div class="legal-request-other-stepper">
                <v-stepper
                  :value="stageIndexOfDisplayed"
                  vertical
                >
                  <template v-for="(stage, si) in stagesDisplayed">
                    <v-stepper-step
                      :key="'step_' + si"
                      color="primary"
                      :step="si"
                      :complete="stageIndexOfDisplayed >= si"
                      :complete-icon="stageIndexOfDisplayed > si ? '$vuetify.icons.complete' : 'mdi-star'"
                    >
                      {{ stage.content }}
                    </v-stepper-step>
                    <v-stepper-content :key="'step_content_' + si" :step="si">
                    </v-stepper-content>
                  </template>
                </v-stepper>
              </div>
            </template>
          </material-app-card>

          <material-app-card
            color="primary"
            icon="mdi-message-draw"
            title="Review Panel"
            v-if="!isPendingRequest"
          >
            <template v-slot:extra>
              <v-tooltip left :disabled="isMobile">
                <template v-slot:activator="{ on }">
                  <v-btn small text icon color="primary"
                    v-if="canComment"
                    @click="clickDraftComments()"
                    v-on="on"
                  >
                    <v-icon size="20">mdi-forum-outline</v-icon>
                  </v-btn>
                </template>
                <span>Comment</span>
              </v-tooltip>
            </template>
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex v-if="canDownloadSignFile || canViewSignInfo" xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <div ref="contract_sign" style="width:100%"></div>
                  <file-list
                    :files="isSigningRequest ? dummyContractSigning : dummyContractSigned"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                  >
                    <template v-slot:buttons="props">
                      <td style="width: 86px; text-align: right;">
                        <v-btn small text icon
                          class="mt-0 mb-0"
                          v-if="canDownloadSignFile"
                          @click="downloadSignFile(props.index)"
                        >
                          <v-icon color="primary">
                            mdi-download
                          </v-icon>
                        </v-btn>
                        <v-btn small text icon
                          v-if="canViewSignInfo"
                          @click="clickViewSign"
                        >
                          <v-icon color="primary">
                            mdi-eye-settings
                          </v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </file-list>
                </v-flex>
                <v-flex v-else-if="isApprovalRequest || isApprovedRequest" xs12 pt-1 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <div ref="contract_under_review" style="width:100%"></div>
                  <file-list
                    v-if="request.generatorUsage"
                    :files="dummyContractUnderReview"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                  >
                    <template v-slot:buttons>
                      <td style="width: 86px;">
                        <v-btn small text icon
                          class="mt-0 mb-0"
                          @click="downloadContractDoc"
                        >
                          <v-icon color="primary">
                            mdi-download
                          </v-icon>
                        </v-btn>
                        <v-btn small text icon
                          @click="isGeneratorDisplayed = true"
                        >
                          <v-icon color="primary">
                            mdi-eye-settings
                          </v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </file-list>
                  <file-list
                    v-else
                    :files="request.draftedContract"
                    :deletable="false"
                    :multiple-rows="$vuetify.breakpoint.lgAndUp || !$vuetify.breakpoint.smAndUp"
                    @click-download="downloadFile"
                  ></file-list>
                </v-flex>
                <v-flex xs12 class="v-flex-row-breaker">
                </v-flex>
                <template v-if="!isPageLoading">
                  <v-flex xs3 pt-1 pl-3 pr-3 pb-3>
                    <div class="bottom-btn-container">
                    </div>
                  </v-flex>
                  <v-flex xs9 pt-1 pl-3 pr-3 pb-3>
                    <div class="bottom-btn-container-right">
                      <v-btn v-if="!isMobile && canReviseContract && !request.generatorUsage" color="primary"
                        @click="clickUploadNewDraftedContract"
                      >
                        Revise Contract
                      </v-btn>
                      <template v-if="canApprove">
                        <v-btn v-if="request.generatorUsage" color="primary"
                          @click="isGeneratorDisplayed = true"
                        >
                          Action
                        </v-btn>
                        <v-btn v-else color="primary"
                          @click="isRequestApproving = true"
                        >
                          Approve
                        </v-btn>
                      </template>
                      <v-btn v-if="canSign" color="primary"
                        @click="clickStartSign"
                      >
                        Sign
                      </v-btn>
                      <v-btn v-if="canViewSignInfo" color="primary"
                        @click="clickViewSign"
                      >
                        Next
                      </v-btn>
                      <v-btn v-if="canArchive" color="primary"
                        @click="clickArchive"
                      >
                        Archive
                      </v-btn>                      
                    </div>
                  </v-flex>
                </template>
              </v-layout>
            </template>
          </material-app-card>

          <material-app-card
            color="primary"
            icon="mdi-history"
            title="Activity History"
            v-if="historyDisplayed.length > 0 && $vuetify.breakpoint.lgAndUp"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <activity-history
                    :history="historyDisplayed"
                  >
                  </activity-history>
                </v-flex>
              </v-layout>
            </template>
          </material-app-card>
        </v-flex>

        <v-flex
          v-if="!$vuetify.breakpoint.lgAndUp"
          order-xs3
          xs12
          pt-0
          pb-0
        >
          <material-app-card
            color="primary"
            icon="mdi-history"
            title="Activity History"
            v-if="historyDisplayed.length > 0"
          >
            <template v-slot>
              <v-layout row wrap class="v-card-x-layout">
                <v-flex xs12 pl-3 pr-3 pb-3 class="v-card-x-cell v-card-x-cell">
                  <activity-history
                    :history="historyDisplayed"
                  >
                  </activity-history>
                </v-flex>
              </v-layout>
            </template>
          </material-app-card>
        </v-flex>

        <v-dialog
          v-model="isGeneratorDisplayed"
          persistent
          fullscreen
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <v-card class="legal-request-other-generate-contract-card">
            <v-list class="sidebar-top">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile && !isFreshContractPreviewing && !canEditRequestType" text icon @click="isGeneratorDisplayed = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    <span 
                      v-if="isDraftStepInRequestType || isFreshContractPreviewing"
                    >Generate Contract</span>
                    <span v-else>Contract Information</span>
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <div style="display: flex">
                    <v-tooltip bottom :disabled="isMobile">
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="primary" class="ml-3"
                          v-if="canComment"
                          @click="clickDraftComments()"
                          v-on="on"
                        >
                          <v-icon>mdi-forum-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Comment</span>
                    </v-tooltip>
                    <v-btn v-if="!isMobile && !isFreshContractPreviewing && !isDraftStepInRequestType" text icon @click="isGeneratorDisplayed = false" class="ml-2">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div
              :style="{
                'position': 'absolute',
                'top': '64px',
                'left': '0',
                'right': '0',
                'bottom': isMobile ? '0' : '65px',
                'overflow': 'hidden',
                'border-radius': isMobile ? '0' : '3px'
              }"
            >
              <div style="position: relative;width: 100%;height: 100%; background-color: rgba(209, 209, 209);">
                <v-layout wrap pl-2
                  :style="{
                    'height': '100%',
                    'overflow': $vuetify.breakpoint.lgAndUp ? 'hidden' : 'auto',
                    'border-top': '1px solid lightgrey',
                    'border-bottom': '1px solid lightgrey'
                  }"
                >
                  <v-flex
                    xs12
                    lg6
                    px-2
                    pt-0
                    pb-4
                    :style="{
                      'height': $vuetify.breakpoint.lgAndUp ? '100%' : 'auto',
                      'overflow': $vuetify.breakpoint.lgAndUp ? 'auto' : 'hidden'
                    }"
                  >
                    <material-app-card
                      id="legal-request-other-generator-form-card"
                      color="primary"
                      icon="mdi-file-document-edit-outline"
                      :title="(isPendingRequest ? '2.' + (0 + !!needClientRequestForm) + ' ' : '') + (isDraftStepInRequestType || isFreshContractPreviewing ? 'Generate Contract' : 'Contract Information')"
                      :style="{
                        'margin-top': '32px'
                      }"
                    >
                      <template v-slot>
                        <v-form ref="legal-request-other-generator-form" :lazy-validation="true">
                          <v-layout pb-3 row wrap class="v-card-x-layout">
                            <v-flex xs12
                              v-if="isPageLoading"
                              :style="{
                                margin: '12px',
                                display: 'flex',
                                'align-items': 'center',
                                'justify-content': 'center'
                              }"
                            >
                              <v-progress-circular
                                :width="4"
                                color="grey"
                                indeterminate
                                :size="24"
                              ></v-progress-circular>
                              <div
                                class="grey--text text--lighten-1"
                                :style="{
                                  'margin-left': '9px'
                                }"
                              >
                                Loading
                              </div>
                            </v-flex>
                            <template v-else>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needLanguage"
                              >
                                <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && !request.language ? ['red--text'] : []), ...(canEditRequestType ? [] : ['my-disabled--text'])]">Language</div>
                                <div>
                                  <v-radio-group row class="legal-request-other-radio-group-horizonal"
                                    :disabled="!canEditRequestForm"
                                    v-model="request.language"
                                    :rules="[v => (!!v && v != '') || 'Please select']"
                                  >
                                    <v-radio label="English" value="English" color="primary"></v-radio>
                                    <v-radio label="中文" value="Chinese" color="primary"></v-radio>
                                  </v-radio-group>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="legal_request_other_generator_form_language"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needNielsenEntityName"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm"
                                  label="NielsenIQ Entity Name"
                                  :items="salesOrgsForSelect"
                                  :item-text="needLanguage ? request.language === 'Chinese' ? 'textCn' : 'textEn' : 'text'"
                                  :placeholder="salesOrgPlaceholder"
                                  v-model="request.nielsenEntityName"
                                  ref="legal_request_other_generator_form_nielsen_entity_name"
                                  :rules="[
                                    v => !!salesOrgsForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!salesOrgsForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                  @change="changeNielsenEntityName"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell">
                                <div
                                  v-if="needClientEntityName"
                                >
                                  <v-combobox
                                    :disabled="!canEditRequestForm"
                                    label="Client Entity Name"
                                    ref="legal_request_other_generator_form_client_entity_name"
                                    no-filter
                                    :search-input.sync="clientEntityNameKey"
                                    v-model="request.clientEntityName"
                                    :items="clientEntityNameItems"
                                    :loading="clientEntityNameLoading"
                                    :rules="[v => !!v || 'Please input and select']"
                                    @change="changeClientEntityName"
                                    @blur="() => request.clientEntityName = clientEntityNameKey"
                                  ></v-combobox>
                                </div>
                                <div
                                  v-if="needRetailerEntityName"
                                >
                                  <v-combobox
                                    :disabled="!canEditRequestForm"
                                    label="Retailer Entity Name"
                                    ref="legal_request_other_generator_form_retailer_entity_name"
                                    no-filter
                                    :search-input.sync="retailerEntityNameKey"
                                    v-model="request.retailerEntityName"
                                    :items="retailerEntityNameItems"
                                    :loading="retailerEntityNameLoading"
                                    :rules="[v => !!v || 'Please input and select']"
                                    @change="changeRetailerEntityName"
                                    @blur="() => request.retailerEntityName = retailerEntityNameKey"
                                  ></v-combobox>
                                </div>
                                <div
                                  v-if="needCooperatorEntityname"
                                >
                                  <v-combobox
                                    :disabled="!canEditRequestForm"
                                    label="Cooperator Entity Name"
                                    ref="legal_request_other_generator_form_cooperator_entity_name"
                                    no-filter
                                    :search-input.sync="cooperatorEntityNameKey"
                                    v-model="request.cooperatorEntityName"
                                    :items="cooperatorEntityNameItems"
                                    :loading="cooperatorEntityNameLoading"
                                    :rules="[v => !!v || 'Please input and select']"
                                    @change="changeCooperatorEntityName"
                                    @blur="() => request.cooperatorEntityName = cooperatorEntityNameKey"
                                  ></v-combobox>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needNielsenResponsibleContactPerson"
                              >
                                <div class="nielsen-responsible-contact-person">
                                  <account-autocomplete
                                    v-model="request.nielsenResponsibleContactPerson"
                                    label="NielsenIQ Responsible Contact Person"
                                    :disabled="!canEditRequestForm"
                                    :hide-details="false"
                                    @change="changeNielsenResponsibleContactPerson"
                                    :rules="[v => !!v.email || 'Please input and select']"
                                  ></account-autocomplete>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="legal_other_request_generator_form_nielsen_responsible_contact_person"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needRetailerResponsibleContactPerson"
                              >
                                <div class="retailer-responsible-contact-person">
                                  <v-text-field
                                    v-model="request.retailerResponsibleContactPerson"
                                    label="Retailer Responsible Contact Person"
                                    ref="legal_other_request_generator_form_retailer_responsible_contact_person"
                                    :disabled="!canEditRequestForm"
                                    :rules="[v => !!v || 'Please input and select']"
                                    @input="changeRetailerResponsibleContactPerson"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needNielsenEntityAddress"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.nielsenEntityAddress ? ['red--text'] : [])]">NielsenIQ Entity Address</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenNielsenEntityAddress"
                                    v-model="request.nielsenEntityAddress"
                                    :readonly="!canEditRequestForm"
                                    ref="legal_other_request_generator_form_nielsen_entity_address"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changeNielsenEntityAddress"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needRetailerEntityAddress"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.retailerEntityAddress ? ['red--text'] : [])]">Retailer Entity Address</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenRetailerEntityAddress"
                                    v-model="request.retailerEntityAddress"
                                    :readonly="!canEditRequestForm"
                                    ref="legal_other_request_generator_form_retailer_entity_address"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changeRetailerEntityAddress"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needContractEffectiveDate"
                              >
                                <div>
                                  <v-dialog
                                    ref="legal_other_request_generator_form_contract_effective_date"
                                    v-model="isPickerContractEffectiveDateDisplayedGen"
                                    :return-value.sync="request.contractEffectiveDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="request.contractEffectiveDate"
                                        label="Contract Effective Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditRequestForm"
                                        ref="legal_other_request_generator_form_contract_effective_date_text_field"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="request.contractEffectiveDate" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedContractEffectiveDates"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="isPickerContractEffectiveDateDisplayedGen = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="clickOkContractEffectiveDateGen">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needContractEndDate"
                              >
                                <div>
                                  <v-dialog
                                    ref="legal_other_request_generator_form_contract_end_date"
                                    v-model="isPickerContractEndDateDisplayedGen"
                                    :return-value.sync="request.contractEndDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="request.contractEndDate"
                                        label="Contract End Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditRequestForm"
                                        ref="legal_other_request_generator_form_contract_end_date_text_field"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="request.contractEndDate" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedContractEndDates"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="isPickerContractEndDateDisplayedGen = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="clickOkContractEndDateGen">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needContractSigningDate"
                              >
                                <div>
                                  <v-dialog
                                    ref="legal_other_request_generator_form_contract_signing_date"
                                    v-model="isPickerContractSigningDateDisplayedGen"
                                    :return-value.sync="request.contractSigningDate"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        v-model="request.contractSigningDate"
                                        label="Contract Signing Date"
                                        append-icon="mdi-calendar"
                                        readonly
                                        v-on="on"
                                        :disabled="!canEditRequestForm"
                                        ref="legal_other_request_generator_form_contract_signing_date_text_field"
                                        :rules="[v => (!!v && v != '') || 'Please select']"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="request.contractSigningDate" scrollable color="primary" header-color="primary"
                                      :allowed-dates="allowedContractSigningDates"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" class="mb-2" @click="isPickerContractSigningDateDisplayedGen = false">CANCEL</v-btn>
                                      <v-btn dark color="primary" class="mb-2" @click="clickOkContractSigningDateGen">OK</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needProjectDescriptions"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.projectDescriptions ? ['red--text'] : [])]">Project Descriptions</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenProjectDescriptions"
                                    v-model="request.projectDescriptions"
                                    :readonly="!canEditRequestForm"
                                    ref="legal_other_request_generator_form_project_descriptions"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changeProjectDescriptions"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needNielsenAppointedEntityNamePayee"
                              >
                                <div>
                                  <v-combobox
                                    :disabled="!canEditRequestForm"
                                    label="NielsenIQ Appointed Entity Name (Payee)"
                                    ref="legal_request_other_form_nielsen_appointed_entity_name_payee"
                                    no-filter
                                    :search-input.sync="nielsenAppointedEntityNamePayeeKey"
                                    v-model="request.nielsenAppointedEntityNamePayee"
                                    :items="nielsenAppointedEntityNamePayeeItems"
                                    :rules="[v => !!v || 'Please input and select']"
                                    @change="changeNielsenAppointedEntityNamePayee"
                                    @blur="() => request.nielsenAppointedEntityNamePayee = nielsenAppointedEntityNamePayeeKey"
                                  ></v-combobox>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needNielsenAssigningEntityNamePayer"
                              >
                                <div>
                                  <v-combobox
                                    :disabled="!canEditRequestForm"
                                    label="NielsenIQ Assigning Entity Name (Payer)"
                                    ref="legal_request_other_form_nielsen_assigning_entity_name_payer"
                                    no-filter
                                    :search-input.sync="nielsenAssigningEntityNamePayerKey"
                                    v-model="request.nielsenAssigningEntityNamePayer"
                                    :items="nielsenAssigningEntityNamePayerItems"
                                    :rules="[v => !!v || 'Please input and select']"
                                    @change="changeNielsenAssigningEntityNamePayer"
                                    @blur="() => request.nielsenAssigningEntityNamePayer = nielsenAssigningEntityNamePayerKey"
                                  ></v-combobox>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPayeeEntityAddress"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.payeeEntityAddress ? ['red--text'] : [])]">Payee Entity Address</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenPayeeEntityAddress"
                                    v-model="request.payeeEntityAddress"
                                    :readonly="!canEditRequestForm"
                                    ref="legal_other_request_generator_form_payee_entity_address"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changePayeeEntityAddress"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPayerEntityAddress"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.payerEntityAddress ? ['red--text'] : [])]">Payer Entity Address</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenPayerEntityAddress"
                                    v-model="request.payerEntityAddress"
                                    :readonly="!canEditRequestForm"
                                    ref="legal_other_request_generator_form_payer_entity_address"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changePayerEntityAddress"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPayeeEntityTelephoneNumber"
                              >
                                <div class="payee-entity-telephone-number">
                                  <v-text-field
                                    v-model="request.payeeEntityTelephoneNumber"
                                    :disabled="!canEditRequestForm"
                                    label="Payee Entity Telephone Number"
                                    ref="legal_other_request_generator_form_payee_entity_telephone_number"
                                    :rules="[v => !!v || 'Please input']"
                                    @input="changePayeeEntityTelephoneNumber"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPayerEntityTelephoneNumber"
                              >
                                <div class="payer-entity-telephone-number">
                                  <v-text-field
                                    v-model="request.payerEntityTelephoneNumber"
                                    :disabled="!canEditRequestForm"
                                    label="Payer Entity Telephone Number"
                                    ref="legal_other_request_generator_form_payer_entity_telephone_number"
                                    :rules="[v => !!v || 'Please input']"
                                    @input="changePayerEntityTelephoneNumber"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPayeeEntityFaxNumber"
                              >
                                <div class="payee-entity-fax-number">
                                  <v-text-field
                                    v-model="request.payeeEntityFaxNumber"
                                    :disabled="!canEditRequestForm"
                                    label="Payee Entity Fax Number"
                                    ref="legal_other_request_generator_form_payee_entity_fax_number"
                                    :rules="[v => !!v || 'Please input']"
                                    @input="changePayeeEntityFaxNumber"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPayerEntityFaxNumber"
                              >
                                <div class="payer-entity-fax-number">
                                  <v-text-field
                                    v-model="request.payerEntityFaxNumber"
                                    :disabled="!canEditRequestForm"
                                    label="Payer Entity Fax Number"
                                    ref="legal_other_request_generator_form_payer_entity_fax_number"
                                    @input="changePayerEntityFaxNumber"
                                    :rules="[v => !!v || 'Please input']"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needProjectNumber"
                              >
                                <div class="project-number">
                                  <v-text-field
                                    v-model="request.projectNumber"
                                    :disabled="!canEditRequestForm"
                                    label="Project Number"
                                    ref="legal_other_request_generator_form_project_number"
                                    @input="changeProjectNumber"
                                    :rules="[v => !!v || 'Please input']"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needFieldWorkCountry"
                              >
                                <div class="field-work-country">
                                  <v-text-field
                                    v-model="request.fieldWorkCountry"
                                    :disabled="!canEditRequestForm"
                                    label="Field Work Country"
                                    ref="legal_other_request_generator_form_field_work_country"
                                    @input="changeFieldWorkCountry"
                                    :rules="[v => !!v || 'Please input']"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needStaffSendToAbroad"
                              >
                                <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && (request.staffSendToAbroad === null) ? ['red--text'] : []), ...(canEditRequestType ? [] : ['my-disabled--text'])]">Staff send to abroad</div>
                                <div>
                                  <v-radio-group row class="legal-request-other-radio-group-horizonal"
                                    :disabled="!canEditRequestForm"
                                    v-model="request.staffSendToAbroad"
                                    :rules="[v => (v !== null && v !== '') || 'Please select']"
                                    @change="changeStaffSendToAbroad"
                                  >
                                    <v-radio :value="true" color="primary">
                                      <template v-slot:label>
                                        <span>YES</span>
                                      </template>
                                    </v-radio>
                                    <v-radio :value="false" color="primary">
                                      <template v-slot:label>
                                        <span>NO</span>
                                      </template>
                                    </v-radio>
                                  </v-radio-group>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="legal_other_request_generator_form_staff_send_to_abroad"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needProjectDuration"
                              >
                                <div class="field-work-country">
                                  <v-text-field
                                    v-model="request.projectDuration"
                                    :disabled="!canEditRequestForm"
                                    label="Project Duration"
                                    ref="legal_other_request_generator_form_project_duration"
                                    @input="changeProjectDuration"
                                    :rules="[v => !!v || 'Please input']"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needLaborEntryFromOverseas"
                              >
                                <div :class="['v-card-x-cell-title v-card-x-cell-title-absolute', ...(isRequestInfoChecked && (request.laborEntryFromOverseas === null) ? ['red--text'] : []), ...(canEditRequestType ? [] : ['my-disabled--text'])]">
                                  <div>Labor entry from overseas</div>
                                  <div>(incl. Hong Kong, Macao and Taiwan)</div>
                                </div>
                                <div style="margin-top: 36px">
                                  <v-radio-group row class="legal-request-other-radio-group-horizonal"
                                    :disabled="!canEditRequestForm"
                                    v-model="request.laborEntryFromOverseas"
                                    :rules="[v => (v !== null && v !== '') || 'Please select']"
                                    @change="changeLaborEntryFromOverseas"
                                  >
                                    <v-radio :value="true" color="primary">
                                      <template v-slot:label>
                                        <span>YES</span>
                                      </template>
                                    </v-radio>
                                    <v-radio :value="false" color="primary">
                                      <template v-slot:label>
                                        <span>NO</span>
                                      </template>
                                    </v-radio>
                                  </v-radio-group>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="legal_other_request_generator_form_labor-entryfrom-overseas"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needServiceType"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm"
                                  label="Service Type"
                                  :items="serviceTypesForSelect"
                                  item-text="value"
                                  v-model="request.serviceType"
                                  ref="legal_request_other_form_service_type"
                                  :rules="[
                                    v => !!serviceTypesForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!serviceTypesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needCurrency"
                              >
                                <v-select
                                  :disabled="!canEditRequestForm"
                                  label="Currency"
                                  :items="currenciesForSelect"
                                  item-text="text"
                                  v-model="request.currency"
                                  ref="legal_request_other_form_currency"
                                  :rules="[
                                    v => !!currenciesForSelect.find(el => el.value === v) || 'Please select',
                                    v => !!currenciesForSelect.find(el => el.value === v && !el.disabled) || 'Selection invalid. Please select another one'
                                  ]"
                                  @change="changeCurrency"
                                >
                                </v-select>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needServiceFeeAmount"
                              >
                                <div class="v-card-contract-amount">
                                  <v-text-field
                                    append-icon="mdi-pencil"
                                    :label="'Service Fee Amount' + (!!serviceFeeAmountToThousands ? ': ' + serviceFeeAmountToThousands : '')"
                                    type="number"
                                    :disabled="!canEditRequestForm"
                                    v-model="request.serviceFeeAmount"
                                    @blur="blurServiceFeeAmount"
                                    ref="legal_request_other_form_service_fee_amount"
                                    :rules="[
                                      v => !isNaN(parseFloat(v)) || 'Please input number',
                                      v => parseFloat(v) > 0 || 'Amount should be greater than 0'
                                    ]"
                                    @input="changeSrvFeeAmount"
                                  >
                                  </v-text-field>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needProjectNameAndDescriptions"
                              >
                                <div :class="['v-card-x-cell-title', ...(isRequestInfoChecked && !request.projectNameAndDescriptions ? ['red--text'] : [])]">Project Name and Descriptions</div>
                                <div class="title-solo-textarea">
                                  <v-textarea
                                    solo
                                    no-resize
                                    rows="3"
                                    placeholder="Please input"
                                    style="margin-top: 0px"
                                    :counter="maxLenProjectNameAndDescriptions"
                                    v-model="request.projectNameAndDescriptions"
                                    :readonly="!canEditRequestForm"
                                    ref="legal_other_request_generator_form_project_name_and_descriptions"
                                    :rules="[v => (!!v && v.toString().trim() != '') || 'Please input']"
                                    @input="changeProjectNameAndDescriptions"
                                  >
                                  </v-textarea>
                                </div>
                              </v-flex>
                              <v-flex xs12 sm6 pt-1 pl-3 pr-3 pb-1 class="v-card-x-cell"
                                v-if="needPayerEntityContactPerson"
                              >
                                <div class="payer-entity-contact-person">
                                  <account-autocomplete
                                    v-model="request.payerEntityContactPerson"
                                    label="Payer Entity Contact Person"
                                    :disabled="!canEditRequestForm"
                                    :hide-details="false"
                                    @change="changePayerEntityContactPerson"
                                    :rules="[v => !!v.email || 'Please input and select']"
                                  ></account-autocomplete>
                                </div>
                                <div
                                  tabindex="0"
                                  ref="legal_other_request_generator_form_payer_entity_contact_person"
                                  style="height: 1px;"
                                ></div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                                v-if="needOtherRequiredDataElements"
                              >
                                <div
                                  v-if="!canEditRequestForm"
                                  class="v-card-x-cell-title pb-1"
                                >
                                  Other Required Data Elements
                                </div>
                                <div v-else style="width: 100%;display: flex;align-items: center;">
                                  <x-upload
                                    request-class="file"
                                    request-function="upload"
                                    accept=".docx"
                                    :params="{
                                      email: userEmail,
                                      loginStatus: loginStatus
                                    }"
                                    :before-upload="beforeUploadOtherRequiredDataElements"
                                    :on-error="errorUploadOtherRequiredDataElements"
                                    :on-success="successUploadOtherRequiredDataElements"
                                  >
                                    <v-btn text
                                      v-if="request.otherRequiredDataElements.length === 0"
                                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    >
                                      <v-icon color="primary" size="24" style="margin-right:12px;">
                                        mdi-upload
                                      </v-icon>
                                      <div>Upload <b>Other Required Data Elements</b> (Word .DOCX Only)</div>
                                    </v-btn>
                                  </x-upload>

                                  <v-btn text
                                    v-if="request.otherRequiredDataElements.length > 0"
                                    style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    @click="isOtherRequiredDataElementsUploadConfirming = true"
                                  >
                                    <v-icon color="primary" size="24" style="margin-right:12px;">
                                      mdi-upload
                                    </v-icon>
                                    <div>Change <b>Other Required Data Elements</b> (Word .DOCX Only)</div>
                                  </v-btn>
                                </div>

                                <v-expand-transition>
                                  <div 
                                    v-if="isRequestInfoChecked && request.otherRequiredDataElements.length === 0" 
                                    class="red--text"
                                    style="font-size:12px;"
                                  >
                                    Please upload Other Required Data Elements
                                  </div>
                                </v-expand-transition>
                                
                                <div style="padding-bottom: 16px;">
                                  <file-list
                                    :files="request.otherRequiredDataElements"
                                    :deletable="canEditRequestForm"
                                    :multiple-rows="windowWidth < 650"
                                    @click-download="downloadFile"
                                    @click-delete="deleteOtherRequiredDataElements"
                                  ></file-list>
                                  <div
                                    tabindex="0"
                                    ref="legal_request_other_other_required_data_elements"
                                    style="height: 1px;"
                                  ></div>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                              <v-flex xs12 pt-3 pl-3 pr-3 pb-0 class="v-card-x-cell"
                                v-if="needNielsenServiceSuppliedToCooperator"
                              >
                                <div
                                  v-if="!canEditRequestForm"
                                  class="v-card-x-cell-title pb-1"
                                >
                                  NielsenIQ Service Supplied to Cooperator
                                </div>
                                <div v-else style="width: 100%;display: flex;align-items: center;">
                                  <x-upload
                                    request-class="file"
                                    request-function="upload"
                                    accept=".docx"
                                    :params="{
                                      email: userEmail,
                                      loginStatus: loginStatus
                                    }"
                                    :before-upload="beforeUploadNielsenServiceSuppliedToCooperator"
                                    :on-error="errorUploadNielsenServiceSuppliedToCooperator"
                                    :on-success="successUploadNielsenServiceSuppliedToCooperator"
                                  >
                                    <v-btn text
                                      v-if="request.nielsenServiceSuppliedToCooperator.length === 0"
                                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    >
                                      <v-icon color="primary" size="24" style="margin-right:12px;">
                                        mdi-upload
                                      </v-icon>
                                      <div>Upload <b>NielsenIQ Service Supplied to Cooperator</b> (Word .DOCX Only)</div>
                                    </v-btn>
                                  </x-upload>

                                  <v-btn text
                                    v-if="request.nielsenServiceSuppliedToCooperator.length > 0"
                                    style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                                    @click="isNielsenServiceSuppliedToCooperatorUploadConfirming = true"
                                  >
                                    <v-icon color="primary" size="24" style="margin-right:12px;">
                                      mdi-upload
                                    </v-icon>
                                    <div>Change <b>NielsenIQ Service Supplied to Cooperator</b> (Word .DOCX Only)</div>
                                  </v-btn>
                                </div>

                                <v-expand-transition>
                                  <div 
                                    v-if="isRequestInfoChecked && request.nielsenServiceSuppliedToCooperator.length === 0" 
                                    class="red--text"
                                    style="font-size:12px;"
                                  >
                                    Please upload NielsenIQ Service Supplied to Cooperator
                                  </div>
                                </v-expand-transition>
                                
                                <div style="padding-bottom: 16px;">
                                  <file-list
                                    :files="request.nielsenServiceSuppliedToCooperator"
                                    :deletable="canEditRequestForm"
                                    :multiple-rows="windowWidth < 650"
                                    @click-download="downloadFile"
                                    @click-delete="deleteNielsenServiceSuppliedToCooperator"
                                  ></file-list>
                                  <div
                                    tabindex="0"
                                    ref="legal_request_other_nielsen_service_supplied_to_cooperator"
                                    style="height: 1px;"
                                  ></div>
                                </div>
                              </v-flex>
                              <v-flex xs12 class="v-flex-row-breaker"></v-flex>
                            </template>
                          </v-layout>
                        </v-form>
                      </template>
                    </material-app-card>
                  </v-flex>

                  <v-flex
                    v-if="isDraftStepInRequestType && !isPreviewContractLoading"
                    xs12
                    lg6
                    pl-2
                    pr-4
                    pt-0
                    pb-4
                    :style="{
                      'height': $vuetify.breakpoint.lgAndUp ? '100%' : 'auto',
                      'overflow-x': 'hidden',
                      'overflow-y': $vuetify.breakpoint.lgAndUp ? 'auto' : 'hidden'
                    }"
                  >
                    <div
                      id="legal-other-contract-generater-button"
                      class="pl-10 d-flex align-center"
                      :style="{
                        'margin-top': $vuetify.breakpoint.lgAndUp ? '32px' : '0px',
                        'min-height': $vuetify.breakpoint.lgAndUp ? 'calc(100% - 32px)' : '120px',
                      }"
                    >
                      <span>Please finish request form, then click</span>
                      <v-btn color="primary"
                        class="mx-2"
                        @click="postGenerateContractPreview"
                      >
                        Generate
                      </v-btn>
                      <span>to preview contract.</span>
                    </div>
                  </v-flex>
                  <v-flex
                    v-else
                    xs12
                    lg6
                    px-2
                    pt-0
                    pb-4
                    style="position:relative;height:100%;overflow:hidden;"
                  >
                    <div
                      :style="{
                        'position': 'absolute',
                        'top': '0',
                        'left': '0',
                        'right': '0',
                        'bottom': '0',
                        'overflow': 'hidden',
                        'background-color': 'rgb(209,209,209)'
                      }"
                    >
                      <div style="position: relative;width: 100%;height: 100%;">
                        <div
                          v-if="isPreviewContractLoading || !request.contractPdfMsPath || !contractPreviewSrc"
                          style="
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: rgb(209,209,209);
                          "
                        >
                          <div style="text-align:center">
                            <v-progress-circular
                              :width="5"
                              color="grey"
                              indeterminate
                              :size="30"
                            ></v-progress-circular>
                            <div
                              class="grey--text text--darken-1"
                              :style="{
                                'margin-top': '9px',
                                'font-size': '14px'
                              }"
                            >
                              {{
                                isPreviewContractLoading
                                  ? 'Generating'
                                  : 'Loading'
                              }}
                            </div>
                          </div>
                        </div>
                        <iframe ref="contractPreviewiFrame" v-else :src="contractPreviewSrc" width="100%" height="100%" frameborder="0"></iframe>
                        <!-- <div v-else style="color: white;padding: 6px;">* Contract template will show in this grey box</div> -->
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </div>
            </div>

            <div v-if="!isMobile" style="position: absolute;bottom: 0;left: 0;right: 0;padding: 2px 12px;display: flex;">
              <v-btn color="primary"
                v-if="canEditRequestForm"
                @click="clickCloseGenerator"
              >
                Previous
              </v-btn>
              <v-btn color="primary"
                v-if="canEditGeneratedContract && isFreshContractPreviewing"
                @click="clickPrevDraftStepInContractPreviewing"
              >
                Re-edit
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                v-if="!canEditRequestForm && !isPreviewContractLoading && request.contractDocMsPath"
                @click="downloadContractDoc"
              >
                Download as {{ isApprovedRequest ? 'PDF' : 'Word' }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                v-if="canEditRequestForm && !isContractTemplateGetting && !isOtherRequiredDataElementsGetting && !isNielsenServiceSuppliedToCooperatorGetting"
                @click="postGenerateContractPreview"
              >
                Generate
              </v-btn>
              <v-btn color="primary"
                v-if="canEditGeneratedContract && isFreshContractPreviewing"
                @click="isGeneratorDisplayed = false"
              >
                Next
              </v-btn>
              <v-btn color="primary"
                v-if="canApprove && request.generatorUsage"
                @click="isRequestApproving = true"
              >
                Approve
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isOtherRequiredDataElementsUploadConfirming"
          persistent
          width="400px"
        >
          <v-card class="pt-6">
            <v-card-text v-if="request.otherRequiredDataElements.length > 0">
              Replace Other Required Data Elements with the upload file?
            </v-card-text>
            <v-card-text v-else>
              Upload Other Required Data Elements
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                style="min-width: 90px;"
                flat
                @click="isOtherRequiredDataElementsUploadConfirming = false"
              >
                No
              </v-btn>
              <x-upload
                request-class="file"
                request-function="upload"
                accept=".docx"
                :params="{
                  email: userEmail,
                  loginStatus: loginStatus
                }"
                :before-upload="beforeUploadOtherRequiredDataElements"
                :on-error="errorUploadOtherRequiredDataElements"
                :on-success="successUploadOtherRequiredDataElements"
              >
                <v-btn
                  dark
                  color="primary"
                  style="min-width: 90px;margin-left: 9px !important;"
                >
                  Yes
                </v-btn>
              </x-upload>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isNielsenServiceSuppliedToCooperatorUploadConfirming"
          persistent
          width="400px"
        >
          <v-card class="pt-6">
            <v-card-text v-if="request.nielsenServiceSuppliedToCooperator.length > 0">
              Replace NielsenIQ Service Supplied to Cooperator with the upload file?
            </v-card-text>
            <v-card-text v-else>
              Upload NielsenIQ Service Supplied to Cooperator
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                style="min-width: 90px;"
                flat
                @click="isNielsenServiceSuppliedToCooperatorUploadConfirming = false"
              >
                No
              </v-btn>
              <x-upload
                request-class="file"
                request-function="upload"
                accept=".docx"
                :params="{
                  email: userEmail,
                  loginStatus: loginStatus
                }"
                :before-upload="beforeUploadNielsenServiceSuppliedToCooperator"
                :on-error="errorUploadNielsenServiceSuppliedToCooperator"
                :on-success="successUploadNielsenServiceSuppliedToCooperator"
              >
                <v-btn
                  dark
                  color="primary"
                  style="min-width: 90px;margin-left: 9px !important;"
                >
                  Yes
                </v-btn>
              </x-upload>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isCommentsSending"
          persistent
          width="fit-content"
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
          style="overflow: visible"
        >
          <v-card class="legal-request-other-comments-card">
            <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile" text icon @click="closeSendComments">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    {{ isContractSubmitting ? 'Send For Approval' : 'Comments' }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn v-if="isMobile && !isContractSubmitting" text icon @click="clickSendComments">
                    <v-icon>check</v-icon>
                  </v-btn>
                  <v-btn v-else text icon @click="closeSendComments">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-form ref="contract-comment-form" :lazy-validation="true">
              <div :style="{position: 'absolute',top: '72px',bottom: 0,left: 0,right: 0,overflow: 'visible'}">
                <div class="legal-request-other-comments-container legal-request-other-comments-container-icon"
                  v-if="isContractSubmitting"
                  :style="{
                    'text-align': 'left',
                    ... isMobile ? { 'padding-bottom': '6px' } : { 'padding': '0 16px 12px' }
                  }"
                >
                  <v-icon color="warning darken-1">
                    mdi-help-circle
                  </v-icon>
                  <div>
                    Your Request will be sent
                    <span v-if="firstApprover.email">to </span>
                    <span v-if="firstApprover.email" class="legal-request-other-comments-container-icon-bold">{{ firstApprover.name ? (firstApprover.name + ' (' + firstApprover.email + ')') : firstApprover.email }}</span>
                    for approval. Do you confirm to submit this request?
                  </div>
                </div>

                <div
                  class="legal-request-other-comments-container"
                  :style="isMobile ? { 'padding-bottom': '6px' } : { 'padding': '8px 16px 0' }"
                >
                  <textarea-at
                    v-model="comments.content"
                    :rows="(isMobile ? commentBoxRows : 6) - (isContractSubmitting ? 2 : 0)"
                    :placeholder="`Please input comments${isContractSubmitting ? ' (If any)' : ''}`"
                    :counter="maxLenComments"
                    :solo="!isMobile"
                    no-resize
                    :rules="[...(isContractSubmitting ? [] : [v => (!!v && v.toString().trim() != '') || 'Please input'])]"

                    @input="commentInput"
                    @at="commentAt"
                    @at-insert="commentAtInsert"
                  >
                  </textarea-at>
                </div>
                <div style="display: flex; align-items: center;justify-content: space-between;padding: 0 12px;">
                  <x-upload
                    request-class="file"
                    request-function="upload"
                    :params="{
                      email: userEmail,
                      loginStatus: loginStatus
                    }"
                    :before-upload="beforeUploadAttachment"
                    :on-error="errorUploadAttachment"
                    :on-success="successUploadAttachment"
                  >
                    <v-btn text
                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                    >
                      <v-icon color="primary" size="24" style="margin-right:12px;">
                        mdi-upload
                      </v-icon>
                      <div>Upload Supporting Documents (If Any)</div>
                    </v-btn>
                  </x-upload>
                </div>
                <file-list
                  :files="comments.files"
                  :multiple-rows="windowWidth < 650"
                  :style="{
                    'height': 'calc(100% - ' + ((isMobile ? commentBoxRows * 18 + 24 : 244) + 74) + 'px)',
                    'padding': '0 12px',
                    'padding-bottom': (isMobile ? 60 : 0) + 'px',
                    'overflow-y': 'auto'
                  }"
                  @click-download="downloadFile"
                  @click-delete="deleteAttachment"
                ></file-list>

                <div v-if="!isMobile" style="padding: 6px 16px;text-align: right;">
                  <v-btn v-if="isContractSubmitting" color="primary"
                    :disabled="comments.content ? comments.content.length > maxLenComments : false"
                    @click="clickSubmitRequestFinal"
                  >
                    Submit
                  </v-btn>
                  <v-btn v-else color="primary"
                    @click="clickSendComments"
                  >
                    Send
                  </v-btn>
                </div>
              </div>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isNewDraftedContractUploadCardDisplayed"
          persistent
          width="fit-content"
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <v-card class="legal-request-other-new-drafted-contract-card">
            <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
              <v-list-item>
                <template v-if="isMobile">
                  <v-list-item-action>
                    <v-btn v-if="isMobile" text icon @click="isNewDraftedContractUploadCardDisplayed = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>

                  <v-spacer></v-spacer>
                </template>

                <v-list-item-content>
                  <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
                    Contract Update
                  </v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn v-if="isMobile" text icon @click="clickConfirmNewDraftedContract">
                    <v-icon>check</v-icon>
                  </v-btn>
                  <v-btn v-else text icon @click="isNewDraftedContractUploadCardDisplayed = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div :style="{position: 'absolute',top: '64px',bottom: 0,left: 0,right: 0,overflow: 'visible'}">
              <div style="display: flex; align-items: center;justify-content: space-between;padding: 0 12px;">
                <div style="margin-right: 4px;">
                  <x-upload
                    request-class="file"
                    request-function="upload"
                    accept=".doc,.docx,.pdf"
                    :params="{
                      email: userEmail,
                      loginStatus: loginStatus
                    }"
                    :before-upload="beforeUploadNewDraftedContract"
                    :on-error="errorUploadNewDraftedContract"
                    :on-success="successUploadNewDraftedContract"
                  >
                    <v-btn text
                      style="margin: 0!important;padding:10px 12px 10px 6px!important;"
                    >
                      <v-icon color="primary" size="24" style="margin-right:12px;">
                        mdi-upload
                      </v-icon>
                      <div>Upload New Contract</div>
                    </v-btn>
                  </x-upload>
                </div>
              </div>
              <file-list
                :files="newDraftedContract.files"
                :multiple-rows="windowWidth < 650"
                :style="{
                  'height': 'calc(100% - 45px - ' + (isMobile ? newDraftedContractCommentBoxRows * 18 + 98 : 236) + 'px)',
                  'min-height': '60px',
                  'padding': '0 12px',
                  'padding-bottom': '40px',
                  'overflow-y': 'auto'
                }"
                @click-download="downloadFile"
                @click-delete="deleteNewDraftedContract"
              ></file-list>
              <div
                class="contract-review-comments-container"
                :style="isMobile ? { 'padding-bottom': '3px' } : { 'padding': '12px 18px' }"
              >
                <div :style="{'text-align': 'left','font-size': '14px',padding: '2px ' + ( isMobile ? '18px' : '0')}">Comments</div>
                <textarea-at
                  v-model="newDraftedContract.comments"
                  :rows="isMobile ? newDraftedContractCommentBoxRows : 3"
                  placeholder="Please input comments (if any)"
                  :counter="maxLenDraftComments"
                  solo
                  no-resize
                
                  @input="draftCommentInput"
                  @at="draftCommentAt"
                  @at-insert="draftCommentAtInsert"
                >
                </textarea-at>
              </div>

              <div v-if="!isMobile" style="padding: 0 18px 2px;text-align: right;">
                <v-btn color="primary"
                  :disabled="newDraftedContract.comments ? newDraftedContract.comments.length > maxLenDraftComments : false"
                  @click="clickConfirmNewDraftedContract"
                >
                  Update
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isRequestApproving"
          persistent
          width="fit-content"
        >
          <v-card class="legal-request-other-approve-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Approve</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isRequestApproving = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <div style="position: absolute;top: 64px;bottom: 0;left: 0;right: 0;overflow: visible;">
              <div style="text-align: left;padding: 3px 16px;">
                Please confirm the approval of this request.
              </div>
              <div
                class="legal-request-other-comments-container"
                :style="{ 'padding': '12px 16px' }"
              >
                <textarea-at
                  v-model="approval.comments.content"
                  :rows="3"
                  placeholder="Please input comments (if any)"
                  hide-details
                  solo
                  no-resize
                
                  @input="approvalCommentInput"
                  @at="approvalCommentAt"
                  @at-insert="approvalCommentAtInsert"
                >
                </textarea-at>
              </div>

              <div style="padding: 0 16px 2px;text-align: right;">
                <v-btn color="primary" @click="postApproveRequest">
                  Approve
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isRequestRecalling"
          persistent
          width="fit-content"
        >
          <v-card class="legal-request-other-sign-type-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Recall</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isRequestRecalling = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            
            <div style="text-align: left;padding: 12px 24px;display: flex;align-items: start;">
              <v-icon color="warning darken-1" style="margin-top: -3px;margin-right: 9px">mdi-help-circle</v-icon>
              <slot>
                <span>Do you confirm to recall this request?
                  {{
                    cntCanRecall === 1 ?
                      canRecallSubmit ? 'Approval process will restart from the very beginning.' :
                      canRecallSign ? 'Signature process will restart.' :
                      '' 
                    : ''
                  }}
                </span>
              </slot>
            </div>

            <v-form ref="legal-request-other-recall-form" :lazy-validation="true">
              <v-radio-group v-if="cntCanRecall > 1" class="legal-request-other-radio-group-vertical legal-request-other-recall-radio-group mx-4 my-3"
                v-model="recall.type"
                :rules="[v => !!v || 'Please select']"
              >
                <v-radio class="py-2" :value="1" color="primary"
                  v-if="canRecallSubmit"
                >
                  <template v-slot:label>
                    <div>
                      <div>Resubmit</div>
                      <div class="legal-request-other-recall-radio-description">Approval process will restart from the very beginning</div>
                    </div>
                  </template>
                </v-radio>
                <v-radio class="py-2" :value="2" color="primary"
                  v-if="canRecallSign"
                >
                  <template v-slot:label>
                    <div>
                      <div>Resign</div>
                      <div class="legal-request-other-recall-radio-description">Signature process will restart</div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-form>

            <div :class="{'mt-3': (cntCanRecall === 1)}" style="padding: 0 16px 8px;text-align: right;">
              <v-btn color="primary"
                :disabled="!recall.type"
                @click="postRecallRequest"
              >
                Yes
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignTypeSelecting"
          persistent
          width="fit-content"
        >
          <v-card class="legal-request-other-sign-type-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Sign</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isSignTypeSelecting = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-radio-group class="legal-request-other-radio-group-vertical legal-request-other-sign-con-type-radio-group mx-3 mt-0 mb-2"
              v-model="signFlow.type"
              :rules="[v => !!v || 'Please select']"
            >
              <v-radio class="py-2" :value="1" color="primary" key="legal-request-other-sign-type-radio-physical-chop">
                <template v-slot:label>
                  <div>
                    <div>Physical Chop</div>
                    <div class="legal-request-other-sign-con-type-radio-description">Cannot be recalled once selected</div>
                  </div>
                </template>
              </v-radio>
              <v-radio class="py-2" :value="2" color="primary" key="legal-request-other-sign-type-radio-e-chop">
                <template v-slot:label>
                  <div>
                    <div>E Chop</div>
                    <div class="legal-request-other-sign-con-type-radio-description">Send a link to client/vendor for chop</div>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>

            <div style="padding: 0 16px 8px;text-align: right;">
              <v-btn color="primary"
                :disabled="!signFlow.type"
                :loading="isSignTypeNextLoading"
                @click="finishSelectType"
              >
                Next
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignerSelecting"
          persistent
          width="fit-content"
        >
          <v-card class="legal-request-other-signer-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Signers</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon
                    :disabled="isSignFlowCreating"
                    @click="isSignerSelecting = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-form ref="legal-request-other-signer-form" :lazy-validation="true">
              <v-layout wrap>
                <v-flex xs12 mx-4 mt-3 mb-2>
                  <div class="sign-flow-signer-header">Internal Signer</div>
                </v-flex>
                <v-flex xs12 mx-4>
                  <v-autocomplete
                    :disabled="false"
                    label="Legal Entity Name"
                    placeholder="Please select"
                    v-model="signFlow.intSigner.organize.name"
                    :items="intSignerOrganizeNames"
                    :rules="[v => !!v || '']"
                  >
                  </v-autocomplete>
                </v-flex>
                <template
                  v-if="signFlow.extSigner.need"
                >
                  <v-flex xs12 mx-4 mt-4 mb-2>
                    <div class="sign-flow-signer-header">External Signer</div>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Legal Entity Name"
                      placeholder="Please input"
                      no-filter
                      :return-object="false"
                      item-value="name"
                      :search-input.sync="extSignerOrganizeNameKey"
                      v-model="signFlow.extSigner.organize.name"
                      :items="extSignerOrganizeNames"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerOrganizeName"
                      @blur="() => signFlow.extSigner.organize.name = extSignerOrganizeNameKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.name }}</div>
                          <div v-if="item.hasAgent">
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" size="18" v-on="on">
                                  playlist_add_check
                                </v-icon>
                              </template>
                              <span>Liaison in Legal Entity</span>
                            </v-tooltip>
                          </div>
                          <div v-else>
                            <v-icon color="grey lighten-1" size="18">
                              list
                            </v-icon>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Name"
                      placeholder="Please input"
                      :return-object="false"
                      item-text="name"
                      item-value="name"
                      :search-input.sync="extSignerAgentNameKey"
                      v-model="signFlow.extSigner.agent.name"
                      :items="extSignerAgentNames"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentName"
                      @blur="() => signFlow.extSigner.agent.name = extSignerAgentNameKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.name }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Mobile No."
                      placeholder="Please input"
                      :return-object="false"
                      item-value="mobile"
                      item-text="mobile"
                      :search-input.sync="extSignerAgentMobileKey"
                      v-model="signFlow.extSigner.agent.mobile"
                      :items="extSignerAgentMobiles"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentMobile"
                      @blur="() => signFlow.extSigner.agent.mobile = extSignerAgentMobileKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.mobile }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                  <v-flex xs12 mx-4>
                    <v-combobox
                      :disabled="false"
                      label="Liaison Email Address"
                      placeholder="Please input"
                      :return-object="false"
                      item-value="email"
                      item-text="email"
                      :search-input.sync="extSignerAgentEmailKey"
                      v-model="signFlow.extSigner.agent.email"
                      :items="extSignerAgentEmails"
                      :rules="[v => !!v || '']"
                      @input="inputExtSignerAgentEmail"
                      @blur="() => signFlow.extSigner.agent.email = extSignerAgentEmailKey"
                    >
                      <template v-slot:item="{ item }">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <div>{{ item.email }}</div>
                          <div>
                            <span v-if="item.hasAgent">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    mdi-account-check
                                  </v-icon>
                                </template>
                                <span>Liaison matched</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                mdi-account
                              </v-icon>
                            </span>
                            <span v-if="item.hasOrg">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" size="18" v-on="on">
                                    playlist_add_check
                                  </v-icon>
                                </template>
                                <span>Liaison in Legal Entity</span>
                              </v-tooltip>
                            </span>
                            <span v-else>
                              <v-icon color="grey lighten-1" size="18">
                                list
                              </v-icon>
                            </span>
                          </div>
                        </div>
                      </template>
                    </v-combobox>
                  </v-flex>
                </template>
              </v-layout>
            </v-form>

            <div style="padding: 0 16px 8px;text-align: right;" class="mt-3">
              <v-btn color="primary"
                :loading="isSignFlowCreating"
                @click="createSignFlow"
              >
                Next
              </v-btn>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="isSignUrlDisplayed"
          persistent
          width="fit-content"
        >
          <v-card class="legal-request-other-signer-card">
            <v-list class="white">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title :style="{'font-size': '20px'}">Sign</v-list-item-title>
                </v-list-item-content>

                <v-spacer></v-spacer>

                <v-list-item-action>
                  <v-btn text icon @click="isSignUrlDisplayed = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-layout wrap pb-10>
              <v-flex xs12 mx-4 mt-2 mb-2>
                <div style="display: flex; align-items: center;">
                  <div class="sign-flow-info-sign-header">Internal Sign</div>
                  <div class="ml-4">
                    <!-- xxxxx -->
                    <v-btn
                      small text icon
                      class="my-0"
                      color="primary"
                      @click="clickIntLink"
                    >
                      <v-icon>mdi-arrow-right-circle</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-flex>
              <v-flex v-if="false" xs12 mx-4>
                <div class="sign-flow-info-sign-content grey--text">URL: {{ signFlow.intSigner.url }}</div>
              </v-flex>
              <v-flex xs12 mx-4 mt-3 mb-3>
                <div class="sign-flow-info-sign-content red--text">NOTE:</div>
                <div class="sign-flow-info-sign-content red--text mb-3">* Signature Consant Password: {{ signFlow.intSigner.pwd }}</div>
                <div class="sign-flow-info-sign-content red--text">* location of official seal (The NielsenIQ Company GZ Limited): GZ Office</div>
                <div class="sign-flow-info-sign-content red--text">* location of contract seal (The NielsenIQ Company GZ Limited): SH Office</div>
              </v-flex>
              <template v-if="isExtSigningRequest && signFlow.extSigner.need">
                <v-flex xs12 mx-4 mt-3 mb-2>
                  <div class="sign-flow-info-sign-header">External Sign</div>
                </v-flex>
                <v-flex v-if="false" xs12 mx-4 mt-2>
                  <div class="sign-flow-info-sign-content grey--text">URL: {{ signFlow.extSigner.url }}</div>
                </v-flex>
              <v-flex xs12 mx-4 mt-2>
                <div class="sign-flow-info-sign-content primary--text">* Notification email has been sent.</div>
              </v-flex>
              </template>
            </v-layout>

          </v-card>
        </v-dialog>

        <!-- Add Opportunity -->
        <v-dialog
          v-model="addingOpportunity"
          width="fit-content"
          persistent
          :fullscreen="isMobile"
          :hide-overlay="isMobile"
          :transition="isMobile ? 'dialog-bottom-transition' : 'dialog-transition'"
        >
          <add-opportunity-card
            :is-mobile="isMobile"
            title="Search Opportunity"
            @close="closeAddOpportunityNew"
            @submit="doAddOpportunity"
          >
          </add-opportunity-card>
        </v-dialog>

        <confirm-dialog
          :card-class="['legal-request-other-confirm-card']"
          v-model="isPrevDraftStepConfirming"
          title="Previous"
          comments="Do you confirm to go back to previous step? (Some information may be lost if you go to previous step)"
          @click-yes="clickConfirmPrevDraftStep"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['legal-request-other-confirm-card']"
          v-model="isNewDraftedContractUploadConfirming"
          comments="Do you conrfirm to upload new contract version to replace the previous version?"
          @click-yes="postReviseDraftedContract"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['legal-request-other-confirm-card']"
          v-model="isRequestAbandoning"
          title="Abandon"
          comments="Do you confirm to abandon this request?"
          @click-yes="postAbandonRequest"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['legal-request-other-confirm-card']"
          v-model="isExtSignerConfirming"
          title="External Signer Confirm"
          @click-yes="clickConfirmExtSigner"
          @click-no="cancelConfirmExtSigner"
          @click-close="closeConfirmExtSigner"
        >
          <div>
            <div class="mb-2" v-if="msgExtOrg">{{ msgExtOrg }}</div>
            <div class="mb-2" v-if="msgExtAgent">{{ msgExtAgent }}</div>
            <div class="mb-2" v-if="msgExtOrgXAgent">{{ msgExtOrgXAgent }}</div>
          </div>
        </confirm-dialog>

        <confirm-dialog
          :card-class="['legal-request-other-confirm-card']"
          v-model="isContractArchiving"
          icon="mdi-information"
          icon-color="primary"
          title="Archive"
          comments="Do you confirm to archive the contract?"
          @click-yes="postArchive"
        ></confirm-dialog>

        <confirm-dialog
          :card-class="['legal-request-other-nielsen-entity-alert-card']"
          v-model="isNielsenEntityExpiredAlertDisplaying"
          title="Issue - NielsenIQ Entity Name"
          icon="mdi-alert"
          icon-color="red"
          :show-no="false"
          yes-text="OK"
          @click-yes="isNielsenEntityExpiredAlertDisplaying = false"
        >
          <span>NielsenIQ Entity Name <b>"{{ nielsenEntityNameInLanguage }}"</b> is unavailable for signing now. <br/>For any query, please contact Scarlett (scarlett.liao.consultant@nielseniq.com) for solution.</span>
        </confirm-dialog>

        <confirm-dialog
          :card-class="['nielsen-entity-alert-card']"
          v-model="isNielsenEntityPhysicalSignOnlyDisplaying"
          title="Issue - Sales Organization"
          icon="mdi-alert"
          icon-color="red"
          yes-text="OK"
          @click-yes="isNielsenEntityPhysicalSignOnlyDisplaying = false"
        >
          <span>NielsenIQ Entity Name <b>"{{ nielsenEntityNameInLanguage }}"</b> is only for <b>offline signing</b> now. <br/>For any query, please contact Scarlett (scarlett.liao.consultant@nielseniq.com) for solution.</span>
        </confirm-dialog>
        
        <share-access
          v-model="isAccessSharing"
          :owner="request.requestor"
          :members="collaborators"
          :editable="canShareAccess"
          @save-members="postUpdatedCollaborator"
        ></share-access>
      </v-layout>
    </div>
  </v-fade-transition>
</template>

<script>
// ====== import ======
// --- config ---
import { isProductEnv, isCdRevEnv } from '@/config'

// --- vuex ---
import { mapState, mapGetters, mapActions } from 'vuex'

// --- functions ---
import { xPost, xDownload } from '@/functions/http/axios'
import { toThousands } from '@/functions/maths'

// --- components ---
import activityHistory from '@/components/app/history'
import addOpportunityCard from '@/components/app/opportunity/x-opp-id-card'
import confirmDialog from '@/components/vuetify-extension/confirm-dialog'
import fileList from '@/components/vuetify-extension/file-list'
import AccountAutocomplete from '@/components/vuetify-extension/account-autocomplete'
import AccountProfile from '@/components/vuetify-extension/account-profile'
import TextareaAt from '@/components/vuetify-extension/textarea-at'
import leaderSelect from '@/components/app/select/user/leader-select'
import shareAccess from '@/components/app/dialog/share-access'
import userSelectSync from '@/components/app/select/user/user-select-sync'
import xUpload from '@/components/x-upload'
import MsalFunctions from '@/components/msal/functions/index.vue'



export default {
  components: {
    activityHistory,
    addOpportunityCard,
    confirmDialog,
    fileList,
    AccountAutocomplete,
    AccountProfile,
    TextareaAt,
    leaderSelect,
    shareAccess,
    userSelectSync,
    xUpload,
    MsalFunctions,
  },
  data () { return {
    // ====== props ======
    isPageLoading: false,
    isGeneratorDisplayed: false,
    isFreshContractPreviewing: false,
    isPreviewContractLoading: false,
    isRequestInfoChecked: false,
    isApprovalInfoChecked: false,
    isPrevDraftStepConfirming: false,
    isContractSubmitting: false,
    isCommentsSending: false,
    isRequestRecalling: false,
    isRequestAbandoning: false,
    isRequestApproving: false,
    isNewDraftedContractUploadCardDisplayed: false,
    isNewDraftedContractUploadConfirming: false,
    isAccessSharing: false,
    isSignTypeSelecting: false,
    isSignTypeNextLoading: false,
    isSignerSelecting: false,
    isIntSignerLoading: false,
    isExtSignerLoading: false,
    isSignFlowCreating: false,
    isExtSignerConfirming: false,
    isSignUrlDisplayed: false,
    isContractArchiving: false,
    isNielsenEntityExpiredAlertDisplaying: false,
    isNielsenEntityPhysicalSignOnlyDisplaying: false,
    addingOpportunity: false,

    isPickerContractEffectiveDateDisplayed: false,
    isPickerContractEffectiveDateDisplayedGen: false,
    isPickerContractEndDateDisplayed: false,
    isPickerContractEndDateDisplayedGen: false,
    isPickerContractSigningDateDisplayed: false,
    isPickerContractSigningDateDisplayedGen: false,

    isContractTemplateGetting: false,
    isOtherRequiredDataElementsGetting: false,
    isOtherRequiredDataElementsUploadConfirming: false,
    isOtherRequiredDataElementsUploading: false,
    isNielsenServiceSuppliedToCooperatorGetting: false,
    isNielsenServiceSuppliedToCooperatorUploadConfirming: false,
    isNielsenServiceSuppliedToCooperatorUploading: false,
    isAttachmentUploading: false,
    
    isClientRequestFormUploading: false,
    isDraftedContractUploading: false,
    isNewDraftedContractUploading: false,
    isEPMUploading: false,

    maxLenNielsenEntityAddress: 1000,
    maxLenRetailerEntityAddress: 1000,
    maxLenProjectDescriptions: 1000,
    maxLenPayeeEntityAddress: 1000,
    maxLenPayerEntityAddress: 1000,
    maxLenProjectNameAndDescriptions: 1000,
    maxLenBackgroundBriefing: 1000,
    maxLenComments: 1000,
    maxLenDraftComments: 1000,

    msgExtOrg: '',
    msgExtAgent: '',
    msgExtOrgXAgent: '',

    // ====== params ======
    requestTypes: [],
    salesOrgs: [],
    serviceTypes: [
      {
        value: 'Information (data only, no report)',
      },
      {
        value: 'Insights (data with report)'
      },      
    ],
    currencies: [],
    products: [],
    verticals: [],
    leaderTitles: [],
    ciLeaderApprovers: [],
    productDSApprovers: [],
    legalApprovers: [],
    legalExApprovers: [],
    marcomApprovers: [],
    archiveApprovers: [],
    stages: [],

    soldToKey: '',
    soldToItems: [],
    soldToTime: null,
    soldToLoading: false,

    clientEntityNameKey: '',
    clientEntityNameItems: [],
    clientEntityNameTime: null,
    clientEntityNameLoading: false,

    retailerEntityNameKey: '',
    retailerEntityNameItems: [],
    retailerEntityNameTime: null,
    retailerEntityNameLoading: false,

    cooperatorEntityNameKey: '',
    cooperatorEntityNameItems: [],
    cooperatorEntityNameTime: null,
    cooperatorEntityNameLoading: false,

    extSignerOrganizeNameKey: '',
    // extSignerOrganizeNameItems: [],

    extSignerAgentNameKey: '',
    // extSignerAgentNameItems: [],

    extSignerAgentMobileKey: '',
    // extSignerAgentMobileItems: [],

    extSignerAgentEmailKey: '',
    // extSignerAgentEmailItems: [],

    nielsenAppointedEntityNamePayeeKey: '',
    nielsenAppointedEntityNamePayeeItems: [],

    nielsenAssigningEntityNamePayerKey: '',
    nielsenAssigningEntityNamePayerItems: [],
    
    // time flag - 保证读取最新数据
    tfPreviewingContract: null,
    flagPostExtOrgs: null,
    flagPostExtAgents: [null, null],
    flagPostExtOrgsAndAgents: [null, null],

    extraRequestors: [],

    // ====== form ======
    request: {
      requestId: null,
      requestor: {
        email: null,
        name: null,
        icon: null,
      },
      costCenter: null,
      team: null,
      stage: null,
      draftStep: null,
      type: null,
      selectGeneratorUsage: null,
      generatorUsage: null,
      needForm: null,
      needLanguage: null,
      needProduct: null,
      needVertical: null,
      needOpportunity: null,
      needNielsenEntityName: null,
      needSoldTo: null,
      needClientEntityName: null,
      needRetailerEntityName: null,
      needCooperatorEntityname: null,
      needContractEffectiveDate: null,
      needContractEndDate: null,
      needContractSigningDate: null,
      needNielsenEntityAddress: null,
      needRetailerEntityAddress: null,
      needNielsenResponsibleContactPerson: null,
      needRetailerResponsibleContactPerson: null,
      needProjectDescriptions: null,
      needOtherRequiredDataElements: null,
      needNielsenServiceSuppliedToCooperator: null,
      needNielsenAppointedEntityNamePayee: null,
      needPayeeEntityAddress: null,
      needPayeeEntityTelephoneNumber: null,
      needPayeeEntityFaxNumber: null,
      needNielsenAssigningEntityNamePayer: null,
      needPayerEntityAddress: null,
      needPayerEntityTelephoneNumber: null,
      needPayerEntityFaxNumber: null,
      needProjectNumber: null,
      needProjectNameAndDescriptions: null,
      needFieldWorkCountry: null,
      needStaffSendToAbroad: null,
      needProjectDuration: null,
      needServiceType: null,
      needLaborEntryFromOverseas: null,
      needCurrency: null,
      needServiceFeeAmount: null,
      needPayerEntityContactPerson: null,
      needLeaderApproval: null,
      needCILeaderApproval: null,
      needProductDSApproval: null,
      needLegalApproval: null,
      needLegalExApproval: null,
      needMarcomApproval: null,
      language: null,
      product: null,
      vertical: null,
      opportunityId: null,
      nielsenEntityName: null,
      soldTo: null,
      clientEntityName: null,
      retailerEntityName: null,
      cooperatorEntityName: null,
      contractEffectiveDate: null,
      contractEndDate: null,
      contractSigningDate: null,
      nielsenEntityAddress: null,
      retailerEntityAddress: null,
      nielsenResponsibleContactPerson: {
        email: null,
        name: null,
        icon: null,
      },
      retailerResponsibleContactPerson: null,
      projectDescriptions: null,
      nielsenAppointedEntityNamePayee: null,
      payeeEntityAddress: null,
      payeeEntityTelephoneNumber: null,
      payeeEntityFaxNumber: null,
      nielsenAssigningEntityNamePayer: null,
      payerEntityAddress: null,
      payerEntityTelephoneNumber: null,
      payerEntityFaxNumber: null,
      projectNumber: null,
      projectNameAndDescriptions: null,
      fieldWorkCountry: null,
      staffSendToAbroad: null,
      projectDuration: null,
      serviceType: null,
      laborEntryFromOverseas: null,
      currency: null,
      serviceFeeAmount: null,
      payerEntityContactPerson: {
        email: null,
        name: null,
        icon: null
      },

      otherRequiredDataElements: [],
      nielsenServiceSuppliedToCooperator: [],

      contractPdfMsPath: '',
      contractDocMsPath: '',
      contractPdfLcPath: '',
      contractDocLcPath: '',

      backgroundBriefing: null,

      clientRequestForm: [],
      draftedContract: [],
      epm: [],
      checkAlertEPM: false,

      leaderApprover: {
        email: null,
        name: null,
        icon: null,
      },
      ciLeaderApprover: {
        email: null,
        name: null,
        icon: null,
      },
      productDSApprover: {
        email: null,
        name: null,
        icon: null,
      },
      legalApprover: {
        email: null,
        name: null,
        icon: null,
      },
      legalExApprover: {
        email: null,
        name: null,
        icon: null,
      },
      marcomApprover: {
        email: null,
        name: null,
        icon: null,
      },
      
      archiveApprover: {
        email: null,
        name: null,
        icon: null,
      },

      signType: null,
      needExtSign: null,
      needArchive: null,
      canRecallArchive: null,

      ci: {
        isListProvided: null,
      },
      purpose: null,
    },

    dummyContractUnderReview: [{
      name: 'Contract Aligned'
    }],

    collaborators: [],
    ats: [],
    
    roles: [],

    history: [],

    // comments
    comments: {
      content: null,
      files: [],
    },
    
    newDraftedContract: {
      files: [],
      comments: '',
    },

    approval: {
      comments: {
        content: '',
      }
    },

    recall: {
      type: null
    },

    signConf: {
      defSignType: null,
      needExtSign: null,
    },
    signFlow: {
      esignFlowId: null,
      type: null,
      intSigner: {
        organize: {
          id: null,
          name: null,
        },
        signed: null,
        url: null,
        pwd: null,
      },
      extSigner: {
        need: null,
        organize: {
          id: null,
          name: null,
        },
        agent: {
          id: null,
          name: null,
          mobile: null,
          email: null,
        },
        // organize and agent binded ?
        binded: false,
        signed: null,
        url: null,
      }
    },
    intOrganizes: [],
    extOrganizesXAgents: [],
    extAgentsXOrganizes: [],

    options: {
      ci: {
        isListProvided: [],
      },
      purposes: [],
    },

    // preview
    contractPreviewSrc: null,

    // msal
    refNo: Date.parse(new Date()),
  }},
  computed: {
    // ====== vuex ======
    ...mapState('app', {
      windowWidth: state => state.windowSize.width,
      windowHeight: state => state.windowSize.height,
      themeColor: state => state.color,
    }),
    ...mapGetters('app', [
      'isMobile',
    ]),
    ...mapGetters('user', [
      'costCenter',
      'loginStatus',
      'userEmail',
      'userName',
      'userPhotoUrl',
      'userTeam',
      'userLocation',
    ]),

    // ====== props ======
    // --- msal ---
    refMsalFunction () {
      return `msal-function-${this.refNo}`
    },
    // --- request ---
    currStageId () {
      if (!this.history.length) return '1'
      return this.history[0].stageId || '1'
    },
    isNewRequest () {
      return this.token === 'new'
    },
    isDraftedRequest () {
      return this.currStageId === '1'
    },
    isRecalledRequest () {
      return this.currStageId === '7'
    },
    isPendingRequest () {
      return this.isNewRequest || this.isDraftedRequest || this.isRecalledRequest
    },
    isAbandonedRequest () {
      return this.currStageId === '8'
    },
    isLeaderApprovalRequest () {
      return this.currStageId === '3'
    },
    isCILeaderApprovalRequest () {
      return this.currStageId === '15'
    },
    isProductDSApprovalRequest () {
      return this.currStageId === '16'
    },
    isLegalApprovalRequest () {
      return this.currStageId === '4'
    },
    isLegalExApprovalRequest () {
      return this.currStageId === '5'
    },
    isMarcomApprovalRequest () {
      return this.currStageId === '17'
    },
    isApprovalRequest () {
      return (
        this.isLeaderApprovalRequest
        || this.isCILeaderApprovalRequest
        || this.isProductDSApprovalRequest
        || this.isLegalApprovalRequest
        || this.isLegalExApprovalRequest
        || this.isMarcomApprovalRequest
      )
    },
    isJustApprovedRequest () {
      return this.currStageId === '6'
    },
    isIntSigningRequest () {
      return this.currStageId === '9'
    },
    isIntSignedRequest () {
      return this.currStageId === '10'
    },
    isExtSigningRequest () {
      return this.currStageId === '11'
    },
    isExtSignedRequest () {
      return this.currStageId === '12'
    },
    isSigningRequest () {
      return (
        this.isIntSigningRequest
        || this.isIntSignedRequest
        || this.isExtSigningRequest
        || this.isExtSignedRequest
      )
    },
    isArchivingRequest () {
      return this.currStageId === '13'
    },
    isArchivedRequest () {
      return this.currStageId === '14'
    },
    isApprovedRequest () {
      return (
        this.isJustApprovedRequest
        || this.isSigningRequest
        || this.isArchivingRequest
        || this.isArchivedRequest
      )
    },
    isDraftStepInRequestType () {
      return this.isPendingRequest && this.request.draftStep === '1'
    },
    isDraftStepInDraftedContract () {
      return this.isPendingRequest && this.request.draftStep === '2'
    },
    isDraftStepInContractInfo () {
      return this.isPendingRequest && this.request.draftStep === '3'
    },
    isDraftStepInGeneratedContract () {
      return this.isPendingRequest && this.request.draftStep === '4'
    },
    isDraftStepInApprovalInfo () {
      return this.isPendingRequest && this.request.draftStep === '5'
    },
    isDraftStepInClientRequestForm () {
      return this.isPendingRequest && this.request.draftStep === '6'
    },
    isDraftStepInEPMUpload () {
      return this.isPendingRequest && this.request.draftStep === '7'
    },
    // --- user ---
    isRequestor () {
      return this.userEmail === this.request.requestor.email
    },
    isCollaborator () {
      return !!this.collaborators.find(el => this.userEmail === el.email)
    },
    isAt () {
      return !!this.ats.find(el => this.userEmail === el.email)
    },
    isLeaderApprover () {
      return this.userEmail === this.request.leaderApprover.email
    },
    isCILeaderApprover () {
      return (
        this.userEmail === this.request.ciLeaderApprover.email
        || !!this.ciLeaderApprovers.find(el => this.userEmail === el.email && el.vertical === this.request.vertical && el.role === 'Approver')
      )
    },
    isCILeaderSupporter () {
      return !!this.ciLeaderApprovers.find(el => this.userEmail === el.email && el.vertical === this.request.vertical && el.role === 'Supporter')
    },
    isCILeaderViewer () {
      return !!this.ciLeaderApprovers.find(el => this.userEmail === el.email && el.vertical === this.request.vertical && el.role === 'Viewer')
    },
    isProductDSApprover () {
      return (
        this.userEmail === this.request.productDSApprover.email
        || !!this.productDSApprovers.find(el => this.userEmail === el.email && el.product === this.request.product && el.role === 'Approver')
      )
    },
    isProductDSSupporter () {
      return !!this.productDSApprovers.find(el => this.userEmail === el.email && el.product === this.request.product && el.role === 'Supporter')
    },
    isProductDSViewer () {
      return !!this.productDSApprovers.find(el => this.userEmail === el.email && el.product === this.request.product && el.role === 'Viewer')
    },
    isLegalApprover () {
      return (
        this.userEmail === this.request.legalApprover.email
        || !!this.legalApprovers.find(el => this.userEmail === el.email && el.role === 'Approver')
      )
    },
    isLegalSupporter () {
      return !!this.legalApprovers.find(el => this.userEmail === el.email && el.role === 'Supporter')
    },
    isLegalViewer () {
      return !!this.legalApprovers.find(el => this.userEmail === el.email && el.role === 'Viewer')
    },
    isLegalExApprover () {
      return (
        this.userEmail === this.request.legalExApprover.email
        || !!this.legalExApprovers.find(el => this.userEmail === el.email && el.role === 'Approver')
      )
    },
    isLegalExSupporter () {
      return !!this.legalExApprovers.find(el => this.userEmail === el.email && el.role === 'Supporter')
    },
    isLegalExViewer () {
      return !!this.legalExApprovers.find(el => this.userEmail === el.email && el.role === 'Viewer')
    },
    isMarcomApprover () {
      return (
        this.userEmail === this.request.marcomApprover.email
        || !!this.marcomApprovers.find(el => this.userEmail === el.email && el.role === 'Approver')
      )
    },
    isMarcomSupporter () {
      return !!this.marcomApprovers.find(el => this.userEmail === el.email && el.role === 'Supporter')
    },
    isMarcomViewer () {
      return !!this.marcomApprovers.find(el => this.userEmail === el.email && el.role === 'Viewer')
    },
    isArchiveApprover () {
      return (
        this.userEmail === this.request.archiveApprover.email
        || !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Approver')
      )
    },
    isArchiveSupporter () {
      return !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Supporter')
    },
    isArchiveViewer () {
      return !!this.archiveApprovers.find(el => this.userEmail === el.email && el.role === 'Viewer')
    },

    canEditRequest () {
      return (
        this.checkUserAccess()
        && (this.isRequestor || this.isCollaborator)
        && this.isPendingRequest
      )
    },
    canEditRequestType () {
      return (
        this.canEditRequest
        && this.isDraftStepInRequestType
      )      
    },
    canEditClientRequestForm () {
      return (
        this.canEditRequest
        && this.isDraftStepInClientRequestForm
      )
    },
    canEditDraftedContract () {
      return (
        this.canEditRequest
        && this.isDraftStepInDraftedContract
      )
    },
    canEditRequestForm () {
      return (
        this.canEditRequest
        && (
          (this.isDraftStepInRequestType && !this.isPreviewContractLoading)
          || this.isDraftStepInContractInfo
        )
      )
    },
    canEditGeneratedContract () {
      return (
        this.canEditRequest
        && this.isDraftStepInGeneratedContract
      )      
    },
    canEditEPMUpload () {
      return (
        this.canEditRequest
        && this.isDraftStepInEPMUpload
      )      
    },
    canEditApprovalForm () {
      return (
        this.canEditRequest
        && this.isDraftStepInApprovalInfo
      )      
    },
    canReviseContract () {
      return (
        (this.isRequestor || this.isCollaborator)
        && this.isApprovalRequest
      )
    },
    canRecallSubmit () {
      return (
        (this.isRequestor || this.isCollaborator)
        && (this.isApprovalRequest || (this.isApprovedRequest && !this.isArchivedRequest) || (this.isArchivedRequest && this.request.canRecallArchive))
      )
    },
    canRecallSign () {
      return (
        (this.isRequestor || this.isCollaborator)
        && (this.isSigningRequest || this.isArchivingRequest || (this.isArchivedRequest && this.request.canRecallArchive))
      )
    },
    cntCanRecall () {
      return (
        this.canRecallSubmit * 1
        + this.canRecallSign * 1
      )
    },
    canRecall () {
      return !!this.cntCanRecall
    },
    canAbandon () {
      return (
        (this.isRequestor || this.isCollaborator)
        && !this.isNewRequest
        && (this.isDraftedRequest || this.isRecalledRequest)
      )
    },
    canApprove () {
      return this.isApprovalRequest 
        && (
          this.roles.indexOf('CurrentApprover') !== -1
          || this.roles.indexOf('CurrentSupporter') !== -1
        )
    },
    canComment () {
      return (
        (
          !this.isPendingRequest
          && (
            this.isRequestor
            || this.isCollaborator
          )
        )
        || (
          (
            this.isApprovalRequest
            || this.isArchivingRequest
          )
          && (
            this.roles.indexOf('CurrentApprover') !== -1
            || this.roles.indexOf('CurrentSupporter') !== -1
            || this.isAt
          )
        )
      )
    },
    canView () {
      return (
        !this.isAbandonedRequest
        && (
          this.isRequestor
          || this.isCollaborator
          || (
            !this.isPendingRequest
            && (
              this.isAt
              || this.canApprove
              || this.canArchive
              || this.roles.indexOf('CurrentViewer') !== -1
              || this.roles.indexOf('PrevApprover') !== -1
              || this.roles.indexOf('PrevSupporter') !== -1
              || this.roles.indexOf('PrevViewer') !== -1
            )
          )
        )
      )
    },
    canShareAccess () {
      return this.isRequestor || this.isCollaborator
    },
    canSign () {
      return (
        (this.isRequestor || this.isCollaborator)
        && this.isJustApprovedRequest
      )
    },
    canViewSignInfo () {
      return (
        (this.isRequestor || this.isCollaborator)
        && this.isSigningRequest
      )
    },
    canDownloadSignFile () {
      return (
        (
          // (this.isRequestor || this.isCollaborator || this.isArchiveApprover || this.isArchiveSupporter)
          // && 
          (this.isArchivingRequest || this.isArchivedRequest)
        )
      )
    },
    canArchive () {
      return (
        (this.isArchiveApprover || this.isArchiveSupporter)
        && this.isArchivingRequest
      )
    },

    // --- form ---
    canSelectGeneratorUsage () {
      return this.getRequestProps('selectGeneratorUsage')
    },
    needClientRequestForm () {
      return this.getRequestProps('needClientRequestForm')
    },
    needForm () {
      return this.getRequestProps('needForm')
    },
    needLanguage () {
      return this.getRequestProps('needLanguage')
    },
    needProduct () {
      return this.getRequestProps('needProduct')
    },
    needVertical () {
      return this.getRequestProps('needVertical')
    },
    needOpportunity () {
      return this.getRequestProps('needOpportunity')
    },
    needNielsenEntityName () {
      return this.getRequestProps('needNielsenEntityName')
    },
    needSoldTo () {
      return this.getRequestProps('needSoldTo')
    },
    needClientEntityName () {
      return this.getRequestProps('needClientEntityName')
    },
    needRetailerEntityName () {
      return this.getRequestProps('needRetailerEntityName')
    },
    needCooperatorEntityname () {
      return this.getRequestProps('needCooperatorEntityname')
    },
    needNielsenResponsibleContactPerson () {
      return this.getRequestProps('needNielsenResponsibleContactPerson')
    },
    needRetailerResponsibleContactPerson () {
      return this.getRequestProps('needRetailerResponsibleContactPerson')
    },
    needNielsenEntityAddress () {
      return this.getRequestProps('needNielsenEntityAddress')
    },
    needRetailerEntityAddress () {
      return this.getRequestProps('needRetailerEntityAddress')
    },
    needContractEffectiveDate () {
      return this.getRequestProps('needContractEffectiveDate')
    },
    needContractEndDate () {
      return this.getRequestProps('needContractEndDate')
    },
    needContractSigningDate () {
      return this.getRequestProps('needContractSigningDate')
    },
    needProjectDescriptions () {
      return this.getRequestProps('needProjectDescriptions')
    },
    needOtherRequiredDataElements () {
      return this.getRequestProps('needOtherRequiredDataElements')
    },
    needNielsenServiceSuppliedToCooperator () {
      return this.getRequestProps('needNielsenServiceSuppliedToCooperator')
    },
    needNielsenAppointedEntityNamePayee () {
      return this.getRequestProps('needNielsenAppointedEntityNamePayee')
    },
    needNielsenAssigningEntityNamePayer () {
      return this.getRequestProps('needNielsenAssigningEntityNamePayer')
    },
    needPayeeEntityAddress () {
      return this.getRequestProps('needPayeeEntityAddress')
    },
    needPayerEntityAddress () {
      return this.getRequestProps('needPayerEntityAddress')
    },
    needPayeeEntityTelephoneNumber () {
      return this.getRequestProps('needPayeeEntityTelephoneNumber')
    },
    needPayerEntityTelephoneNumber () {
      return this.getRequestProps('needPayerEntityTelephoneNumber')
    },
    needPayeeEntityFaxNumber () {
      return this.getRequestProps('needPayeeEntityFaxNumber')
    },
    needPayerEntityFaxNumber () {
      return this.getRequestProps('needPayerEntityFaxNumber')
    },
    needProjectNumber () {
      return this.getRequestProps('needProjectNumber')
    },
    needFieldWorkCountry () {
      return this.getRequestProps('needFieldWorkCountry')
    },
    needStaffSendToAbroad () {
      return this.getRequestProps('needStaffSendToAbroad')
    },
    needProjectDuration () {
      return this.getRequestProps('needProjectDuration')
    },
    needLaborEntryFromOverseas () {
      return this.getRequestProps('needLaborEntryFromOverseas')
    },
    needServiceType () {
      return this.getRequestProps('needServiceType')
    },
    needCurrency () {
      return this.getRequestProps('needCurrency')
    },
    needServiceFeeAmount () {
      return this.getRequestProps('needServiceFeeAmount')
    },
    needProjectNameAndDescriptions () {
      return this.getRequestProps('needProjectNameAndDescriptions')
    },
    needPayerEntityContactPerson () {
      return this.getRequestProps('needPayerEntityContactPerson')
    },
    needCIIsListProvided () {
      return this.getRequestProps('needCIIsListProvided')
    },
    needPurpose () {
      return this.getRequestProps('needPurpose')
    },
    needEPMUpload () {
      return this.getRequestProps('needEPMUpload')
    },

    needLeaderApproval () {
      return this.getRequestProps('needLeaderApproval')
    },
    needCILeaderApproval () {
      return this.getRequestProps('needCILeaderApproval')
    },
    needProductDSApproval () {
      return this.getRequestProps('needProductDSApproval')
    },
    needLegalApproval () {
      return this.getRequestProps('needLegalApproval')
    },
    needLegalExApproval () {
      return this.getRequestProps('needLegalExApproval')
    },
    needMarcomApproval () {
      return this.getRequestProps('needMarcomApproval')
    },
    needApproval () {
      return this.needLeaderApproval
        || this.needCILeaderApproval
        || this.needProductDSApproval
        || this.needLegalApproval
        || this.needLegalExApproval
        || this.needMarcomApproval
    },

    draftedContractTitle () {
      return this.requestTypeObj instanceof Object
        ? (this.requestTypeObj['draftedContractTitle'] || '')
        : ''
    },
    draftedContractLabelEn () {
      return this.requestTypeObj instanceof Object
        ? (this.requestTypeObj['draftedContractLabelEn'] || '')
        : ''
    },
    draftedContractButtonType () {
      return this.requestTypeObj instanceof Object
        ? (this.requestTypeObj['draftedContractButtonType'] || '')
        : ''
    },
    draftedContractLabelCn () {
      return this.requestTypeObj instanceof Object
        ? (this.requestTypeObj['draftedContractLabelCn'] || '')
        : ''
    },
    formTitle () {
      return this.requestTypeObj instanceof Object
        ? (this.requestTypeObj['formTitle'] || '')
        : ''
    },
    templateURLClientRequestForm () {
      return this.requestTypeObj instanceof Object
        ? (this.requestTypeObj['templateURLClientRequestForm'] || '')
        : ''
    },
    templateURLDraftedContract () {
      return this.requestTypeObj instanceof Object
        ? (this.requestTypeObj['templateURLDraftedContract'] || '')
        : ''
    },

    isEPMEssential () {
      return this.needPurpose &&
        !!this.options.purposes.find(el =>
          el.value === this.request.purpose &&
          !el.na
        )
    },

    // ====== params ======
    token () {
      return this.$route.params.token
    },
    requestTypeObj () {
      let index = this.requestTypes.findIndex(el => el.value === this.request.type)
      if (index === -1) return null
      return this.requestTypes[index]
    },

    isSignerLoading () {
      return this.isIntSignerLoading || this.isExtSignerLoading
    },

    // form
    serviceFeeAmountToThousands () {
      let floAmount = parseFloat(this.request.serviceFeeAmount)
      if (isNaN(floAmount)) return ''
      return toThousands(floAmount.toFixed(2)) + (floAmount%1 ? '' : '.00')
    },

    // stage
    stagesDisplayed () {
      let stages = []
      let index = this.stages.findIndex(el => el.id === this.currStageId)
      if (index === -1) return []      
      stages.push(...this.allPrevStages)
      stages.push(this.stages[index])
      stages.push(...this.allNextStages)
      return stages
    },
    allPrevStages () {
      let index = this.stages.findIndex(el => el.id === this.currStageId)
      if (index === -1) return []
      let prevStr = this.stages[index].prev
      if (!prevStr) return []
      let prevs = prevStr.split(';')
      let stages = []
      prevs.forEach(prev => {
        let index2 = this.stages.findIndex(el => el.id === prev && (!el.activeField || this.request[el.activeField]))
        if (index2 === -1) return
        stages.push(this.stages[index2])
      });
      return stages
    },
    allNextStages () {
      let index = this.stages.findIndex(el => el.id === this.currStageId)
      if (index === -1) return []
      let nextStr = this.stages[index].next
      if (!nextStr) return []
      let nexts = nextStr.split(';')
      let stages = []
      nexts.forEach(next => {
        let index2 = this.stages.findIndex(el => el.id === next && (!el.activeField || this.request[el.activeField]))
        if (index2 === -1) return
        stages.push(this.stages[index2])
      });
      return stages
    },
    stageIndexOfDisplayed () {
      return this.stagesDisplayed.findIndex(el => el.id === this.currStageId)
    },

    // history
    historyDisplayed () {
      return this.history.filter(el => !!el.display)
    },

    commentBoxRows () {
      return parseInt((this.windowHeight - 64 - 45) / 18 / 2)
    },
    newDraftedContractCommentBoxRows () {
      return parseInt((this.windowHeight - 64 - 45) / 18 / 2)
    },

    // list / selections
    salesOrgsForSelect () {
      return this.salesOrgs.filter(el => el?.active)
    },
    salesOrgPlaceholder () {
      return (
        this.isPendingRequest
          ? ''
          : this.salesOrgs?.find(el => el.value === this.request.nielsenEntityName)?.[this.needLanguage ? this.request.language === 'Chinese' ? 'textCn' : 'textEn' : 'text'] ?? this.request.nielsenEntityName ?? ''
      )
    },
    requestTypesForSelect () {
      let requestTypes = this.requestTypes
        .filter(el => el.active)
      let index1 = requestTypes.findIndex(el => el.value === this.request.type)
      let index2 = this.requestTypes.findIndex(el => el.value === this.request.type)
      if (index1 === -1 && index2 !== -1) {
        let requestType = { 
          ...this.requestTypes[index2],
          disabled: true,
        }
        requestTypes.push(requestType)
      }
      return requestTypes
    },
    serviceTypesForSelect () {
      let serviceTypes = this.serviceTypes
        .map(el => ({ ...el }))
      let index1 = serviceTypes.findIndex(el => el.value === this.request.type)
      let index2 = this.serviceTypes.findIndex(el => el.value === this.request.type)
      if (index1 === -1 && index2 !== -1) {
        let serviceType = { 
          ...this.serviceTypes[index2],
          disabled: true,
        }
        serviceTypes.push(serviceType)
      }
      return serviceTypes
    },
    currenciesForSelect () {
      let currencies = this.currencies
        .filter(el => el.active)
        .map(el => ({ ...el }))
      let index1 = currencies.findIndex(el => el.value === this.request.type)
      let index2 = this.currencies.findIndex(el => el.value === this.request.type)
      if (index1 === -1 && index2 !== -1) {
        let currency = { 
          ...this.currencies[index2],
          disabled: true,
        }
        currencies.push(currency)
      }
      return currencies
    },
    productsForSelect () {
      let products = this.products
        .filter(el => el.active && ((',' + (el.types || '') + ',').indexOf(this.request.type || '') !== -1))
        .map(el => ({ ...el }))
      let index1 = products.findIndex(el => el.value === this.request.product)
      let index2 = this.products.findIndex(el => el.value === this.request.product)
      if (index1 === -1 && index2 !== -1) {
        let product = { 
          ...this.products[index2],
          disabled: true,
        }
        products.push(product)
      }
      return products
    },
    verticalsForSelect () {
      let verticals = this.verticals
        .filter(el => el.active)
        .map(el => ({ ...el }))
      let index1 = verticals.findIndex(el => el.value === this.request.vertical)
      let index2 = this.verticals.findIndex(el => el.value === this.request.vertical)
      if (index1 === -1 && index2 !== -1) {
        let vertical = { 
          ...this.verticals[index2],
          disabled: true,
        }
        verticals.push(vertical)
      }
      return verticals
    },
    legalApproversForSelect () {
      let legalApprovers = this.legalApprovers
        .filter(el => el.active && el.role === 'Approver')
        .map(el => ({ ...el }))
      let index1 = legalApprovers.findIndex(el => el.email === this.request.legalApprover.email)
      let index2 = this.legalApprovers.findIndex(el => el.email === this.request.legalApprover.email)
      if (index1 === -1 && index2 !== -1) {
        let legalApprover = { 
          ...this.legalApprovers[index2],
          disabled: true,
        }
        legalApprovers.push(legalApprover)
      }
      return legalApprovers
    },
    legalApproversActiveInGroup () {
      let legalApprovers = this.legalApprovers.filter(el => el.active)
        .filter(el => el.active && el.group && el.role === 'Approver')
        .map(el => ({ ...el }))
      return legalApprovers
    },
    nielsenEntityNameInLanguage () {
      const index = this.salesOrgs.findIndex(el => el.value === this.request.nielsenEntityName)
      if (index < 0) return null
      switch (this.request.language) {
        case 'Chinese': case 'chinese': case 'CN': case 'Cn': case 'cn': 
          return this.salesOrgs[index].textCn
          break
        default:
          return this.salesOrgs[index].textEn
          break
      }
    },
    currencyEn () {
      const index = this.currencies.findIndex(el => el.value === this.request.currency)
      if (index < 0) return null
      return this.currencies[index].textEn
    },
    currencyCn () {
      const index = this.currencies.findIndex(el => el.value === this.request.currency)
      if (index < 0) return null
      return this.currencies[index].textCn
    },
    staffSendToAbroadText () {
      if (this.request.staffSendToAbroad === null) return null
      return this.request.staffSendToAbroad ? 'YES' : 'NO'
    },
    laborEntryFromOverseasText () {
      if (this.request.laborEntryFromOverseas === null) return null
      return this.request.laborEntryFromOverseas ? 'YES' : 'NO'
    },

    defaultSalesOrg () {
      // Don't care location
      return null
    },

    // approve 第一个
    firstApprover () {
      if (this.needLeaderApproval && !!this.request.leaderApprover.email) {
        return {
          email: this.request.leaderApprover.email,
          name: this.request.leaderApprover.name ?? '',
        }
      }
      if (this.needCILeaderApproval && !!this.request.ciLeaderApprover.email) {
        return {
          email: this.request.ciLeaderApprover.email,
          name: this.request.ciLeaderApprover.name ?? '',
        }
      }
      if (this.needProductDSApproval && !!this.request.productDSApprover.email) {
        return {
          email: this.request.productDSApprover.email,
          name: this.request.productDSApprover.name ?? '',
        }
      }
      if (this.needLegalApproval && !!this.request.legalApprover.email) {
        return {
          email: this.request.legalApprover.email,
          name: this.request.legalApprover.name ?? '',
        }
      }
      if (this.needLegalExApproval && !!this.request.legalExApprover.email) {
        return {
          email: this.request.legalExApprover.email,
          name: this.request.legalExApprover.name ?? '',
        }
      }
      if (this.needMarcomApproval && !!this.request.marcomApprover.email) {
        return {
          email: this.request.marcomApprover.email,
          name: this.request.marcomApprover.name ?? '',
        }
      }
      return {
        email: '',
        name: '',
      }
    },
    
    // signers setting
    intSignerOrganizeNames () {
      let ret = []
      this.intOrganizes.forEach(el => {
        if (el.id && el.name && (ret.indexOf(el.name) === -1)) {
          ret.push(el.name)
        }
      })
      return ret
    },
    extSignerOrganizeNames () {
      let ret = []
      this.extAgentsXOrganizes
        .filter(el => 
          (
            (!this.extSignerOrganizeNameKey && !this.signFlow.extSigner.organize.name)
            || (
              (!!this.extSignerOrganizeNameKey && !!el.orgName && el.orgName.indexOf(this.extSignerOrganizeNameKey) !== -1)
              || (!!this.signFlow.extSigner.organize.name && !!el.orgName && el.orgName.indexOf(this.signFlow.extSigner.organize.name) !== -1)
            )
          )
          && (
            !this.signFlow.extSigner.agent.name || (el.agentName === this.signFlow.extSigner.agent.name)
          )
          && (
            !this.signFlow.extSigner.agent.mobile || (el.agentMobile === this.signFlow.extSigner.agent.mobile)
          )
          && (
            !this.signFlow.extSigner.agent.email || (el.agentEmail === this.signFlow.extSigner.agent.email)
          )
        )
        .forEach(el => {
          if (el.orgId && el.orgName && !ret.find(el2 => el2.name === el.orgName)) {
            ret.push({
              name: el.orgName,
              hasAgent: true,
            })
          }
        })
      this.extOrganizesXAgents.forEach(el => {
        if (el.orgId && el.orgName && !ret.find(el2 => el2.name === el.orgName)) {
          ret.push({
            name: el.orgName,
            hasAgent: false,
          })
        }
      })
      return ret
    },
    extSignerAgents () {
      let ret = []
      this.extOrganizesXAgents
        .filter(el =>
          (
            !!this.signFlow.extSigner.organize.name && (el.orgName === this.signFlow.extSigner.organize.name)
          )
          && (
            (!this.extSignerAgentNameKey && !this.signFlow.extSigner.agent.name)
            || (
              (!!this.extSignerAgentNameKey && !!el.agentName && el.agentName.indexOf(this.extSignerAgentNameKey) !== -1)
              || (!!this.signFlow.extSigner.agent.name && !!el.agentName && el.agentName.indexOf(this.signFlow.extSigner.agent.name) !== -1)
            )
          )
          && (
            (!this.extSignerAgentMobileKey && !this.signFlow.extSigner.agent.mobile)
            || (
              (!!this.extSignerAgentMobileKey && !!el.agentMobile && el.agentMobile.indexOf(this.extSignerAgentMobileKey) !== -1)
              || (!!this.signFlow.extSigner.agent.mobile && !!el.agentMobile && el.agentMobile.indexOf(this.signFlow.extSigner.agent.mobile) !== -1)
            )
          )
          && (
            (!this.extSignerAgentEmailKey && !this.signFlow.extSigner.agent.email)
            || (
              (!!this.extSignerAgentEmailKey && !!el.agentEmail && el.agentEmail.indexOf(this.extSignerAgentEmailKey) !== -1)
              || (!!this.signFlow.extSigner.agent.email && !!el.agentEmail && el.agentEmail.indexOf(this.signFlow.extSigner.agent.email) !== -1)
            )
          )
        )
        .forEach(el => {
          if (
            el.agentId && el.agentName && el.agentMobile && el.agentEmail
            && !ret.find(el2 => el2.id === el.agentId && el2.name === el.agentName && el2.mobile === el.agentMobile && el2.email === el.agentEmail)
          ) {
            ret.push({
              id: el.agentId,
              name: el.agentName,
              mobile: el.agentMobile,
              email: el.agentEmail,
              hasOrg: true,
            })
          }
        })
      this.extAgentsXOrganizes.forEach(el => {
        if (
          el.agentId && el.agentName && el.agentMobile && el.agentEmail
          && !ret.find(el2 => 
            el2.id === el.agentId 
            && el2.name === el.agentName 
            && el2.mobile === el.agentMobile 
            && el2.email === el.agentEmail
          )
        ) {
          ret.push({
            id: el.agentId,
            name: el.agentName,
            mobile: el.agentMobile,
            email: el.agentEmail,
            hasOrg: false,
          })
        }
      })
      return ret
    },
    extSignerAgentNames () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentNameKey || (!!el.name && el.name.indexOf(this.extSignerAgentNameKey) !== -1))
            || (!this.signFlow.extSigner.agent.name || (!!el.name && el.name.indexOf(this.signFlow.extSigner.agent.name) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.name === el.name)) {
            ret.push({
              name: el.name,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(
                el2 => el2.name === el.name
                && (!!this.signFlow.extSigner.agent.mobile || !!this.signFlow.extSigner.agent.email)
                && (el2.mobile === this.signFlow.extSigner.agent.mobile || !this.signFlow.extSigner.agent.mobile)
                && (el2.email === this.signFlow.extSigner.agent.email || !this.signFlow.extSigner.agent.email)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },
    extSignerAgentMobiles () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentMobileKey || (!!el.mobile && el.mobile.indexOf(this.extSignerAgentMobileKey) !== -1))
            || (!this.signFlow.extSigner.agent.mobile || (!!el.mobile && el.mobile.indexOf(this.signFlow.extSigner.agent.mobile) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.mobile === el.mobile)) {
            ret.push({
              mobile: el.mobile,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(el2 => 
                el2.mobile === el.mobile 
                && (!!this.signFlow.extSigner.agent.name || !!this.signFlow.extSigner.agent.email)
                && (el2.name === this.signFlow.extSigner.agent.name || !this.signFlow.extSigner.agent.name)
                && (el2.email === this.signFlow.extSigner.agent.email || !this.signFlow.extSigner.agent.email)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },
    extSignerAgentEmails () {
      let ret = []
      this.extSignerAgents
        .filter(el =>
          (
            (!this.extSignerAgentEmailKey || (!!el.email && el.email.indexOf(this.extSignerAgentEmailKey) !== -1))
            || (!this.signFlow.extSigner.agent.email || (!!el.mobile && el.email.indexOf(this.signFlow.extSigner.agent.email) !== -1))
          )
        )
        .forEach(el => {
          if (!ret.find(el2 => el2.email === el.email)) {
            ret.push({
              email: el.email,
              hasOrg: el.hasOrg,
              hasAgent: !!this.extSignerAgents.find(el2 => 
                el2.email === el.email 
                && (!!this.signFlow.extSigner.agent.name || !!this.signFlow.extSigner.agent.mobile)
                && (el2.name === this.signFlow.extSigner.agent.name || !this.signFlow.extSigner.agent.name)
                && (el2.mobile === this.signFlow.extSigner.agent.mobile || !this.signFlow.extSigner.agent.mobile)
              ),
            })
          }
        })
      ret = ret.sort((a, b) => {
        let pa = a.hasOrg * 1 + a.hasAgent * 10
        let pb = b.hasOrg * 1 + b.hasAgent * 10
        return pb - pa
      })
      return ret
    },

    dummyContractSigning () {
      return [{
        name: 'Contract Signing'
      }]
    },
    dummyContractSigned () {
      return this.request.generatorUsage 
        ?
        [{
          name: 'Contract Signed'
        }]
        : 
        this.request.draftedContract
          .map(el => ({
            name: el.name + ' (Signed)'
          }))
    },
  },
  methods: {
    // ====== vuex ======
    ...mapActions('app', ['setMainTitle']),
    
    // ====== check ======
    // datepicker
    allowedContractEffectiveDates(date) {
      let ret = true
      if (this.request.contractSigningDate) {
        ret = ret && (date >= this.request.contractSigningDate)
      }
      if (this.request.contractEndDate) {
        ret = ret && (date <= this.request.contractEndDate)
      }
      return ret
    },
    allowedContractEndDates(date) {
      let ret = true
      if (this.request.contractSigningDate) {
        ret = ret && (date >= this.request.contractSigningDate)
      }
      if (this.request.contractEffectiveDate) {
        ret = ret && (date >= this.request.contractEffectiveDate)
      }
      return ret
    },
    allowedContractSigningDates (date) {
      let ret = true
      if (this.request.contractEffectiveDate) {
        ret = ret && (date <= this.request.contractEffectiveDate)
      }
      if (this.request.contractEndDate) {
        ret = ret && (date <= this.request.contractEndDate)
      }
      return ret
    },

    isProductMatch (productName) {
      return this.needProduct &&
        !!this.products.find(el =>
          el.value === this.request.product &&
          el.text === productName
        )
    },

    // ====== actions ======
    clickCancelNewRequest () {
      this.$router.push('/legal/request/list')
    },
    clickPrevDraftStep () {
      this.isPrevDraftStepConfirming = true
    },
    async clickPrevDraftStepInContractPreviewing () {
      await this.saveDraftRequest('prev', true)
    },
    async clickConfirmPrevDraftStep () {
      await this.saveDraftRequest('prev', false)
      this.isPrevDraftStepConfirming = false
    },
    async clickSaveRequest () {
      await this.saveDraftRequest(null, false)
    },
    async clickSubmitRequest () {
      let errors = []
      if (
        this.$refs['legal-request-other-type-form']
        && !this.$refs['legal-request-other-type-form'].validate()
      ) {
        errors.push('legal-request-other-type-form')
      }
      if (
        this.$refs['legal-request-other-form']
        && !this.$refs['legal-request-other-form'].validate()
      ) {
        errors.push('legal-request-other-form')
      }
      if (
        this.$refs['legal-request-other-generator-form']
        && !this.$refs['legal-request-other-generator-form'].validate()
      ) {
        errors.push('legal-request-other-generator-form')
      }
      if (
        this.$refs['approval-info-form']
        && !this.$refs['approval-info-form'].validate()
      ) {
        errors.push('approval-info-form')
      }

      this.isApprovalInfoChecked = true

      console.log(errors)
      if (errors.length) return

      this.showSendComments(true)
    },
    async clickNextDraftStep () {
      this.isRequestInfoChecked = true
      if (this.isDraftStepInRequestType) {
        if (!this.$refs['legal-request-other-type-form'].validate()) return
        await this.saveDraftRequest(!this.request.generatorUsage ? 'next' : null, this.request.generatorUsage)
        this.isGeneratorDisplayed = this.request.generatorUsage
      } else {
        if (this.isDraftStepInContractInfo && !this.$refs['legal-request-other-form'].validate()) return
        await this.saveDraftRequest('next', false)
      }
    },
    clickCloseGenerator () {
      this.isGeneratorDisplayed = false
      this.saveDraftRequest(null, true)
    },
    clickDraftComments () {
      this.showSendComments(false)
    },
    
    clickOkContractEffectiveDate () {
      this.$refs['legal_other_request_form_contract_effective_date'].save(this.request.contractEffectiveDate)
    },
    clickOkContractEffectiveDateGen () {
      this.$refs['legal_other_request_generator_form_contract_effective_date'].save(this.request.contractEffectiveDate)
    },
    clickOkContractEndDate () {
      this.$refs['legal_other_request_form_contract_end_date'].save(this.request.contractEndDate)
    },
    clickOkContractEndDateGen () {
      this.$refs['legal_other_request_generator_form_contract_end_date'].save(this.request.contractEndDate)
    },
    clickOkContractSigningDate () {
      this.$refs['legal_other_request_form_contract_signing_date'].save(this.request.contractSigningDate)
    },   
    clickOkContractSigningDateGen () {
      this.$refs['legal_other_request_generator_form_contract_signing_date'].save(this.request.contractSigningDate)
    },
    clickUploadNewDraftedContract () {
      this.newDraftedContract.files = []
      this.newDraftedContract.comments = ''
      this.isNewDraftedContractUploadCardDisplayed = true
    },
    clickConfirmNewDraftedContract () {
      if (this.newDraftedContract.files.length < 1) {
        this.$eventBus.$emit('snackbar', { text: 'Please upload new contract', type: 'warning' })
        return
      }
      this.isNewDraftedContractUploadConfirming = true
    },
    async clickSubmitRequestFinal () {
      // save
      let token = await this.saveDraftRequest(null, true)
      if (!token) {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send request', type: 'error' })
        return
      }

      // submit
      this.$eventBus.$emit('snackbar', { text: 'Sending Request for Approval', type: 'loading' })

      let result = await this.postComment(token, true)

      if (result === 'success') {
        this.closeSendComments()
        this.$eventBus.$emit('snackbar', { text: 'Request has been sent for approval', type: 'success' })
      } else if (result === 'failure stage') {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send request, since request status has been changed.', type: 'error' })
      } else if (result === 'failure') {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send request', type: 'error' })
      }
    },
    async clickSendComments () {
      this.$eventBus.$emit('snackbar', { text: 'Sending Comments', type: 'loading' })
      
      let result = await this.postComment(this.token, false)

      if (result === 'success') {
        this.closeSendComments()
        this.$eventBus.$emit('snackbar', { text: 'Comments Sent', type: 'success' })
      } else if (result === 'failure stage') {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send comments, since request status has been changed.', type: 'error' })
      } else if (result === 'failure') {
        this.$eventBus.$emit('snackbar', { text: 'Fail to send comments', type: 'error' })
      }
    },
    clickRecall () {
      if (this.canRecall) {
        if (this.cntCanRecall === 1) {
          this.recall.type = 
            this.canRecallSubmit ? 1 :
            this.canRecallSign ? 2 : null
        } else {
          if (this.$refs['legal-request-other-recall-form']) {
            this.$refs['legal-request-other-recall-form'].resetValidation()
          }          
          this.recall.type = null
        }
        this.isRequestRecalling = true
      }
    },

    blurServiceFeeAmount () {
      let floAmount = parseFloat(this.request.serviceFeeAmount)
      if (isNaN(floAmount)) return ''
      this.request.serviceFeeAmount = floAmount.toFixed(2).toString() || null
    },

    inputExtSignerOrganizeName () {
      this.extSignerOrganizeNameKey = this.signFlow.extSigner.organize.name
    },
    inputExtSignerAgentName () {
      this.extSignerAgentNameKey = this.signFlow.extSigner.agent.name
    },
    inputExtSignerAgentMobile () {
      this.extSignerAgentMobileKey = this.signFlow.extSigner.agent.mobile
    },
    inputExtSignerAgentEmail () {
      this.extSignerAgentEmailKey = this.signFlow.extSigner.agent.email
    },
    
    deleteOtherRequiredDataElements (data) {
      this.request.otherRequiredDataElements.splice(data.index, 1)
    },
    deleteNielsenServiceSuppliedToCooperator (data) {
      this.request.nielsenServiceSuppliedToCooperator.splice(data.index, 1)
    },
    deleteClientRequestForm (data) {
      this.request.clientRequestForm.splice(data.index, 1)
    },
    deleteDraftedContract (data) {
      this.request.draftedContract.splice(data.index, 1)
    },
    deleteNewDraftedContract (data) {
      this.newDraftedContract.files.splice(data.index, 1)
    },
    deleteEPM (data) {
      this.request.epm.splice(data.index, 1)
    },
    deleteAttachment (data) {
      this.comments.files.splice(data.index, 1)
    },
    
    async downloadFile (data) {
      try {
        const res = await xDownload(
          data.item.url,
          data.item.name,
          { 
            loginStatus: this.loginStatus
          }
        )
        try {
          if (res.data.message === 'Authorize Failed') {
            this.$router.push('/login')
            return
          }
        } catch (e) {throw ''}
      } catch (e) {
        console.log('Error [download file]')
        // console.log(e)
      }
    },
    async downloadContractDoc() {
      let url = (
        await this.$refs[this.refMsalFunction].getDownloadUrlByPath(
          this.isApprovedRequest ? this.request.contractPdfMsPath : this.request.contractDocMsPath
        )
      ) || null
      if (!url) return
      const elemIF = document.createElement('iframe')
      elemIF.src = url
      elemIF.style.display = 'none'
      document.body.appendChild(elemIF)
    },
    async downloadSignFile (index) {
      let url = await this.postGetEsignDownloadUrl(index)
      if (!url) return

      const elemIF = document.createElement('iframe')
      elemIF.src = url
      elemIF.style.display = 'none'
      document.body.appendChild(elemIF)
    },

    // generator focus
    changeNielsenEntityName (value) {
      // do nothing
    },
    changeClientEntityName (value) {
      // do nothing
    },
    changeRetailerEntityName (value) {
      // do nothing
    },
    changeCooperatorEntityName (value) {
      // do nothing
    },
    changeNielsenEntityAddress (value) {
      // do nothing
    },
    changeRetailerEntityAddress (value) {
      // do nothing
    },
    changeNielsenResponsibleContactPerson (value) {
      // do nothing
    },
    changeRetailerResponsibleContactPerson (value) {
      // do nothing
    },
    changeProjectDescriptions (value) {
      // do nothing
    },
    changeNielsenAppointedEntityNamePayee (value) {
      // do nothing
    },
    changeNielsenAssigningEntityNamePayer (value) {
      // do nothing
    },
    changePayeeEntityAddress (value) {
      // do nothing
    },
    changePayerEntityAddress (value) {
      // do nothing
    },
    changePayeeEntityTelephoneNumber (value) {
      // do nothing
    },
    changePayerEntityTelephoneNumber (value) {
      // do nothing
    },
    changePayeeEntityFaxNumber (value) {
      // do nothing
    },
    changePayerEntityFaxNumber (value) {
      // do nothing
    },
    changeProjectNumber (value) {
      // do nothing
    },
    changeProjectNameAndDescriptions (value) {
      // do nothing
    },
    changeFieldWorkCountry (value) {
      // do nothing
    },
    changeStaffSendToAbroad (value) {
      // do nothing
    },
    changeProjectDuration (value) {
      // do nothing
    },
    changeServiceType (value) {
      // do nothing
    },
    changeLaborEntryFromOverseas (value) {
      // do nothing
    },
    changeCurrency (value) {
      // do nothing
    },
    changeSrvFeeAmount (value) {
      let tmpSrvFeeAmount = parseFloat(this.request.serviceFeeAmount)
      if (isNaN(tmpSrvFeeAmount)) {
        tmpSrvFeeAmount = null
      } else {
        tmpSrvFeeAmount = tmpSrvFeeAmount.toFixed(2).toString()
      }
      tmpSrvFeeAmount = this.needServiceFeeAmount ? tmpSrvFeeAmount : null

      // do nothing
    },
    changePayerEntityContactPerson (value) {
      // do nothing
    },

    // ====== functions ======
    // 页面初始化
    async init () {
      this.isPageLoading = true
      if (this.isNewRequest) {
        await this.postGetParams()
        this.presetData()
      } else {
        this.$eventBus.$emit('clearNotification', {
          type: 'Other Legal Requests',
          token: this.token
        })
        await this.refreshPage()
      }
      if (!this.canView) {
        this.$router.push('/legal/request/list')
      }
      this.isPageLoading = false
    },
    // new case 设置初始数据
    presetData () {
      this.request.requestor.email = this.userEmail
      this.request.requestor.name = this.userName
      this.request.requestor.icon = this.userPhotoUrl
      this.request.costCenter = this.costCenter
      this.request.team = this.userTeam
      this.request.draftStep = '1'
      this.history = []
    },
    // 重载页面
    async refreshPage () {
      this.isRequestInfoChecked = false
      
      await this.postGetParams()

      let postGetRequestInfo = this.postGetRequestInfo()
      let postSignFlowInfo = this.postSignFlowInfo()
      let postGetHistory = this.postGetHistory()
      await postGetRequestInfo
      await postSignFlowInfo
      await postGetHistory
    },
    // 保存 draft request
    async saveDraftRequest (stepTo, silent) {
      stepTo = stepTo || null
      silent = silent || false

      if (!silent) this.$eventBus.$emit('snackbar', { text: 'Saving', type: 'loading' })

      let token = await this.postSaveDraftRequest(stepTo)
      if (!!token) { await this.refreshPage() }

      if (!silent) {
        if (!!token) {
          this.$eventBus.$emit('snackbar', { text: 'Request Saved', type: 'success' })
        } else {
          this.$eventBus.$emit('snackbar', { text: 'Fail to save request', type: 'error' })
        }
      }

      return token
    },
    async getSoldToItems () {
      let time = new Date()
      this.soldToTime = time
      this.soldToLoading = true
      let keyword = this.soldToKey
      let postGetSapByKeyword = this.postGetSapByKeyword(keyword)
      let resp = await postGetSapByKeyword
      if ((this.soldToTime === time) &&
        (resp instanceof Array) &&
        keyword === this.soldToKey) {
        this.soldToItems = resp
        this.soldToLoading = false
      }
    },
    async getClientEntityNameItems () {
      let time = new Date()
      this.clientEntityNameTime = time
      this.clientEntityNameLoading = true
      let keyword = this.clientEntityNameKey
      let postGetSapByKeyword = this.postGetSapByKeyword(keyword)
      let resp = await postGetSapByKeyword
      if ((this.clientEntityNameTime === time) && (resp instanceof Array) && keyword === this.clientEntityNameKey) {
        this.clientEntityNameItems = resp
        this.clientEntityNameLoading = false
      }
    },
    async getRetailerEntityNameItems () {
      let time = new Date()
      this.retailerEntityNameTime = time
      this.retailerEntityNameLoading = true
      let keyword = this.retailerEntityNameKey
      let postGetSapByKeyword = this.postGetSapByKeyword(keyword)
      let resp = await postGetSapByKeyword
      if ((this.retailerEntityNameTime === time) &&
        (resp instanceof Array) &&
        keyword === this.retailerEntityNameKey) {
        this.retailerEntityNameItems = resp
        this.retailerEntityNameLoading = false
      }
    },
    async getCooperatorEntityNameItems () {
      let time = new Date()
      this.cooperatorEntityNameTime = time
      this.cooperatorEntityNameLoading = true
      let keyword = this.cooperatorEntityNameKey
      let postGetSapByKeyword = this.postGetSapByKeyword(keyword)
      let resp = await postGetSapByKeyword
      if ((this.cooperatorEntityNameTime === time) &&
        (resp instanceof Array) && 
        keyword === this.cooperatorEntityNameKey) {
        this.cooperatorEntityNameItems = resp
        this.cooperatorEntityNameLoading = false
      }
    },    
    async getNielsenAppointedEntityNamePayeeItems () {
      let keyword = this.nielsenAppointedEntityNamePayeeKey
      let postGetSapByKeyword = this.postGetSapByKeyword(keyword)
      let resp = await postGetSapByKeyword
      if ((resp instanceof Array) &&
        keyword === this.nielsenAppointedEntityNamePayeeKey) {
        this.nielsenAppointedEntityNamePayeeItems = resp
      }
    },
    async getNielsenAssigningEntityNamePayerItems () {
      let keyword = this.nielsenAssigningEntityNamePayerKey
      let postGetSapByKeyword = this.postGetSapByKeyword(keyword)
      let resp = await postGetSapByKeyword
      if ((resp instanceof Array) && keyword === this.nielsenAssigningEntityNamePayerKey) {
        this.nielsenAssigningEntityNamePayerItems = resp
      }
    },
    async getIntOrgs () {
      this.isIntSignerLoading = true
      let postGetIntOrgs = this.postGetIntOrgs()
      this.intOrganizes = await postGetIntOrgs
      this.isIntSignerLoading = false
    },
    async getExtOrgsWithAgents (val) {
      this.isExtSignerLoading = true
      let postGetExtOrgs = this.postGetExtOrgs(val)
      let resp = await postGetExtOrgs
      if ((resp instanceof Array) && val === this.extSignerOrganizeNameKey) {
        this.extOrganizesXAgents = resp
      }
      this.isExtSignerLoading = false
    },
    async getExtAgentsWithOrgs (val, type) {
      this.isExtSignerLoading = true
      type = type || ''
      this.flagPostExtAgents[0] = val
      this.flagPostExtAgents[1] = type
      let postGetExtAgents = this.postGetExtAgents(val, type)
      let resp = await postGetExtAgents
      if ((resp instanceof Array) && val === this.flagPostExtAgents[0] && type === this.flagPostExtAgents[1]) {
        this.extAgentsXOrganizes = resp
      }
      this.isExtSignerLoading = false
    },

    beforeUploadClientRequetForm(file) {
      this.isClientRequestFormUploading = true
    },
    errorUploadClientRequetForm(err, file) {
      console.log('Error [upload client request form]')
      // console.log(err)
      // console.log(file)
      this.isClientRequestFormUploading = false
    },
    successUploadClientRequetForm(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.clientRequestForm.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 4,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload client request form]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isClientRequestFormUploading = false
    },

    beforeUploadDraftedContract(file) {
      this.isDraftedContractUploading = true
    },
    errorUploadDraftedContract(err, file) {
      console.log('Error [upload drafted contract]')
      // console.log(err)
      // console.log(file)
      this.isDraftedContractUploading = false
    },
    successUploadDraftedContract(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.draftedContract.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 1,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload drafted contract]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isDraftedContractUploading = false
    },

    beforeUploadNewDraftedContract(file) {
      this.isNewDraftedContractUploading = true
    },
    errorUploadNewDraftedContract(err, file) {
      console.log('Error [upload new drafted contract]')
      // console.log(err)
      // console.log(file)
      this.isNewDraftedContractUploading = false
    },
    successUploadNewDraftedContract(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.newDraftedContract.files.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 1,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload new drafted contract]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isNewDraftedContractUploading = false
    },

    beforeUploadOtherRequiredDataElements(file) {
      this.isOtherRequiredDataElementsUploading = true
    },
    errorUploadOtherRequiredDataElements(err, file) {
      console.log('Error [upload Other Required Data Elements]')
      // console.log(err)
      // console.log(file)
      this.isOtherRequiredDataElementsUploading = false
    },
    async successUploadOtherRequiredDataElements(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.otherRequiredDataElements = [{
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 2,
          UploadTime: time,
          token: this.token
        }]
        this.isOtherRequiredDataElementsUploadConfirming = false

        if (this.isGeneratorDisplayed) {
          // let token = await this.postSaveDraftRequest()
          await this.postSaveDraftRequest()
        }
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload refresh other required data elements]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isOtherRequiredDataElementsUploading = false
    },

    beforeUploadNielsenServiceSuppliedToCooperator(file) {
      this.isNielsenServiceSuppliedToCooperatorUploading = true
    },
    errorUploadNielsenServiceSuppliedToCooperator(err, file) {
      console.log('Error [upload NielsenIQ Service Supplied to Cooperator]')
      // console.log(err)
      // console.log(file)
      this.isNielsenServiceSuppliedToCooperatorUploading = false
    },
    async successUploadNielsenServiceSuppliedToCooperator(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.nielsenServiceSuppliedToCooperator = [{
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 3,
          UploadTime: time,
          token: this.token
        }]
        this.isNielsenServiceSuppliedToCooperatorUploadConfirming = false

        if (this.isGeneratorDisplayed) {
          // let token = await this.postSaveDraftRequest()
          await this.postSaveDraftRequest()
        }
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload refresh other required data elements]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isNielsenServiceSuppliedToCooperatorUploading = false
    },

    beforeUploadEPM(file) {
      this.isEPMUploading = true
    },
    errorUploadEPM(err, file) {
      console.log('Error [upload external publicity materials]')
      // console.log(err)
      // console.log(file)
      this.isEPMUploading = false
    },
    successUploadEPM(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.request.epm.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 5,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload external publicity materials]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isEPMUploading = false
    },

    beforeUploadAttachment(file) {
      this.isAttachmentUploading = true
    },
    errorUploadAttachment(err, file) {
      console.log('Error [upload attachment]')
      // console.log(err)
      // console.log(file)
      this.isAttachmentUploading = false
    },
    successUploadAttachment(res, file) {
      if (res.status === 200) {
        const data = res.detail[0]
        const date = new Date()
        const time = date.getFullYear() + '-' + this.add0(date.getMonth() + 1) + '-' + this.add0(date.getDate()) + ' ' + this.add0(date.getHours()) + ':' + this.add0(date.getMinutes()) + ':' + this.add0(date.getSeconds())
        this.comments.files.push({
          url: data.url,
          name: file.name,
          time: time,
          download: true,
          delete: true,
          DispName: file.name,
          FileName: data.url,
          type: 0,
          UploadTime: time,
          token: this.token
        })
        // this.$eventBus.$emit('refreshGapi')
      } else {
        console.log('Error [upload attachment]')
        this.$eventBus.$emit('snackbar', { text: 'File is not acceptable', type: 'error' })
        if (res.message === 'Authorize Failed')
          this.$router.push('/login')
      }
      this.isAttachmentUploading = false
    },

    checkUserAccess () {
      let warning = ''

      try {
        if (
          (
            typeof this.costCenter === 'undefined'
            || this.costCenter.length < 7
          ) 
          && (this.extraRequestors.map(x => x.toLowerCase()).indexOf(this.loginStatus.userInfo.primaryEmail.toLowerCase()) === -1)
        ) {
          warning = 'You don\'t have right to handle request.'
        }
      } catch (err) {
        console.log('Error [warn user access]')
        // console.log(err)
      }

      if (warning) this.$eventBus.$emit('snackbar', { text: warning, type: 'warning' })
      return !warning
    },
    
    closeAddOpportunityNew () {
      this.addingOpportunity = false
    },
    doAddOpportunity(opportunity) {
      this.request.opportunityId = opportunity instanceof Object
        ? (opportunity.id || '')
        : ''
    }, 
    
    showSendComments(isToSumbit) {
      this.resetComments()
      if (!!isToSumbit) {this.isContractSubmitting = true}
      this.isCommentsSending = true
    },
    closeSendComments() {
      this.isCommentsSending = false
      this.isContractSubmitting = false
    },
    resetComments () {
      if (this.$refs['contract-comment-form']) {
        this.$refs['contract-comment-form'].resetValidation()
      }
      this.comments.content = null
      this.comments.files = []
    },

    showShareTo () {
      this.isAccessSharing = true
    },
    closeShareTo () {
      this.isAccessSharing = false
    },

    clickStartSign () {
      const activeSalesOrg = this.salesOrgsForSelect.find(el => el.value === this.request.nielsenEntityName)
      // check NielsenIQ entity
      if (this.needNielsenEntityName && !activeSalesOrg) {
        this.isNielsenEntityExpiredAlertDisplaying = true
      } else if (this.needNielsenEntityName && !activeSalesOrg?.signable) {
        this.isNielsenEntityPhysicalSignOnlyDisplaying = true
      } else {
        this.startSign()
      }
    },
    async startSign () {
      await this.postGetSignConf()
      if (!this.signConf.defSignType) {
        this.isSignTypeSelecting = true
      } else {
        this.signFlow.type = this.signConf.defSignType
        await this.finishSelectType()
      }
    },
    async finishSelectType () {
      this.isSignTypeNextLoading = true
      await this.getIntOrgs()
      this.isSignTypeNextLoading = false

      // if manual selecting
      this.isSignTypeSelecting = false

      this.signFlow.extSigner.need = (this.signConf.needExtSign && (this.signFlow.type === 2))
      this.startSignerSelecting()
    },
    startSignerSelecting () {
      if (this.$refs['legal-request-other-signer-form']) {
        this.$refs['legal-request-other-signer-form'].resetValidation()
      }
      this.signFlow.intSigner.organize.name = null
      this.signFlow.extSigner.organize.name = null
      this.signFlow.extSigner.agent.name = null
      this.signFlow.extSigner.agent.mobile = null
      this.signFlow.extSigner.agent.email = null
      this.isSignerSelecting = true
    },
    async createSignFlow () {
      let validate = true
      if (!!this.$refs['legal-request-other-signer-form']) {
        validate = validate && this.$refs['legal-request-other-signer-form'].validate()
      }
      if (!validate) return

      this.isSignFlowCreating = true
      // ids
      let intOrg = this.intOrganizes.find(el => el.name === this.signFlow.intSigner.organize.name)
      
      if (this.signFlow.extSigner.need) {
        let validationExtSigner = true
        let extOrg = this.extOrganizesXAgents.find(el => el.orgName === this.signFlow.extSigner.organize.name)
        if (!extOrg) {
          this.msgExtOrg = 'Legal Entity Not Found: New legal entity will be created.'
          validationExtSigner = false
        }
        let extAgent = this.extAgentsXOrganizes.find(el => el.agentName === this.signFlow.extSigner.agent.name && el.agentMobile === this.signFlow.extSigner.agent.mobile && el.agentEmail === this.signFlow.extSigner.agent.email)
        if (!extAgent) {
          this.msgExtAgent = 'Liaison (Name/Mobile/Email) Not Found: New liaison will be created.'
          validationExtSigner = false
        }
        let extOrgXAgent = this.extAgentsXOrganizes.find(el => el.orgName === this.signFlow.extSigner.organize.name && el.agentName === this.signFlow.extSigner.agent.name && el.agentMobile === this.signFlow.extSigner.agent.mobile && el.agentEmail === this.signFlow.extSigner.agent.email)
        if (!extOrgXAgent) {
          this.msgExtOrgXAgent = 'Liaison (Name/Mobile/Email) Not Found in Legal Entity: Liaison will be added into Legal Entity.'
          validationExtSigner = false
        }
        this.signFlow.extSigner.binded = validationExtSigner
        if (!validationExtSigner) {
          this.isExtSignerConfirming = true
          return
        }
      }

      await this.confirmCreateSignFlow()
    },
    async clickConfirmExtSigner () {
      this.isExtSignerConfirming = false
      await this.confirmCreateSignFlow()
    },
    cancelConfirmExtSigner () {
      this.isSignFlowCreating = false
    },
    closeConfirmExtSigner () {
      this.isSignFlowCreating = false
    },
    async confirmCreateSignFlow () {
      // post
      let postCreateSignFlow = await this.postCreateSignFlow()
      this.isSignFlowCreating = false
      if (postCreateSignFlow) {
        this.isSignerSelecting = false
        await this.refreshPage()
      } else {
        await this.getExtOrgsWithAgents(this.signFlow.extSigner.organize.name)
        await this.getExtAgentsWithOrgs('', '')
      }
    },
    async clickViewSign () {
      const activeSalesOrg = this.salesOrgsForSelect.find(el => el.value === this.request.nielsenEntityName)
      if (!(await this.postGetIntSignPwd())) {
        this.$eventBus.$emit('snackbar', { text: 'Fail to get signing information', type: 'error' })
      } else if (this.needNielsenEntityName && !activeSalesOrg) {
        this.isNielsenEntityExpiredAlertDisplaying = true
      } else if (this.needNielsenEntityName && !activeSalesOrg?.signable) {
        this.isNielsenEntityPhysicalSignOnlyDisplaying = true
      } else {
        this.isSignUrlDisplayed = true
      }
    },
    clickIntLink () {
      // DO NOT go to sign when code review
      if (!isProductEnv && isCdRevEnv) return;
      window.open(this.signFlow.intSigner.url)
    },
    clickExtLink () {
      // DO NOT go to sign when code review
      if (!isProductEnv && isCdRevEnv) return;
      window.open(this.signFlow.extSigner.url)
    },
    async clickArchive () {
      this.isContractArchiving = true
    },

    autoExtSigner (type) {
      let auto = false
      switch (type) {
        case 'orgName':
          auto = (this.extSignerOrganizeNames instanceof Array && !!this.extSignerOrganizeNames.find(el => el.name === this.signFlow.extSigner.organize.name))
          break;
      
        case 'agentName':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.name === this.signFlow.extSigner.agent.name))
          break;
      
        case 'agentMobile':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.mobile === this.signFlow.extSigner.agent.mobile))
          break;
      
        case 'agentEmail':
          auto = (this.extSignerAgents instanceof Array && !!this.extSignerAgents.find(el => el.email === this.signFlow.extSigner.agent.email))
          break;
      
        default:
          // do nothing
          break;
      }
      if (auto) {
        if (!this.signFlow.extSigner.organize.name && this.extSignerOrganizeNames instanceof Array && this.extSignerOrganizeNames.length === 1) {
          this.signFlow.extSigner.organize.name = this.extSignerOrganizeNames[0].name
        }
        if (!this.signFlow.extSigner.agent.name && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.name = this.extSignerAgents[0].name
        }
        if (!this.signFlow.extSigner.agent.mobile && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.mobile = this.extSignerAgents[0].mobile
        }
        if (!this.signFlow.extSigner.agent.email && this.extSignerAgents instanceof Array && this.extSignerAgents.length === 1) {
          this.signFlow.extSigner.agent.email = this.extSignerAgents[0].email
        }
      }
    },

    // get options
    getOptions (arr, opt) {
      let ret = arr
        .filter(el => !!el.active || el.value === opt)
        .map(el => ({
          ...el,
          disabled: !el.active
        }))
      return ret
    },

    // ====== http request ======
    // --- posts ---
    // 获取列表选项
    async postGetParams () {
      try {
        const req = xPost(
          'legal_request_other',
          'params',
          {
            loginStatus: this.loginStatus
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          this.salesOrgs = res.data.salesOrgs
            .filter(el => el.id && el.en && el.cn)
            .map(el => ({
              value: el.id,
              active: !!parseInt(el.active),
              signable: !!parseInt(el.signable ?? 0),
              textEn: el.en,
              textCn: el.cn,
              text: el.en + ' ' + el.cn,
            }))
          this.currencies = res.data.currencies
            .filter(el => el.id && el.en && el.cn)
            .map(el => ({
              value: el.id,
              active: !!parseInt(el.active),
              textEn: el.en,
              textCn: el.cn,
              text: el.en + ' ' + el.cn,
            }))
          this.products = res.data.products
            .filter(el => el.id && el.text)
            .map(el => ({
              value: el.id,
              active: !!parseInt(el.active),
              text: el.text,
              types: el.types,
            }))
          this.verticals = res.data.verticals
            .filter(el => el.id && el.text)
            .map(el => ({
              value: el.id,
              active: !!parseInt(el.active),
              text: el.text,
            }))
          this.leaderTitles = res.data.leaderTitles
            .map(el => el.Title)
          this.requestTypes = res.data.requestTypes
            .filter(el => el.id && (el.en || el.cn))
            .map(el => {
              let en = el.en || ''
              let cn = el.cn || ''
              return {
                value: el.id,
                active: !!parseInt(el.active),
                text: [en, cn].join(' '),
                selectGeneratorUsage: !!parseInt(el.selectGeneratorUsage),
                generatorUsage: !!parseInt(el.generatorUsage),
                needClientRequestForm: !!parseInt(el.needClientRequestForm),
                needForm: !!parseInt(el.needForm),
                needLanguage: !!parseInt(el.needLanguage),
                needProduct: !!parseInt(el.needProduct),
                needVertical: parseInt(el.needVertical) || 0,
                needOpportunity: !!parseInt(el.needOpportunity),
                needNielsenEntityName: !!parseInt(el.needNielsenEntityName),
                needSoldTo: !!parseInt(el.needSoldTo),
                needClientEntityName: !!parseInt(el.needClientEntityName),
                needRetailerEntityName: !!parseInt(el.needRetailerEntityName),
                needCooperatorEntityname: !!parseInt(el.needCooperatorEntityname),
                needContractEffectiveDate: !!parseInt(el.needContractEffectiveDate),
                needContractEndDate: !!parseInt(el.needContractEndDate),
                needContractSigningDate: !!parseInt(el.needContractSigningDate),
                needNielsenEntityAddress: !!parseInt(el.needNielsenEntityAddress),
                needRetailerEntityAddress: !!parseInt(el.needRetailerEntityAddress),
                needNielsenResponsibleContactPerson: !!parseInt(el.needNielsenResponsibleContactPerson),
                needRetailerResponsibleContactPerson: !!parseInt(el.needRetailerResponsibleContactPerson),
                needProjectDescriptions: !!parseInt(el.needProjectDescriptions),
                needOtherRequiredDataElements: !!parseInt(el.needOtherRequiredDataElements),
                needNielsenServiceSuppliedToCooperator: !!parseInt(el.needNielsenServiceSuppliedToCooperator),
                needNielsenAppointedEntityNamePayee: !!parseInt(el.needNielsenAppointedEntityNamePayee),
                needPayeeEntityAddress: !!parseInt(el.needPayeeEntityAddress),
                needPayeeEntityTelephoneNumber: !!parseInt(el.needPayeeEntityTelephoneNumber),
                needPayeeEntityFaxNumber: !!parseInt(el.needPayeeEntityFaxNumber),
                needNielsenAssigningEntityNamePayer: !!parseInt(el.needNielsenAssigningEntityNamePayer),
                needPayerEntityAddress: !!parseInt(el.needPayerEntityAddress),
                needPayerEntityTelephoneNumber: !!parseInt(el.needPayerEntityTelephoneNumber),
                needPayerEntityFaxNumber: !!parseInt(el.needPayerEntityFaxNumber),
                needProjectNumber: !!parseInt(el.needProjectNumber),
                needProjectNameAndDescriptions: !!parseInt(el.needProjectNameAndDescriptions),
                needFieldWorkCountry: !!parseInt(el.needFieldWorkCountry),
                needStaffSendToAbroad: !!parseInt(el.needStaffSendToAbroad),
                needProjectDuration: !!parseInt(el.needProjectDuration),
                needServiceType: !!parseInt(el.needServiceType),
                needLaborEntryFromOverseas: !!parseInt(el.needLaborEntryFromOverseas),
                needCurrency: !!parseInt(el.needCurrency),
                needServiceFeeAmount: !!parseInt(el.needServiceFeeAmount),
                needPayerEntityContactPerson: !!parseInt(el.needPayerEntityContactPerson),
                needCIIsListProvided: parseInt(el.needCIIsListProvided) || 0,
                needPurpose: !!parseInt(el.needPurpose),
                needEPMUpload: !!parseInt(el.needEPMUpload),
                needLeaderApproval: parseInt(el.needLeaderApproval) || 0,
                needCILeaderApproval: parseInt(el.needCILeaderApproval) || 0,
                needProductDSApproval: parseInt(el.needProductDSApproval) || 0,
                needLegalApproval: parseInt(el.needLegalApproval) || 0,
                needLegalExApproval: parseInt(el.needLegalExApproval) || 0,
                needMarcomApproval: parseInt(el.needMarcomApproval) || 0,
                draftedContractTitle: el.draftedContractTitle || '',
                draftedContractLabelEn: el.draftedContractLabelEn || '',
                draftedContractLabelCn: el.draftedContractLabelCn || '',
                draftedContractButtonType: el.draftedContractButtonType || '',
                formTitle: el.formTitle || '',
                templateURLClientRequestForm: el.templateURLClientRequestForm || '',
                templateURLDraftedContract: el.templateURLDraftedContract || '',
              }
            })
          this.ciLeaderApprovers = res.data.ciLeaderApprovers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              vertical: el.vertical,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.productDSApprovers = res.data.productDSApprovers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              product: el.product,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.legalApprovers = res.data.legalApprovers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              group: el.group,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.legalExApprovers = res.data.legalExApprovers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.marcomApprovers = res.data.marcomApprovers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.archiveApprovers = res.data.archiveApprovers
            .map(el => ({
              value: el.email,
              active: !!parseInt(el.active),
              role: el.role,
              email: el.email,
              name: el.name,
              icon: el.avatar,
            }))
          this.stages = res.data.stages
          // options
          this.options.ci.isListProvided = res.data.optsCIIsListProvided
            .filter(el => el.id && el.text)
            .map(el => ({
              value: el.id,
              text: el.text,
              active: !!parseInt(el.active),
              na: !!parseInt(el.na),
            }))
          this.options.purposes = res.data.purposes
            .filter(el => el.id && el.text)
            .map(el => ({
              value: el.id,
              text: el.text,
              active: !!parseInt(el.active),
              na: !!parseInt(el.na),
            }))
          this.extraRequestors = res.data.extraRequestors || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [post load data - other legal request options]')
        // console.log(err)
        this.$router.push('/legal/request/list')
      }
    },
    async postGetRequestInfo () {
      try {
        const res = await xPost(
          'legal_request_other',
          'infotoken',
          {
            loginStatus: this.loginStatus,
            token: this.token
          },
        )
        if (res.data.status === 200) {
          this.request.requestId = res.data.info.requestId

          this.request.requestor.email = res.data.info.requestorEmail
          this.request.requestor.name = res.data.info.requestorName
          this.request.requestor.icon = res.data.info.requestorAvatar
          
          this.request.costCenter = res.data.info.requestorCostCenter
          this.request.team = res.data.info.requestorTeam

          this.request.draftStep = res.data.info.draftStep
          this.request.type = res.data.info.type
          
          this.request.selectGeneratorUsage = !!parseInt(res.data.info.selectGeneratorUsage)
          this.request.needClientRequestForm = !!parseInt(res.data.info.needClientRequestForm)
          this.request.needForm = !!parseInt(res.data.info.needForm)
          this.request.needLanguage = !!parseInt(res.data.info.needLanguage)
          this.request.needProduct = !!parseInt(res.data.info.needProduct)
          this.request.needVertical = !!parseInt(res.data.info.needVertical)
          this.request.needOpportunity = !!parseInt(res.data.info.needOpportunity)
          this.request.needNielsenEntityName = !!parseInt(res.data.info.needNielsenEntityName)
          this.request.needSoldTo = !!parseInt(res.data.info.needSoldTo)
          this.request.needRetailerEntityName = !!parseInt(res.data.info.needRetailerEntityName)
          this.request.needCooperatorEntityname = !!parseInt(res.data.info.needCooperatorEntityname)
          this.request.needContractEffectiveDate = !!parseInt(res.data.info.needContractEffectiveDate)
          this.request.needContractEndDate = !!parseInt(res.data.info.needContractEndDate)
          this.request.needContractSigningDate = !!parseInt(res.data.info.needContractSigningDate)
          this.request.needNielsenEntityAddress = !!parseInt(res.data.info.needNielsenEntityAddress)
          this.request.needRetailerEntityAddress = !!parseInt(res.data.info.needRetailerEntityAddress)
          this.request.needNielsenResponsibleContactPerson = !!parseInt(res.data.info.needNielsenResponsibleContactPerson)
          this.request.needRetailerResponsibleContactPerson = !!parseInt(res.data.info.needRetailerResponsibleContactPerson)
          this.request.needProjectDescriptions = !!parseInt(res.data.info.needProjectDescriptions)
          this.request.needOtherRequiredDataElements = !!parseInt(res.data.info.needOtherRequiredDataElements)
          this.request.needNielsenServiceSuppliedToCooperator = !!parseInt(res.data.info.needNielsenServiceSuppliedToCooperator)
          this.request.needNielsenAppointedEntityNamePayee = !!parseInt(res.data.info.needNielsenAppointedEntityNamePayee)
          this.request.needPayeeEntityAddress = !!parseInt(res.data.info.needPayeeEntityAddress)
          this.request.needPayeeEntityTelephoneNumber = !!parseInt(res.data.info.needPayeeEntityTelephoneNumber)
          this.request.needPayeeEntityFaxNumber = !!parseInt(res.data.info.needPayeeEntityFaxNumber)
          this.request.needNielsenAssigningEntityNamePayer = !!parseInt(res.data.info.needNielsenAssigningEntityNamePayer)
          this.request.needPayerEntityAddress = !!parseInt(res.data.info.needPayerEntityAddress)
          this.request.needPayerEntityTelephoneNumber = !!parseInt(res.data.info.needPayerEntityTelephoneNumber)
          this.request.needPayerEntityFaxNumber = !!parseInt(res.data.info.needPayerEntityFaxNumber)
          this.request.needProjectNumber = !!parseInt(res.data.info.needProjectNumber)
          this.request.needProjectNameAndDescriptions = !!parseInt(res.data.info.needProjectNameAndDescriptions)
          this.request.needFieldWorkCountry = !!parseInt(res.data.info.needFieldWorkCountry)
          this.request.needStaffSendToAbroad = !!parseInt(res.data.info.needStaffSendToAbroad)
          this.request.needProjectDuration = !!parseInt(res.data.info.needProjectDuration)
          this.request.needServiceType = !!parseInt(res.data.info.needServiceType)
          this.request.needLaborEntryFromOverseas = !!parseInt(res.data.info.needLaborEntryFromOverseas)
          this.request.needCurrency = !!parseInt(res.data.info.needCurrency)
          this.request.needServiceFeeAmount = !!parseInt(res.data.info.needServiceFeeAmount)
          this.request.needPayerEntityContactPerson = !!parseInt(res.data.info.needPayerEntityContactPerson)
          this.request.needCIIsListProvided = !!parseInt(res.data.info.needCIIsListProvided)
          this.request.needPurpose = !!parseInt(res.data.info.needPurpose)
          this.request.needEPMUpload = !!parseInt(res.data.info.needEPMUpload)
          
          this.request.needLeaderApproval = !!parseInt(res.data.info.needLeaderApproval)
          this.request.needCILeaderApproval = !!parseInt(res.data.info.needCILeaderApproval)
          this.request.needProductDSApproval = !!parseInt(res.data.info.needProductDSApproval)
          this.request.needLegalApproval = !!parseInt(res.data.info.needLegalApproval)
          this.request.needLegalExApproval = !!parseInt(res.data.info.needLegalExApproval)
          this.request.needMarcomApproval = !!parseInt(res.data.info.needMarcomApproval)

          this.request.generatorUsage = !!parseInt(res.data.info.generatorUsage)
          this.request.language = res.data.info.language || null
          this.request.product = res.data.info.product || null
          this.request.vertical = res.data.info.vertical || null
          this.request.opportunityId = res.data.info.opportunityId || null
          this.request.nielsenEntityName = res.data.info.nielsenEntityName || this.defaultSalesOrg || null
          this.request.soldTo = res.data.info.soldTo || null
          this.request.clientEntityName = res.data.info.clientEntityName || null
          this.request.retailerEntityName = res.data.info.retailerEntityName || null
          this.request.cooperatorEntityName = res.data.info.cooperatorEntityName || null
          this.request.contractEffectiveDate = res.data.info.contractEffectiveDate || null
          this.request.contractEndDate = res.data.info.contractEndDate || null
          this.request.contractSigningDate = res.data.info.contractSigningDate || null
          this.request.nielsenEntityAddress = res.data.info.nielsenEntityAddress || null
          this.request.retailerEntityAddress = res.data.info.retailerEntityAddress || null

          this.request.nielsenResponsibleContactPerson.email = res.data.info.nielsenResponsibleContactPersonEmail || null
          this.request.nielsenResponsibleContactPerson.name = res.data.info.nielsenResponsibleContactPersonName || null
          this.request.nielsenResponsibleContactPerson.icon = res.data.info.nielsenResponsibleContactPersonIcon || null
          
          this.request.retailerResponsibleContactPerson = res.data.info.retailerResponsibleContactPerson || null
          this.request.projectDescriptions = res.data.info.projectDescriptions || null
          this.request.nielsenAppointedEntityNamePayee = res.data.info.nielsenAppointedEntityNamePayee || null
          this.request.payeeEntityAddress = res.data.info.payeeEntityAddress || null
          this.request.payeeEntityTelephoneNumber = res.data.info.payeeEntityTelephoneNumber || null
          this.request.payeeEntityFaxNumber = res.data.info.payeeEntityFaxNumber || null
          this.request.nielsenAssigningEntityNamePayer = res.data.info.nielsenAssigningEntityNamePayer || null
          this.request.payerEntityAddress = res.data.info.payerEntityAddress || null
          this.request.payerEntityTelephoneNumber = res.data.info.payerEntityTelephoneNumber || null
          this.request.payerEntityFaxNumber = res.data.info.payerEntityFaxNumber || null
          this.request.projectNumber = res.data.info.projectNumber || null
          this.request.projectNameAndDescriptions = res.data.info.projectNameAndDescriptions || null
          this.request.fieldWorkCountry = res.data.info.fieldWorkCountry || null
          this.request.staffSendToAbroad = (res.data.info.staffSendToAbroad === null) ? null : !!parseInt(res.data.info.staffSendToAbroad)
          this.request.projectDuration = res.data.info.projectDuration || null
          this.request.serviceType = res.data.info.serviceType || null
          this.request.laborEntryFromOverseas = (res.data.info.laborEntryFromOverseas === null) ? null : !!parseInt(res.data.info.laborEntryFromOverseas)
          this.request.currency = res.data.info.currency || null
          this.request.backgroundBriefing = res.data.info.backgroundBriefing || null

          this.request.ci.isListProvided = res.data.info.ciIsListProvided || null
          this.request.purpose = res.data.info.purpose || null

          let tmpSFA = parseFloat(res.data.info.serviceFeeAmount)
          if (isNaN(tmpSFA)) {
            this.request.serviceFeeAmount = null
          } else {
            this.request.serviceFeeAmount = tmpSFA.toFixed(2).toString() || null
          }

          this.request.payerEntityContactPerson.email = res.data.info.payerEntityContactPersonEmail || null
          this.request.payerEntityContactPerson.name = res.data.info.payerEntityContactPersonName || null
          this.request.payerEntityContactPerson.icon = res.data.info.payerEntityContactPersonIcon || null

          this.request.contractPdfMsPath = res.data.info.contractPdfMsPath || null
          this.request.contractPdfLcPath = res.data.info.contractPdfLcPath || null
          this.request.contractDocMsPath = res.data.info.contractDocMsPath || null
          this.request.contractDocLcPath = res.data.info.contractDocLcPath || null
          
          if (this.needLeaderApproval) {
            this.request.leaderApprover.email = res.data.info.leaderApproverEmail || null
            this.request.leaderApprover.name = res.data.info.leaderApproverName || null
            this.request.leaderApprover.icon = res.data.info.leaderApproverIcon || null
          }
          
          if (this.needCILeaderApproval) {
            this.request.ciLeaderApprover.email = res.data.info.ciLeaderApproverEmail || null
            this.request.ciLeaderApprover.name = res.data.info.ciLeaderApproverName || null
            this.request.ciLeaderApprover.icon = res.data.info.ciLeaderApproverIcon || null
          }
          
          if (this.needProductDSApproval) {
            this.request.productDSApprover.email = res.data.info.productDSApproverEmail || null
            this.request.productDSApprover.name = res.data.info.productDSApproverName || null
            this.request.productDSApprover.icon = res.data.info.productDSApproverIcon || null
          }
          
          if (this.needLegalApproval) {
            this.request.legalApprover.email = res.data.info.legalApproverEmail || null
            this.request.legalApprover.name = res.data.info.legalApproverName || null
            this.request.legalApprover.icon = res.data.info.legalApproverIcon || null
          }

          if (this.needLegalExApproval) {
            this.request.legalExApprover.email = res.data.info.legalExApproverEmail || null
            this.request.legalExApprover.name = res.data.info.legalExApproverName || null
            this.request.legalExApprover.icon = res.data.info.legalExApproverIcon || null
          }
          
          if (this.needMarcomApproval) {
            this.request.marcomApprover.email = res.data.info.marcomApproverEmail || null
            this.request.marcomApprover.name = res.data.info.marcomApproverName || null
            this.request.marcomApprover.icon = res.data.info.marcomApproverIcon || null
          }

          this.request.signType = !!parseInt(res.data.info.signType)
          this.request.needExtSign = !!parseInt(res.data.info.needExtSign)
          this.request.needArchive = !!parseInt(res.data.info.needArchive)
          
          if (this.request.needArchive) {
            this.request.archiveApprover.email = res.data.info.archiveApproverEmail || null
            this.request.archiveApprover.name = res.data.info.archiveApproverName || null
            this.request.archiveApprover.icon = res.data.info.archiveApproverIcon || null
          }

          this.request.canRecallArchive = res.data.recallArchv || false

          let files = res.data.files || []

          let draftedContract = files.filter(el => el.File_Type === '1')
          if (draftedContract.length) {
            this.request.draftedContract = draftedContract.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 1,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.draftedContract = []
          }

          let clientRequestForm = files.filter(el => el.File_Type === '4')
          if (clientRequestForm.length) {
            this.request.clientRequestForm = clientRequestForm.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 4,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.clientRequestForm = []
          }

          let otherRequiredDataElements = files.filter(el => el.File_Type === '2')
          if (otherRequiredDataElements.length) {
            this.request.otherRequiredDataElements = otherRequiredDataElements.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 2,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.otherRequiredDataElements = []
          }

          let nielsenServiceSuppliedToCooperator = files.filter(el => el.File_Type === '3')
          if (nielsenServiceSuppliedToCooperator.length) {
            this.request.nielsenServiceSuppliedToCooperator = nielsenServiceSuppliedToCooperator.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 3,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.nielsenServiceSuppliedToCooperator = []
          }

          let epm = files.filter(el => el.File_Type === '5')
          if (epm.length) {
            this.request.epm = epm.map(el => ({
              url: el.File_Name,
              name: el.Display_Name,
              time: el.Uploaded_Time,
              download: true,
              delete: true,
              DispName: el.Display_Name,
              FileName: el.File_Name,
              type: 5,
              UploadTime: el.Uploaded_Time,
              token: this.token
            })) 
          } else {
            this.request.epm = []
          }

          let share = res.data.share || []

          this.collaborators = share
            .filter(el => el.role === '1')
            .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
            }))

          this.ats = share
            .filter(el => el.role === '2')
            .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
            }))

          this.roles = res.data.roles
        }
      } catch (err) {
        console.log('Error [post load data - get request info]')
        // console.log(err)
        this.$router.push('/legal/request/list')
      }
    },
    async postSignFlowInfo () {
      try {
        const res = await xPost(
          'legal_request_other',
          'signflow',
          {
            loginStatus: this.loginStatus,
            token: this.token
          },
        )
        if (res.data.status === 200) {
          let signFlow = res.data.signFlow
          this.signFlow.esignFlowId = signFlow.esignFlowId || null
          this.signFlow.type = parseInt(signFlow.type) || null
          this.signFlow.extSigner.need = (signFlow.needExtSign !== '0')

          let intSigner = signFlow.signer.find(el => el.type === '1')
          if (!!intSigner) {
            this.signFlow.intSigner.signed = (intSigner.signed !== '0')
            this.signFlow.intSigner.url = intSigner.url
          }

          let extSigner = signFlow.signer.find(el => el.type === '2')
          if (!!extSigner) {
            this.signFlow.extSigner.signed = (extSigner.signed !== '0')
            this.signFlow.extSigner.url = extSigner.url
          }
        }
      } catch (err) {
        console.log('Error [post load data - get sign flow info]')
        console.log(err)
        this.$router.push('/legal/request/list')
      }
    },
    async postGetHistory () {
      try {
        const res = await xPost(
          'legal_request_other',
          'history',
          {
            loginStatus: this.loginStatus,
            token: this.token
          },
        )
        if (res.data.status === 200) {
          this.history = []
          res.data.history.forEach(el => {
            let index = this.history
              .findIndex(el2 =>
                el2.user.email === el.userEmail &&
                el2.time === el.time &&
                el2.action === el.action &&
                el2.comment === el.comment
              )
            if (index < 0) {
              index = this.history.length
              this.history.push({
                user: {
                  email: el.userEmail || '',
                  name: el.userName || '',
                  icon: el.userIcon || ''
                },
                time: el.time,
                action: el.action,
                display: !!parseInt(el.display),
                stageId: el.stageId,
                comment: el.comment,
                files: [],
                receivers: []
              })
            }
            if (!!el.fileName && !!el.fileDisplayName) {
              this.history[index].files.push({
                url: el.fileName,
                name: el.fileDisplayName
              })
            }
            if (!!el.receiverEmail) {
              this.history[index].receivers.push({
                email: el.receiverEmail || '',
                name: el.receiverName || '',
                icon: el.receiverIcon || ''
              })
            }
          });
        }
      } catch (err) {
        console.log('Error [post load data - get request history]')
        // console.log(err)
        this.$router.push('/legal/request/list')
      }
    },
    async postGetSapByKeyword (keyword) {
      try {
        keyword = keyword || ''
        const req = xPost(
          'sap',
          'getSapByKeyword',
          {
            loginStatus: this.loginStatus,
            keyword
          }
        )
        const res = await req
        if (res.data && res.data.status === 200) {
          return res.data.response.filter((el, i) => !!el)
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get SAP]')
        // console.log(err)
        return
      }
    },
    async postSaveDraftRequest (stepTo) {

      // 返回值 [string]: request token
      let ret = ''

      stepTo = stepTo || null

      let approval = {
        needCIIsListProvided: !!this.needCIIsListProvided,
        needVertical: !!this.needVertical,
        needLeaderApproval: !!this.needLeaderApproval,
        needCILeaderApproval: !!this.needCILeaderApproval,
        needProductDSApproval: !!this.needProductDSApproval,
        needLegalApproval: !!this.needLegalApproval,
        needLegalExApproval: !!this.needLegalExApproval,
        needMarcomApproval: !!this.needMarcomApproval,
      }

      try {
        const res = await xPost(
          'legal_request_other',
          'savedraft',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            form: this.request,
            default: this.requestTypeObj,
            approval,
            stepTo,
          },
        )
        if (res.data.status === 200) {
          if (this.isNewRequest && res.data.token) {
            this.$router.push('/legal/request/details/other/' + res.data.token)
            ret = res.data.token
          } else {
            ret = this.token
          }
          // this.$eventBus.$emit('refreshGapi')
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [save data - draft request]')
        // console.log(err)
      }

      return ret
    },
    async postGenerateContractPreview () {
      this.isRequestInfoChecked = true
      let valFields = this.$refs['legal-request-other-generator-form'].validate()
      let valFiles = true
      if (this.needOtherRequiredDataElements && !this.request.otherRequiredDataElements.length) { valFiles = false }
      if (this.needNielsenServiceSuppliedToCooperator && !this.request.nielsenServiceSuppliedToCooperator.length) { valFiles = false }
      if (!valFields || !valFiles) {
        this.$eventBus.$emit('snackbar', { text: 'Missing information or attachement. Please check.', type: 'warning' })
        return
      }

      this.tfPreviewingContract = new Date()
      let tfPreviewingContract = this.tfPreviewingContract
      this.isPreviewContractLoading = true
      
      try {
        let tmpSrvFeeAmount = parseFloat(this.request.serviceFeeAmount)
        if (isNaN(tmpSrvFeeAmount)) {
          tmpSrvFeeAmount = null
        } else {
          tmpSrvFeeAmount = tmpSrvFeeAmount.toFixed(2).toString()
        }
        tmpSrvFeeAmount = this.needServiceFeeAmount ? tmpSrvFeeAmount : null

        let form = {
          ...this.request,
          token: this.token,
          txtNielsenEntityName: this.nielsenEntityNameInLanguage,
          txtServiceFeeAmount: tmpSrvFeeAmount,
          currencyEn: this.currencyEn,
          currencyCn: this.currencyCn,
          nielsenResponsibleContactPersonName: this.request.nielsenResponsibleContactPerson.name,
          payerEntityContactPersonName: this.request.payerEntityContactPerson.name,
        }        
        const res = await xPost(
          'legal_request_other',
          'contractprev',
          {
            loginStatus: this.loginStatus,
            form,
          }
        )
        if (this.isGeneratorDisplayed && tfPreviewingContract === this.tfPreviewingContract) {
          if (res.data.status === 200) {
            this.request.contractPdfMsPath = res.data.pdf
            this.request.contractDocMsPath = res.data.doc
            this.request.contractPdfLcPath = res.data.pdfl
            this.request.contractDocLcPath = res.data.docl
            // this.$eventBus.$emit('refreshGapi')
            setTimeout(async () => {
              if (
                this.request.contractPdfMsPath
                && this.request.contractDocMsPath
                && this.request.contractPdfLcPath
                && this.request.contractDocLcPath
              ) {
                await this.saveDraftRequest('next', false)
                this.isPreviewContractLoading = false
                this.isFreshContractPreviewing = true
              }
            }, 2500);
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return
              }
            } catch (e) {}
            throw ''
          }
        }
      } catch (err) {
        console.log('Error [generate preview contract]')
        // console.log(err)
        this.$eventBus.$emit('snackbar', { text: 'Fail to generate contract preview', type: 'error' })
        this.isPreviewContractLoading = false
      }
    },
    async postComment (token, isToSubmit) {
      let ret = 'failure'
      if (!token || token === 'new') { return ret }
      isToSubmit = !!isToSubmit || false
      
      try {
        const res = await xPost(
          'legal_request_other',
          'comment',
          {
            loginStatus: this.loginStatus,
            token: token,
            submit: isToSubmit,
            stage: this.currStageId,
            content: this.comments.content,
            files: this.comments.files.map(el => el).reverse(),
          }
        )
        if (res.data && res.data.status === 200) {
          await this.refreshPage()
          ret = 'success'
        } else if (res.data.status == 401 && res.data.detail == 'stage') {
          await this.refreshPage()
          ret = 'failure stage'
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return null
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [submit contract]')
        //  console.log(err)
      }

      return ret
    },
    async postRecallRequest () {
      this.$eventBus.$emit('snackbar', { text: 'Recalling Contract', type: 'loading' })
      let snackbarParams = { text: 'Fail to recall request', type: 'error' }
      
      try {
        const res = await xPost(
          'legal_request_other',
          'recall',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
            type: this.recall.type,
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          snackbarParams = { text: 'Contract Recalled', type: 'success' }
          this.isRequestRecalling = false
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to recall request, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [recall contract]')
        //  console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postAbandonRequest () {
      this.isRequestAbandoning = false
      this.$eventBus.$emit('snackbar', { text: 'Abandoning Contract', type: 'loading' })
      let snackbarParams = { text: 'Fail to abandon request', type: 'error' }
      
      try {
        const res = await xPost(
          'legal_request_other',
          'abandon',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
          }
        )
        if (res.data.status === 200) {
          this.$router.push('/legal/request/list')
          snackbarParams = { text: 'Contract Abandoned', type: 'success' }
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to abandon request, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [abandon contract]')
        //  console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postApproveRequest () {
      this.$eventBus.$emit('snackbar', { text: 'Approving Request', type: 'loading' })
      let snackbarParams = { text: 'Fail to approve request', type: 'error' }
      
      try {
        const res = await xPost(
          'legal_request_other',
          'approve',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
            content: this.approval.comments.content,
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          this.isRequestApproving = false
          this.isGeneratorDisplayed = false
          snackbarParams = { text: 'Contract Approved', type: 'success' }
          if (!(this.isRequestor || this.isCollaborator || this.canApprove)) {
            this.$router.push('/approval')
          } 
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to approve request, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [approve request]')
        //  console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postReviseDraftedContract () {
      this.isNewDraftedContractUploadConfirming = false
      this.$eventBus.$emit('snackbar', { text: 'Updating Draft Contract', type: 'loading' })
      let snackbarParams = { text: 'Fail to revise draft contract', type: 'error' }
      
      try {
        const res = await xPost(
          'legal_request_other',
          'revisedraftcontract',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
            content: this.newDraftedContract.comments || '',
            draftedContract: this.newDraftedContract.files
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          snackbarParams = { text: 'Draft Contract Updated', type: 'success' }
          this.isNewDraftedContractUploadCardDisplayed = false
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to revise draft contract, since request status has been changed.', type: 'error' }
          this.isNewDraftedContractUploadCardDisplayed = false
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [update drafted contract]')
        //  console.log(err)
      }
      this.$eventBus.$emit('snackbar', snackbarParams)
    },
    async postUpdatedCollaborator (members) {
      this.$eventBus.$emit('snackbar', { text: 'Saving Access', type: 'loading' })

      let change = this.updateCollaborator(members)
      if (!change.length) {
        this.$eventBus.$emit('snackbar', { text: 'Change not found', type: 'warning' })
      } else {
        let snackbarParams = { text: 'Fail to save access', type: 'error' }
        try {
          const res = await xPost(
            'legal_request_other',
            'updateshare',
            {
              loginStatus: this.loginStatus,
              token: this.token,
              role: 1,
              added: change.filter(el => el.caseType === 'added'),
              deleted: change.filter(el => el.caseType === 'deleted'),
            }
          )
          if (res.data.status === 200) {
            snackbarParams = { text: 'Access Saved', type: 'success' }
            this.closeShareTo()
            // this.$eventBus.$emit('refreshGapi')
            setTimeout(async () => {
              await this.refreshPage()
            }, 100);
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return
              }
            } catch (e) {}
            throw ''
          }
        } catch (err) {
          console.log('Error [save access]')
          // console.log(err)
        }
        this.$eventBus.$emit('snackbar', snackbarParams)
      }
    },
    async postGetSignConf () {
      try {
        const res = await xPost(
          'legal_request_other',
          'signconf',
          {
            loginStatus: this.loginStatus,
            token: this.token
          }
        )
        if (res.data.status === 200) {
          this.signConf.defSignType = res.data.defSignType
          this.signConf.needExtSign = res.data.needExtSign
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get sign conf]')
        //  console.log(err)
      }
    },
    async postCreateSignFlow () {
      let ret = false

      this.$eventBus.$emit('snackbar', { text: 'Creating Sign Flow', type: 'loading' })
      let snackbarParams = { text: 'Fail to create sign flow', type: 'error' }
      
      try {
        const res = await xPost(
          'legal_request_other',
          'createsign',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
            signFlow: this.signFlow,
            needArchive: this.signConf.needArchive,
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          snackbarParams = { text: 'Sign Flow Created', type: 'success' }
          ret = true
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to create sign flow, since request status has been changed.', type: 'error' }
        } else if (res.data.status === 401 && res.data.detail === 'create_ext_signer_user_exist') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to create liaison, since email address as account exists.', type: 'error' }
        } else if (res.data.status === 401 && res.data.detail === 'create_ext_signer_user_new') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to create liaison. Please confirm the information (Mobile No./ Email) available.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return ret
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [create sign]')
        //  console.log(err)
      }

      this.$eventBus.$emit('snackbar', snackbarParams)
      return ret
    },
    async postGetIntOrgs () {
      try {        
        const res = await xPost(
          'esign',
          'intorgs',
          {
            loginStatus: this.loginStatus
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetExtOrgs (val) {
      try {        
        const res = await xPost(
          'esign',
          'extorgsxagents',
          {
            loginStatus: this.loginStatus,
            name: val || null,
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetExtAgents (val, type) {
      try {        
        let name = this.signFlow.extSigner.agent.name
        let mobile = this.signFlow.extSigner.agent.mobile
        let email = this.signFlow.extSigner.agent.email

        switch (type) {        
          case 'name':
            name = val
            break;
        
          case 'mobile':
            mobile = val
            break;
        
          case 'email':
            email = val
            break;
        
          default:
            break;
        }

        const res = await xPost(
          'esign',
          'extagentsxorgs',
          {
            loginStatus: this.loginStatus,
            name: name || null,
            mobile: mobile || null,
            email: email || null,
          }
        )
        if (res.data.status === 200) {
          return res.data.data || []
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get external organizes]')
        // console.log(err)
      }
      return []
    },
    async postGetIntSignPwd () {
      let ret = false

      try {
        const res = await xPost(
          'esign',
          'intsignpwd',
          {
            loginStatus: this.loginStatus,
          }
        )
        if (res.data.status === 200) {
          this.signFlow.intSigner.pwd = res.data.data || null
          ret = true
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get internal signer pwd]')
        // console.log(err)
      }

      return ret
    },
    async postArchive () {
      this.isContractArchiving = false
      let ret = false

      this.$eventBus.$emit('snackbar', { text: 'Archiving', type: 'loading' })
      let snackbarParams = { text: 'Fail to archive', type: 'error' }
      
      try {
        const res = await xPost(
          'legal_request_other',
          'archive',
          {
            loginStatus: this.loginStatus,
            token: this.token,
            stage: this.currStageId,
          }
        )
        if (res.data.status === 200) {
          await this.refreshPage()
          snackbarParams = { text: 'Contract Archived', type: 'success' }
          ret = true
        } else if (res.data.status === 401 && res.data.detail === 'stage') {
          await this.refreshPage()
          snackbarParams = { text: 'Fail to archive, since request status has been changed.', type: 'error' }
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return ret
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [archive]')
        //  console.log(err)
      }

      this.$eventBus.$emit('snackbar', snackbarParams)
      return ret
    },
    async postGetEsignDownloadUrl (index) {
      try {
        index = index || 0

        const res = await xPost(
          'esign',
          'esigndownurl',
          {
            loginStatus: this.loginStatus,
            id: this.signFlow.esignFlowId,
            idx: index,
          }
        )
        if (res.data.status === 200) {
          return res.data.data
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return null
            }
          } catch (e) {}
          throw ''
        }
      } catch (err) {
        console.log('Error [get esign download url]')
        //  console.log(err)
      }

      return null
    },

    // ====== tools ======
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    getRequestProps (prop, propReq) {
      propReq = propReq || prop
      if (this.isPendingRequest) {
        if (this.requestTypeObj) {
          switch (prop) {
            case 'needVertical':
            case 'needCIIsListProvided':
              switch (this.requestTypeObj[prop]) {
                case 2:
                  return this.isProductMatch('CI')
                  // break;
                default:
                  return !!this.requestTypeObj[prop]
                  // break;
              }
              // break;

            case 'needLeaderApproval':
              switch (this.requestTypeObj[prop]) {
                case 2:
                  return !this.request.generatorUsage
                  // break;  
                case 3:
                  return this.isProductMatch('RMS')
                  // break;
                default:
                  return !!this.requestTypeObj[prop]
                  // break;
              }
              // break;

            case 'needCILeaderApproval':
              switch (this.requestTypeObj[prop]) {
                case 2:
                  return this.isProductMatch('CI')
                  // break;
                default:
                  return !!this.requestTypeObj[prop]
                  // break;
              }
              // break;
          
            default:
              return (this.requestTypeObj[prop] === 2
                ? !this.request.generatorUsage
                : !!this.requestTypeObj[prop])
              // break;
          }
        } else {
          return false
        }
      } else {
        return this.request
          ? this.request[propReq]
          : false
      }
    },

    updateCollaborator (members) {
      let newList = []
      let change = []
      try {
        newList = members
          .map(el => ({
            email: el.email,
            name: el.name,
            icon: el.icon,
          }))
        change = newList
          .filter(el => this.collaborators.findIndex(el2 => el.email == el2.email) < 0)
          .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
              caseType: 'added'
            }))
          .concat(
            this.collaborators
              .filter(el => newList.findIndex(el2 => el.email == el2.email) < 0)
              .map(el => ({
              email: el.email,
              name: el.name,
              icon: el.icon,
              caseType: 'deleted'
            }))
          )
        this.collaborators = newList
      } catch (e) {
        return []
      }
      return change
    },

    commentInput (item) {
    },
    commentAt (item) {
    },
    commentAtInsert (item) {
    },

    draftCommentInput (item) {
    },
    draftCommentAt (item) {
    },
    draftCommentAtInsert (item) {
    },

    approvalCommentInput (item) {
    },
    approvalCommentAt (item) {
    },
    approvalCommentAtInsert (item) {
    },
  },
  async created () {
    // 设置顶部标题
    this.setMainTitle('Other Legal Requests')
    // 页面初始化
    await this.init()
  },  
  watch: {
    token (nv, ov) {
      if (nv !== ov) {
        this.init()
      }
    },
    'request.type' (nv, ov) {
      if (this.isDraftStepInRequestType) {
        let index1 = this.requestTypes.findIndex(el => el.value === nv)
        let index2 = this.requestTypes.findIndex(el => el.value === ov)
        if (index1 !== -1) {
          if (!this.requestTypes[index1].selectGeneratorUsage) {
            this.request.generatorUsage = this.requestTypes[index1].generatorUsage
          } else if (index2 !== -1 && !this.requestTypes[index2].selectGeneratorUsage) {
            this.request.generatorUsage = null
          }
        }
      }
    },
    isGeneratorDisplayed: {
      async handler (nv, ov) {
        if (!!nv && nv !== ov) {
          this.contractPreviewSrc = null
          if (this.request.contractPdfMsPath) {
            this.contractPreviewSrc = (
              await this.$refs[this.refMsalFunction].getItemPreviewUrlByPath(this.request.contractPdfMsPath)
            ) || null
          }
        }
        if (!nv) {
          this.isFreshContractPreviewing = false
        }
      }
    },
    soldToKey (val) {
      if (!!val && this.request.soldTo !== val) this.getSoldToItems()
    },
    clientEntityNameKey (val) {
      if (!!val && this.request.clientEntityName !== val) this.getClientEntityNameItems()
    },
    retailerEntityNameKey (val) {
      if (!!val && this.request.retailerEntityName !== val) this.getRetailerEntityNameItems()
    },
    cooperatorEntityNameKey (val) {
      if (!!val && this.request.cooperatorEntityName !== val) this.getCooperatorEntityNameItems()
    },
    extSignerOrganizeNameKey: {
      async handler (val) {
        await this.getExtOrgsWithAgents(val)
      }
    },
    extSignerAgentNameKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'name')
      }
    },
    extSignerAgentMobileKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'mobile')
      }
    },
    extSignerAgentEmailKey: {
      async handler (val) {
        await this.getExtAgentsWithOrgs(val, 'email')
      }
    },
    'signFlow.extSigner.organize.name': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('orgName')
        }
      }
    },
    'signFlow.extSigner.agent.name': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentName')
        }
      }
    },
    'signFlow.extSigner.agent.mobile': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentMobile')
        }
      }
    },
    'signFlow.extSigner.agent.email': {
      async handler (val, oval) {
        if (!!val) {
          this.autoExtSigner('agentEmail')
        }
      }
    },
    nielsenAppointedEntityNamePayeeKey (val) {
      if (!!val && this.request.nielsenAppointedEntityNamePayee !== val) this.getNielsenAppointedEntityNamePayeeItems()
    },
    nielsenAssigningEntityNamePayerKey (val) {
      if (!!val && this.request.nielsenAssigningEntityNamePayer !== val) this.getNielsenAssigningEntityNamePayerItems()
    },
    'request.contractPdfMsPath': {
      async handler () {
        if (this.isGeneratorDisplayed) { 
          this.contractPreviewSrc = (
            await this.$refs[this.refMsalFunction].getItemPreviewUrlByPath(this.request.contractPdfMsPath)
          ) || null
        }
      }
    },
  }
}
</script>

<style lang="sass">
.legal-request-other-detail
  width: 100%

  button
    margin-top: 3px
    margin-bottom: 3px
  

  .approver-selector-with-details
    display: flex
    align-items: center
  

  .approver-selector-without-details
    padding-right: 40px
  
  
  .extra-button-text
    padding: 8px 14px 8px 8px !important

  .v-input.v-textarea.v-input--is-readonly
    textarea
      color: rgba(0,0,0,.4) !important
  


.legal-request-other-detail-loading
  width: 100%
  text-align: center
  padding-top: calc(50vh - 85px)


.legal-request-other-radio-group-vertical
  .v-input__control
    width: 100%

    .v-radio
      width: 100%
      align-items: start

      label
        top: 2px
      
    
  

  .v-input--selection-controls
    margin-top: 0
  


.legal-request-other-comments-card,
.legal-request-other-approve-card,
.legal-request-other-sign-type-card,
.legal-request-other-signer-card,
.legal-request-other-contract-preview-card,
.legal-request-other-generate-contract-card,
.legal-request-other-new-drafted-contract-card,
.legal-request-other-nielsen-entity-alert-card
  width: 600px !important
  max-width: 100% !important
  // overflow: hidden


.legal-request-other-comments-card,
.legal-request-other-new-drafted-contract-card
  height: 450px !important


.legal-request-other-confirm-card,
.legal-request-other-sign-type-card
  width: 400px !important


.legal-request-other-signer-card
  width: 450px !important
  max-width: 100% !important


.legal-request-other-approve-card
  width: 500px !important
  height: 272px !important


.legal-request-other-contract-preview-card,
.legal-request-other-generate-contract-card
  width: 1200px !important
  height: 90vh !important

.legal-request-other-generate-contract-card,
#legal-request-other-form-card
  .nielsen-responsible-contact-person,
  .payer-entity-contact-person
    .v-autocomplete
      .v-select__slot
        padding: 9px 0

        .v-select__selections
          & > div
            padding-left: 0px !important

            .v-avatar
              margin-right: 12px !important
            
          
        
      
    
  

  .retailer-responsible-contact-person
    & > .v-input
      margin-top: 0px

      input
        height: 50px
        max-height: 50px
      
    
  


.legal-request-other-comments-container-icon
  display: flex

  i
    margin: 0 10px 0 0
  

  &-bold
    font-weight: 500
  


.legal-request-other-radio-group-horizonal
  .v-input__control
    width: 100% !important

    .v-radio
      width: calc(50% - 16px) !important
      align-items: start

      label
        top: 2px
      
    
  


.legal-request-other-approver-no-need
  margin-right: 40px
  padding: 28px 0 13px
  position: relative
  color: rgba(0, 0, 0, .68) !important

  &:before
    bottom: -1px
    content: ""
    left: 0
    position: absolute
    transition: .3s cubic-bezier(.25,.8,.5,1)
    width: 100% !important
    border-color: #d2d2d2!important
    border-style: solid
    border-width: thin 0 0
    border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38),rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat
  


.legal-request-other-details-approver-tooltip
  text-align: left


.legal-request-other-stepper
  // height: 24px

  .v-stepper,
  .v-stepper--vertical
    background: none
    box-shadow: none
    min-height: 0
    margin: 0 -12px
    padding-bottom: 0

    .v-stepper__header
      height: auto
    

    .v-stepper__step
      padding: 0 24px 3px 24px
    

    .v-stepper__header .v-divider
      border-color: hsla(0,0%,100%,.5)
    

    .v-stepper__content
      height: 10px
    
  


.legal-request-other-comments-container
  .v-input
    padding-top: 0px
  

  .v-input__slot
    padding: 0 9px 9px
  

  .person-select
    .v-input__slot
      padding: 9px
    
    .v-select__selections
      padding-left: 6px
    
  

  textarea
    margin-top: 0px !important
    padding-top: 8px !important
  


.legal-request-other-recall-radio-group
  .legal-request-other-recall-radio-description
    color:  grey
    font-size: 11px
    line-height: 12px
  


.legal-request-other-sign-con-type-radio-group
  .legal-request-other-sign-con-type-radio-description
    color:  grey
    font-size: 11px
    line-height: 12px
  


.sign-flow-signer-header,
.sign-flow-info-sign-header
  text-align: left
  font-size: 16px


.sign-flow-ext-signer-error-msg
  color: red
  text-align: left
  font-size: 12px


.sign-flow-info-sign-content
  text-align: left
  font-size: 12px

.v-card-x-layout
  .v-card-x-title
    font-size: 14px
    padding-left: 9px !important
    padding-right: 9px !important
    padding-bottom: 6px !important
  

  .v-card-x-cell
    text-align: left

    .v-card-x-cell-title
      font-size: 14px
      color: rgba(0,0,0,1)
      transform: translateX(-12.5%) scale(.75)
      -webkit-transform: translateX(-12.5%) scale(.75)
    

    .v-card-x-cell-title-absolute
      position: absolute
    
  

  .v-card-contract-amount
    .v-label
      font-size: 14px
    

    // .v-messages__message
    //   color: rgba(0, 0, 255, 0.5)
    // 
  


.v-card-x-buttons
  text-align: left


.v-flex-row-breaker
  padding: 0 !important


.bottom-btn-container
  text-align: left

  &-right
    text-align: right
  

  .v-btn,
  &-right .v-btn
    max-width: 130px
    min-width: 130px
  


a.template
  font-weight: bold
  text-decoration: underline


a.template.alert
  color: #000000

</style>
