<template>
  <vue-at-textarea
    v-model="myValue"
    :members="members"
    name-key="tag"
    @input="handleInput"
    @at="handleAt"
    @insert="handleInsert"
  >
    <template
      slot="item"
      slot-scope="s"
    >
      <v-avatar color="blue lighten-1" size="20" class="vue-at-avatar mr-2">
        <v-img v-if="s.item.avatar" :src="s.item.avatar"></v-img>
        <span v-else-if="s.item.name" class="white--text font-weight-bold">{{ s.item.name[0].toUpperCase() }}</span>
      </v-avatar>
      <span
        style="font-size: 13px; font-weight: 300;"
        v-text="s.item.name"
      />
    </template>
    <v-textarea
      :rows="rows"
      :placeholder="placeholder"
      :counter="counter"
      :solo="solo"
      :no-resize="noResize"
      :hide-details="hideDetails"
      :rules="rules"
    />
    <msal-functions :ref="refMsalFunction"></msal-functions>
  </vue-at-textarea>
</template>

<script>
import { mapGetters } from 'vuex'
import VueAtTextarea from 'vue-at/dist/vue-at-textarea'
import MsalFunctions from '@/components/msal/functions/index.vue'

export default {
  components: {
    VueAtTextarea,
    MsalFunctions,
  },
  props: {
    // data
    value: {
      type: String
    },

    // props: v-textarea
    rows: {
      type: [Number, String]
    },
    placeholder: {
      type: String
    },
    counter: {
      type: [Boolean, Number, String]
    },
    solo: {
      type: Boolean
    },
    noResize: {
      type: Boolean
    },
    hideDetails: {
      type: Boolean
    },
    rules: {
      type: Array
    }
  },
  model: {
    event: 'change',
    prop: 'value'
  },
  data () { return {
    // data
    myValue: '',
    members: [],

    // props
    timeAt: null,
    chunk: '',
    chunkTime: null,

    // params
    defaultImg: require('@/static/img/avatars/default.jpg'),
    refNo: Date.parse(new Date()),
  }},
  computed: {
    ...mapGetters('user', [
      'userEmail'
    ]),
    refMsalFunction () {
      return `msal-function-${this.refNo}`
    },
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
    },
    async handleAt (chunk) {
      this.$emit('at', chunk)

      let timeAt = new Date()
      this.timeAt = timeAt

      let results = []
      if (chunk) {
        if (this.chunk === chunk) {
          const chunkTime = new Date()
          this.chunkTime = chunkTime
          let result = []
          let val = this.chunk.toString().trim()
          result = await this.$refs[this.refMsalFunction].getUserListByInput(val)
          this.members = result
            .filter((el, index) => index < 10)
            .map(el => ({
              email: el.mail.toLowerCase(),
              name: el.displayName,
              tag: el.displayName + ' (' + el.mail.toLowerCase() +  ')',
              avatar: null
            }))
          // get icon
          this.getItemsIcons(val, chunkTime)
        } else {
          this.chunk = chunk
        }
      }
    },
    handleInsert (item) {
      this.$emit('at-insert', item)
    },
    async getItemsIcons (chunk, chunkTime) {
      // get icon
      for (let indexItems = 0; indexItems < this.members.length; indexItems++) {
        if (chunk !== this.chunk || chunkTime !== this.chunkTime) { return }
        const email = this.members[indexItems].email
        const avatar = await this.$refs[this.refMsalFunction].getUserBase64Image({
          id: email,
          size: 48,
        })
        if (email === this.members[indexItems].email) {
          this.members[indexItems].avatar = avatar
        } else {
          return
        }
      }
    },
  },
  watch: {
    value: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.myValue = this.value
        }
      }
    },
    myValue: {
      handler (nv, ov) {
        if (nv !== ov) {
          this.$emit('change', nv)
        }
      }
    }
  }
}
</script>

<style lang="sass">
  .vue-at-avatar
    min-width: 20px
</style>
