<template>
  <v-card class="x-opp-id-card">
    <v-list :class="isMobile ? ['white', 'sidebar-top'] : ['white']">
      <v-list-item>
        <template v-if="isMobile">
          <v-list-item-action>
            <v-btn v-if="isMobile" text icon @click="closeMe">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>

          <v-spacer></v-spacer>
        </template>

        <v-list-item-content>
          <v-list-item-title :style="isMobile ? {'text-align': 'center'} : {'font-size': '20px'}">
            {{ title }}
          </v-list-item-title>
        </v-list-item-content>

        <v-spacer></v-spacer>

        <v-list-item-action>
          <v-btn v-if="isMobile" text icon @click="submitMe">
            <v-icon>check</v-icon>
          </v-btn>
          <v-btn v-else text icon @click="closeMe">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <div class="content pl-3 pr-3">
      <div class="search-input pb-2 mb-3">
        <v-text-field
          v-model="keyword"
          placeholder="Please input entire Opportunity ID"
          solo
          single-line
          small
          hide-details
        >
        </v-text-field>
        <v-btn fab small :color="!!keyword ? 'primary' : 'grey lighten-2'" class="white--text mb-2 ml-2"
          :loading="loading"
          @click="getOpportunity"
        >
          <v-icon size="30" class="pb-1">mdi-magnify</v-icon>
        </v-btn>
      </div>

      <div v-if="!!opportunity.id" class="opp-info pt-2 pb-3 grey lighten-4">
        <div class="opp-info-row">
          <div class="opp-info-title">
            Opportunity ID
          </div>
          <div class="opp-info-divider">
            :
          </div>
          <div class="opp-info-value">
            {{ opportunity.id }}
          </div>
        </div>
        <div class="opp-info-row">
          <div class="opp-info-title">
            Opportunity Name
          </div>
          <div class="opp-info-divider">
            :
          </div>
          <div class="opp-info-value">
            {{ opportunity.name }}
          </div>
        </div>
        <div class="opp-info-row">
          <div class="opp-info-title">
            Account Name
          </div>
          <div class="opp-info-divider">
            :
          </div>
          <div class="opp-info-value">
            {{ opportunity.accountName }}
          </div>
        </div>
        <div class="opp-info-row">
          <div class="opp-info-title">
            Amount (Currency)
          </div>
          <div class="opp-info-divider">
            :
          </div>
          <div class="opp-info-value">
            {{ toThousandsMoney(opportunity.amount) }} {{ opportunity.currency ? '(' + opportunity.currency + ')' : '' }}
          </div>
        </div>
        <div class="opp-info-row">
          <div class="opp-info-title">
            Product(s)
          </div>
          <div class="opp-info-divider">
            :
          </div>
          <div class="opp-info-value">
            {{ oppProducts || '-' }}
          </div>
        </div>
      </div>
      <div v-else-if="!loading && !!empty">
        {{ empty }}
      </div>
    </div>

    <div class="bottom-btns ma-3 pt-3" v-if="!isMobile">
      <v-spacer></v-spacer>
      <v-btn :disabled="loading || !opportunity.id" color="primary" @click="submitMe">
        Done
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { xPost } from '@/functions/http/axios'
import { mapGetters } from 'vuex'
import { setTimeout } from 'timers';
import { toThousands } from '@/functions/maths'

export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Add Opportunity',
    }
  },
  data () {
    return {
      keyword: '',
      loading: false,
      empty: '',
      opportunity: {
        id: '',
        name: '',
        accountName: '',
        amount: '',
        currency: '',
        products: [],
        from: '',
        sap: ''
      }
    }
  },
  computed: {
    ...mapGetters('user', [
      'loginStatus'
    ]),
    oppProducts () {
      return this.opportunity.products
        .map(el => el.product || '')
        .filter(el => !!el)
        .join(' / ')
    }
  },
  methods: {
    async getOpportunity () {
      if (!!this.keyword) {
        this.loading = true
        this.empty = 'No matched Opportunity'
        // this.clearOpportunity()
        try {
          const res = await xPost(
            'opportunity',
            'getOpportunityById',
            {
              loginStatus: this.loginStatus,
              keyword: this.keyword
            }
          )
          if (res.data.status === 200) {
            if (this.loading) {
              if (!!res.data.response) {
                this.opportunity.id = res.data.response.id
                this.opportunity.name = res.data.response.name
                this.opportunity.accountName = res.data.response.accountName
                this.opportunity.amount = res.data.response.amount
                this.opportunity.products = res.data.response.products
                this.opportunity.currency = res.data.response.currency
                this.opportunity.from = res.data.response.from
                this.opportunity.sap = res.data.response.sap || ''
              } else {
                this.clearOpportunity()
              }
            }
          } else {
            try {
              if (res.data.message === 'Authorize Failed') {
                this.$router.push('/login')
                return []
              }
            } catch (e) {}
            throw ''
          }
        } catch (err) {
          console.log('Error [get opportunity]')
          // console.log(err)
        }
        this.loading = false
      }
    },

    // clear opportunity info
    clearOpportunity () {
      this.opportunity.id = ''
      this.opportunity.name = ''
      this.opportunity.accountName = ''
      this.opportunity.amount = ''
      this.opportunity.products = []
      this.opportunity.currency = ''
      this.opportunity.from = ''
    },

    // close
    closeMe () {
      this.$emit('close')
      setTimeout(() => {
        this.keyword = ''
        this.loading = false
        this.empty = ''
        this.clearOpportunity()
      }, 300);
    },

    // submit - add opportunity
    submitMe () {
      this.$emit('submit', {
        id: this.opportunity.id,
        name: this.opportunity.name,
        accountName: this.opportunity.accountName,
        amount: this.toThousandsMoney(this.opportunity.amount),
        products: this.opportunity.products,
        currency: this.opportunity.currency,
        from: this.opportunity.from,
        sap: this.opportunity.sap
      })
      this.closeMe()
    },

    toThousandsMoney (val) {
      let fltAmount = parseFloat(val)
      if (isNaN(fltAmount)) return ''
      return toThousands(fltAmount.toFixed(2)) + (fltAmount%1 ? '' : '.00')
    }
  }
}
</script>

<style lang="sass">
.x-opp-id-card
  width: 450px
  max-width: 100%
  max-height: 90vh
  overflow: auto

  .search-input
    display: flex
    align-items: center

  .opp-info
    .opp-info-row
      display: flex
      padding: 2px 12px

      .opp-info-title
        width: 130px
        min-width: 130px
        text-align: left

      .opp-info-divider
        width: 12px
        min-width: 12px
      
      .opp-info-value
        text-align: left
        color: rgba(0, 0, 0, 0.54)
        padding-left: 3px

  .bottom-btns
    display: flex
    text-align: left

    .v-btn
      margin: 0 !important
</style>
