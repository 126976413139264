<template>
  <v-tooltip
    :value="show"
    :offset-overflow="offsetOverflow"
    :bottom="bottom || !(top || left || right)"
    :top="top"
    :left="left"
    :right="right"
    :disabled="hideTooltip || !this.info.name"
  >
    <div
      style="text-align: left;padding: 3px 12px 12px 12px;"
      @mouseenter="mouseenterTooltip"
      @mouseleave="mouseleaveTooltip"
    >
      <div>
        <div
          class="account-profile-flex-center"
          :style="{
            'margin-top': toPixel(gapOfTitleAndContent),
            'margin-bottom': toPixel(gapOfTitleAndContent)
          }"
        >
          <v-avatar
            :size="profileTagIconSize"
            color="blue lighten-2"
          >
            <v-img v-if="info.icon" :src="info.icon"></v-img>
            <span v-else-if="email" class="white--text font-weight-bold">{{ email[0].toUpperCase() }}</span>
          </v-avatar>
          <div
            :style="{
              'padding': '0 12px 0 6px',
              'padding-left': toPixel(gapOfTitleIconAndName),
              'font-size': toPixel(profileTitleFontSize)
            }"
          >
            {{ info.name }}
          </div>
        </div>
        <div class="account-profile-flex-top account-profile-profiles">
          <v-icon :size="profileFontSize" style="line-height: 1.5">
            mdi-email
          </v-icon>
          <div :style="{ 'margin-left': toPixel(gapOfIconAndText), 'font-size': toPixel(profileFontSize) }">
            {{ email }}
          </div>
        </div>
        <div v-if="!!info.title || !!info.department" class="account-profile-flex-top account-profile-profiles">
          <v-icon :size="profileFontSize" style="line-height: 1.5">
            mdi-account
          </v-icon>
          <div :style="{ 'margin-left': toPixel(gapOfIconAndText), 'font-size': toPixel(profileFontSize) }">
            {{ [info.title, info.department].filter(el => el).join(' · ') }}
          </div>
        </div>
        <div v-if="!!info.location" class="account-profile-flex-top account-profile-profiles">
          <v-icon :size="profileFontSize" style="line-height: 1.5">
            mdi-map-marker
          </v-icon>
          <div :style="{ 'margin-left': toPixel(gapOfIconAndText), 'font-size': toPixel(profileFontSize) }">
            {{ info.location }}
          </div>
        </div>
        <div v-if="!!info.phone" class="account-profile-flex-top account-profile-profiles">
          <v-icon :size="profileFontSize" style="line-height: 1.5">
            mdi-phone-classic
          </v-icon>
          <div :style="{ 'margin-left': toPixel(gapOfIconAndText), 'font-size': toPixel(profileFontSize) }">
            {{ info.phone }}
          </div>
        </div>
      </div>
    </div>
    <template v-slot:activator="{ on }">
      <msal-functions :ref="refMsalFunction"></msal-functions>
      <div
        style="width: 100%"
        v-on="on"
        @mouseenter="mouseenterTag"
        @mouseleave="mouseleaveTag"
      >
        <slot name="tag">
          <div
            :class="{
              'account-profile-tag': true,
              'account-profile-flex-center': true,
              'account-profile-flex-top': tagAlign === 'top'
            }"
            v-on="on"
          >
            <v-avatar
              v-if="!hideIcon"
              color="blue lighten-2"
              :size="tagIconSize"
              :style="{
                'margin-top': tagAlign === 'top' ? 'calc(((' + toPixel(tagFontSize) +' * 1.5) - ' + toPixel(tagIconSize) +')/2)' : 0
              }"
            >
              <v-img v-if="info.icon" :src="info.icon"></v-img>
              <span v-else-if="email" class="white--text font-weight-bold">{{ email[0].toUpperCase() }}</span>
            </v-avatar>
            <div
              :style="{
                'padding': '0 12px 0 6px',
                'padding-left': toPixel(hideIcon ? 0 : gapOfTagIconAndName),
                'font-size': toPixel(tagFontSize),
                'z-index': '2',
                'max-width': 'calc(100% - ' + (hideIcon ? 0 : tagIconSize + 'px') + ')',
                color: tagColor,
                ...(tagOverflow === 'wrap' ? {
                  'overflow-wrap': 'break-word',
                  overflow: 'hidden'
                } : {
                  'text-overflow': 'ellipsis', 
                  'white-space': 'nowrap',
                  overflow: 'hidden'
                })
              }"
            >
              {{ info.name }}
            </div>
          </div>
        </slot>
      </div>
    </template>
  </v-tooltip>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import MsalFunctions from '@/components/msal/functions/index.vue'
import { xPost } from '@/functions/http/axios'

export default {
  components: {
    MsalFunctions,
  },
  props: {
    email: {  // email account
      style: String,
      default: ''
    },

    tagAlign: {
      style: String,
      default: ''
    },

    tagColor: {
      style: String,
      default: ''
    },
    tagIconSize: {
      style: [Number, String],
      default: 30
    },
    tagFontSize: {
      style: [Number, String],
      default: 14
    },
    profileTagIconSize: {
      style: [Number, String],
      default: 45
    },
    profileTitleFontSize: {
      style: [Number, String],
      default: 16
    },
    profileFontSize: {
      style: [Number, String],
      default: 14
    },
    gapOfTagIconAndName: {
      style: [Number, String],
      default: 8
    },
    gapOfTitleIconAndName: {
      style: [Number, String],
      default: 14
    },
    gapOfTitleAndContent: {
      style: [Number, String],
      default: 16
    },
    gapOfIconAndText: {
      style: [Number, String],
      default: 6
    },

    hideIcon: {
      style: Boolean,
      default: false
    },
    hideTooltip: {
      style: Boolean,
      default: false
    },

    offsetOverflow: {
      style: Boolean,
      default: true
    },
    bottom: {
      style: Boolean,
      default: false
    },
    top: {
      style: Boolean,
      default: false
    },
    left: {
      style: Boolean,
      default: false
    },
    right: {
      style: Boolean,
      default: false
    },

    maxLoadingTimes: {
      style: Number,
      default: 10
    },

    tagOverflow: {
      style: String,
      default: 'ellipsis'
      // ellipsis / wrap
    }
  },
  data () {
    return {
      onTag: false,
      onTooltip: false,
      show: false,
      loading: true,
      refNo: Date.parse(new Date()),
    }
  },
  computed: {
    ...mapState('user', {
      msal: state => state.msal,
    }),
    ...mapGetters('user', [
      'loginStatus',
      'listUser'
    ]),
    refMsalFunction () {
      return `msal-function-${this.refNo}`
    },
    info () {
      return (this.listUser(this.email) || {}).info || {}
    }
  },
  methods: {
    ...mapActions('user', [
      'setListUser',
    ]),
    mouseenterTag () {
      this.onTag = true
      this.checkShow(800)
    },
    mouseleaveTag () {
      this.onTag = false
      this.checkShow(100)
    },
    mouseenterTooltip () {
      this.onTooltip = true
      this.checkShow(0)
    },
    mouseleaveTooltip () {
      this.onTooltip = false
      this.checkShow(100)
    },
    checkShow(time) {
      setTimeout(() => { this.show = this.onTag || this.onTooltip }, time)
    },
    toPixel (value) {
      return typeof value === 'number' ? value + 'px' : value
    },

    setInfo () {
      if (!this.email) { return }
      this.setBaseInfo()
      this.setIcon()
    },
    async setBaseInfo () {
      if (!this.email) { return }
      const info = await this.$refs[this.refMsalFunction].getUserInfo(this.email)
      this.setListUser({
        email: this.email,
        info: {
          ...info,
          name: this._.get(info, 'displayName'),
          title: this._.get(info, 'jobTitle'),
          location: this._.get(info, 'officeLocation'),
          phone: this._.get(info, 'mobilePhone'),
          department: this._.get(info, 'department'),
        }
      })
    },
    async setIcon () {
      if (!this.email || this.info.icon) { return }
      const icon = await this.$refs[this.refMsalFunction].getUserBase64Image({
        id: this.email,
        size: 48,
      })
      this.setListUser({
        email: this.email,
        info: {
          icon,
        }
      })
    },
  },
  mounted () {
    this.setInfo()
  },
}
</script>

<style lang="sass">
  .account-profile-tag
    // cursor: default // pointer
    width: 100%
  

  .account-profile-flex-center
    display: flex
    align-items: center
  

  .account-profile-flex-top
    display: flex
    align-items: flex-start
  

  .account-profile-profiles
    padding: 3px 0
  

  .account-profile-flex-right
    display: flex
    justify-content: flex-end
  
</style>