<template>
  <div>
    <person-select
      v-model="myValue"
      :label="label"
      :items="items"
      :loading="loading"
      :disabled="disabled"
      :rules="rules"
      @focus="onFocus"
      @change="onChange"
    >
    </person-select>
    <msal-functions :ref="refMsalFunction"></msal-functions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isProductEnv } from '@/config'
import { xPost } from '@/functions/http/axios'
import personSelect from '@/components/vuetify-extension/person-select'
import MsalFunctions from '@/components/msal/functions/index.vue'

export default {
  components: {
    personSelect,
    MsalFunctions,
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    owner: {
      type: String,
      default: ''
    },
    leaderTitles: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      default_icon: require('@/static/img/avatars/default.jpg'),
      loading: false,
      myValue: null,
      domains: [
        'nielseniq.com',
        'nielsen.com'
      ],
      items: [],
      refNo: Date.parse(new Date()),
    }
  },
  computed: {
    ...mapGetters('user', [
      'loginStatus',
      'userEmail',
      'userTeam',
    ]),
    refMsalFunction () {
      return `msal-function-${this.refNo}`
    },
  },
  methods: {
    onFocus () {
      if (this.items.length < 1 && !this.loading) {
        this.requestItem()
      }
    },
    onChange () {
      this.$emit('change', {...this.myValue})
      this.updateUser('change')
    },
    async requestItem () {
      if (!this.owner) {
        return
      }
      this.loading = true
      this.items = []
      let result = []

      try {
        // get report chain & teammates
        const getExtraCommercialLeaders = xPost(
          'user',
          'ex_comm_ldrs',
          {
            loginStatus: this.loginStatus,
            email: this.userEmail
          }
        )
        const getReportChain = this.$refs[this.refMsalFunction].getUserReportChain()
        const getTeammate = this.$refs[this.refMsalFunction].getUserInTeam(this.userTeam)
        const resGetExtraCommercialLeaders = await getExtraCommercialLeaders
        let emailsExtraCommercialLeaders = []
        if (this._.get(resGetExtraCommercialLeaders, 'data.status') === 200) {
          emailsExtraCommercialLeaders = this._.get(resGetExtraCommercialLeaders, 'data.data') || []
        }
        let objCommercialLeadersInfos = {}
        for (const emailCL of emailsExtraCommercialLeaders) {
          objCommercialLeadersInfos[emailCL] = {
            func: this.$refs[this.refMsalFunction].getUserInfo(emailCL)
          }
        }
        for (const emailCL of Object.keys(objCommercialLeadersInfos)) {
          const objCommercialLeadersInfoMsData = await objCommercialLeadersInfos[emailCL].func
          result.push({
            email: emailCL,
            name: this._.get(objCommercialLeadersInfoMsData, 'displayName'),
            icon: null,
          })
        }
        const reportChain = await getReportChain
        const teammate = this._.get(await getTeammate, 'value') || []        
        let manager = this._.get(reportChain, 'manager')
        while (manager) {
          if (
            this._.get(manager, 'mail')
            && this.leaderTitles.find(el =>
              (this._.get(manager, 'jobTitle') || '')
                .match(new RegExp("(^|[^A-Za-z])"+el+"([^A-Za-z]|$)", 'g'))
            )
          ) {
            result.push({
              email: manager.mail,
              name: this._.get(manager, 'displayName'),
              icon: null,
            })
          }
          manager = manager.manager
        }
        result.splice(
          -1, 0,
          ...teammate.filter(el =>
            this._.get(el, 'mail')
            && this.leaderTitles.find(el =>
              (this._.get(manager, 'jobTitle') || '')
                .match(new RegExp("(^|[^A-Za-z])"+el+"([^A-Za-z]|$)", 'g'))
            )
          ).map(el => ({
            email: el.mail,
            name: this._.get(el, 'displayName'),
            icon: null,
          }))
        )
        result = [...new Set(result.map(el => JSON.stringify(el)))].map(el => JSON.parse(el))
        result.forEach(async el =>
          el.icon = await this.$refs[this.refMsalFunction].getUserBase64Image({
            id: el.email,
            size: 48,
          }) || null
        )
      } catch (e) {
        console.log('[Error] leader-select get items')
        console.log(e)
      }

      result.sort((a,b) => a.name < b.name ? -1 : 1)

      this.items = result
      this.syncMyValueItems()
      this.loading = false
    },
    setMyValue (val) {
      try {
        if (!this.myValue || val.email !== this.myValue.email || val.name !== this.myValue.name || val.icon !== this.myValue.icon) {
          this.myValue = {
            email: val.email || '',
            name: val.name || '',
            icon: val.icon || ''
          }
          this.syncMyValueItems()
        }
      } catch (e) {
        // console.log(e)
      }
    },
    syncMyValueItems () {
      if (this.myValue && this.myValue.email) {
        let index = this.items.findIndex(item => item.email === this.myValue.email)
        if (index > -1) {
          if (this.myValue.name !== this.items[index].name || this.myValue.icon !== this.items[index].icon) {
            this.myValue.name = this.items[index].name
            this.myValue.icon = this.items[index].icon
            this.updateUser('sync')
          }
        } else {
          this.items = this.items.filter(el => !el.disabled)
          this.items.splice(0, 0, { ...this.myValue, disabled: true })
        }
      }
    },
    async updateUser (detail) {
      detail = detail || ''
      // add to usermap
      if (this.myValue && this.myValue.email) {
        // const res = await xPost(
        //   'user',
        //   'updateUserMap',
        //   {
        //     loginStatus: this.loginStatus,
        //     email: this.myValue.email,
        //     detail: `leader-select-${detail}`
        //   }
        // )
        // if (res.data.status === 200) {
        //   // do nothing
        // } else {
        //   console.log('Error [update usermap] - leader-select-' + detail)
        // }
      }
    },
  },
  created () {
    this.setMyValue(this.value)
  },
  mounted () {
    this.requestItem()
  },
  watch: {
    value: {
      deep: true,
      handler (val, oVal) {
        this.setMyValue(val)
      }
    },
    owner (val) {
      this.requestItem()
    }
  }
}
</script>

<style>

</style>
